/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
// Soft UI Dashboard PRO React helper functions

export default {
  styleOverrides: {
    root: {
      underline: "none",
      color: `${colors.info.main}`,
      outline: 0,
      cursor: "pointer",
      "&.Mui-focusVisible": {
        outline: 0,
      },
    },
  },
};
