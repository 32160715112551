/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// react-router-dom components
import { Link } from "react-router-dom";
// PropTypes
import PropTypes from "prop-types";

// i18n
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";
import authenticationEN from "locales/en/authentication.json";

import * as Yup from "yup";
import { Formik, Form } from "formik";

// @mui material components
import Card from "@mui/material/Card";
import FormHelperText from "@mui/material/FormHelperText";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiMobilePhoneInput from "components/sui/SuiMobilePhoneInput";
import SuiButton from "components/sui/SuiButton";
import SuiCheckbox from "components/sui/SuiCheckbox";
import SuiSpinner from "components/sui/SuiSpinner";
// Authentication layout components
import BasicLayout from "layouts/components/BasicLayout";
import Socials from "layouts/components/Socials";
import Separator from "layouts/components/Separator";
import Error from "components/layout/Error";

import { isValidPhoneNumber } from "react-phone-number-input";

// Images
import curved6 from "assets/images/curved-images/curved6.jpg";

import * as authentication from "actions/authentication";
import * as app from "actions/app";

const initialValues = {
  mobilePhone: "",
  agreement: false,
};

function SignUp({ changeView, setRefId, t }) {
  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.appStore);
  const [submit, setSubmit] = useState(false);
  const formik = useRef(null);

  useEffect(() => {
    i18n.addResources("en", "authentication", authenticationEN);
  }, []);

  const handleSetAgremment = () => {
    if (!submit) {
      formik.current.setFieldValue("agreement", !formik.current.values.agreement);
    }
  };

  const handleSetMobilePhone = (value) => {
    if (!value) return;
    formik.current.setFieldValue("mobilePhone", value, true);
    formik.current.setFieldTouched("mobilePhone", true, false);
  };

  const onSubmited = (error, result) => {
    if (error) {
      switch (error.code) {
        case 3250:
          formik.current.errors.mobilePhone = t("errors:3250");
          break;
        default:
          dispatch(app.error(error));
      }
      setSubmit(false);
    } else {
      setRefId(result.refId);
      changeView("sign-up2");
    }
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(app.errorClose());
    dispatch(authentication.signUp(values, onSubmited));
    return true;
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      mobilePhone: Yup.string()
        .required(t("yup:required", { name: t("mobilePhone") }))
        .test({
          name: "mobilePhone",
          exclusive: true,
          message: t("yup:format", { name: t("mobilePhone") }),
          test: isValidPhoneNumber,
        }),
      agreement: Yup.bool().oneOf([true], t("yup:terms-and-condition")),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  return (
    <BasicLayout
      title={t("authentication:signup.title")}
      description={t("authentication:signup.subtitle")}
      image={curved6}
    >
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values, errors, touched, handleSubmit }) => (
          <Card>
            <Form onSubmit={handleSubmit} noValidate name="registerForm">
              <SuiBox p={3} mb={1} textAlign="center">
                <SuiTypography variant="h5" fontWeight="medium">
                  <Trans>authentication:register-with</Trans>
                </SuiTypography>
              </SuiBox>

              <SuiBox mb={2}>
                <Socials />
              </SuiBox>
              <Separator />
              <SuiBox pt={2} pb={3} px={3}>
                <Error
                  error={appStore.error}
                  color="primary"
                  onClick={() => {
                    dispatch(app.errorClose());
                  }}
                />
                <SuiBox>
                  <SuiBox mb={2}>
                    <SuiMobilePhoneInput
                      name="mobilePhone"
                      placeholder={t("phone-placeholder")}
                      onChange={handleSetMobilePhone}
                      value={values.mobilePhone}
                      error={!!touched.mobilePhone && !!errors.mobilePhone}
                      helperText={errors.mobilePhone}
                      touched={touched.mobilePhone}
                    />
                  </SuiBox>
                  <SuiBox display="flex" alignItems="left">
                    <SuiCheckbox
                      checked={values.agreement}
                      onChange={handleSetAgremment}
                      error={!!touched.agreement && !!errors.agreement}
                      disabled={submit}
                    />
                    <SuiTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={handleSetAgremment}
                      sx={{ cursor: submit ? "default" : "pointer", userSelect: "none" }}
                    >
                      &nbsp;&nbsp;<Trans>authentication:accept-terms-1</Trans>&nbsp;
                      <SuiTypography
                        component={Link}
                        to="/legal/terms"
                        target="_blank"
                        variant="button"
                        fontWeight="bold"
                        textGradient
                      >
                        <Trans>authentication:accept-terms-2</Trans>
                      </SuiTypography>
                      &nbsp;&nbsp;<Trans>authentication:accept-terms-3</Trans>&nbsp;
                      <SuiTypography
                        component={Link}
                        to="/legal/privacy"
                        target="_blank"
                        variant="button"
                        fontWeight="bold"
                        textGradient
                      >
                        <Trans>authentication:accept-terms-4</Trans>
                      </SuiTypography>
                      &nbsp;&nbsp;<Trans>authentication:accept-terms-5</Trans>&nbsp;
                    </SuiTypography>
                  </SuiBox>
                  {!!touched.agreement && errors.agreement && (
                    <SuiBox display="flex" alignItems="center">
                      <FormHelperText error id="accountId-error">
                        {errors.agreement}
                      </FormHelperText>
                    </SuiBox>
                  )}

                  {submit && (
                    <SuiBox mt={1} mb={1}>
                      {" "}
                      <SuiSpinner size={50} minHeight={50} />{" "}
                    </SuiBox>
                  )}
                  <SuiBox mt={4} mb={1}>
                    <SuiButton
                      variant="gradient"
                      color="dark"
                      fullWidth
                      disabled={submit}
                      onClick={() => {
                        formik.current.submitForm();
                      }}
                    >
                      <Trans>authentication:signup</Trans>
                    </SuiButton>
                  </SuiBox>
                  <SuiBox mt={3} textAlign="center">
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                      Already have an account?&nbsp;
                      <SuiTypography
                        onClick={() => {
                          changeView("sign-in");
                        }}
                        variant="button"
                        color="dark"
                        fontWeight="bold"
                        textGradient
                        disabled={submit}
                      >
                        <Trans>authentication:signin</Trans>
                      </SuiTypography>
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Form>
          </Card>
        )}
      </Formik>
    </BasicLayout>
  );
}

SignUp.propTypes = {
  changeView: PropTypes.func.isRequired,
  setRefId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SignUp);
