/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";

// react-router-dom components
import { Redirect, useParams } from "react-router-dom";

// PropTypes
import PropTypes from "prop-types";

// i18n
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";
import authenticatorEN from "locales/en/authenticator.json";

import * as Yup from "yup";
import { Formik, Form } from "formik";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiCodeInput from "components/sui/SuiCodeInput";
import SuiSpinner from "components/sui/SuiSpinner";
import TimelineList from "components/sui/SuiTimeline/TimelineList";
import TimelineItem from "components/sui/SuiTimeline/TimelineItem";

// Authentication layout components
import BasicLayout from "layouts/pages/components/BasicLayout";
import Error from "components/layout/Error";
import Spinner from "components/layout/Spinner";
// Images
import curved8 from "assets/images/curved-images/curved8.jpg";

import * as app from "actions/app";
import * as profile from "actions/profile";

// Constants
import Constants from "constants/Constants";

const { AUTHENTICATORS } = Constants;
const { REFLINK_TYPES } = Constants;

function GoogleAuthenticatorActivate({ t }) {
  const dispatch = useDispatch();
  const { refId } = useParams();
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(true);
  const [attemps, setAttemps] = useState(2);
  const [cancel, setCancel] = useState(false);
  const [qrcode, setQRcode] = useState(undefined);
  const [redirect, setRedirect] = useState(false);
  const formik = useRef(null);
  const codeInput = useRef(null);
  const initialValues = {
    code: "",
  };

  useEffect(() => {
    i18n.addResources("en", "authenticator", authenticatorEN);
    dispatch(
      app.refLink({ refId, type: REFLINK_TYPES.ENABLE_GOOGLE_AUTHENTICATOR }, (_error, result) => {
        if (_error) {
          setRedirect(`/error/${_error.code}`);
        } else {
          setQRcode(result.qrcode);
          setLoading(false);
        }
      })
    );
  }, []);

  const handleSetCode = (e) => {
    formik.current.setFieldTouched("code", true);
    formik.current.setFieldValue("code", e);
    setError(false);
  };

  const onSubmited = (_error) => {
    if (_error) {
      switch (_error.code) {
        case 3600:
          setError(_error);
          formik.current.errors.code = t("errors:3600");
          break;
        case 8300:
          setError({ code: 8301 });
          setCancel(true);
          break;
        default:
          setError(_error);
      }
      if (attemps === 0) {
        setError({ code: 8301 });
        setCancel(true);
      }
      formik.current.setFieldValue("code", initialValues.code);
      formik.current.setFieldTouched("code", false);
      codeInput.current.reset();
      setSubmit(false);
    } else {
      setRedirect("/home/default");
    }
  };

  const onSubmit = (values) => {
    setSubmit(true);
    setAttemps(attemps - 1);
    setError(false);
    dispatch(
      profile.authenticatorEnable3({ ...values, refId, type: AUTHENTICATORS.GOOGLE }, onSubmited)
    );
    return true;
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  const validationSchema = () =>
    Yup.object().shape({
      code: Yup.string()
        .required(t("yup:yup-required", { name: t("code") }))
        .min(6, t("yup:min", { name: t("code"), char: t("6") })),
    });

  if (redirect) return <Redirect to={redirect} />;
  if (loading) return <Spinner />;

  const src = `data:image/jpeg;base64,${qrcode.img}`;

  return (
    <BasicLayout
      title={t("authenticator:title")}
      description={t("authenticator:subtitle")}
      image={curved8}
    >
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values, errors, touched, handleSubmit }) => (
          <Card>
            <Form onSubmit={handleSubmit} noValidate name="authForm">
              <SuiBox p={3} mb={1} textAlign="center">
                <SuiTypography variant="h5" fontWeight="medium">
                  <Trans>authenticator:authenticator</Trans>
                </SuiTypography>
              </SuiBox>
              <SuiBox pt={2} pb={3} px={3}>
                <SuiBox component="form" role="form">
                  <SuiBox mb={2}>
                    <TimelineList>
                      <TimelineItem
                        key="Install"
                        color="success"
                        icon="install_mobile"
                        title="Install"
                        description="Install Google Authenticator on your phone."
                      />
                      <TimelineItem
                        key="open"
                        color="error"
                        icon="book_online"
                        title="Open"
                        description="Open Google Authenticator app."
                      />
                      <TimelineItem
                        key="menu"
                        color="info"
                        icon="menu"
                        title="Tap menu"
                        description='Tap menu, then tap "Set up account" then tap "Scan a barrcode".'
                      />
                      <TimelineItem
                        key="scan"
                        color="warning"
                        icon="qr_code_scanner"
                        title="Scan"
                        description='Your phone will now be in a "scanning" mode. When you are in this mode, please scan the barcode below:'
                      >
                        <SuiBox mb={2}>
                          <img id="base64image" src={src} alt="QR Code" />
                        </SuiBox>
                      </TimelineItem>
                      <TimelineItem
                        key="code"
                        color="primary"
                        icon="vpn_key"
                        title="Enter code"
                        description="Once you have scanned the barrcode, enter the six digit code below:"
                      >
                        <SuiBox mb={2} mt={2}>
                          <SuiCodeInput
                            name="code"
                            type="number"
                            fields={6}
                            value={values.code}
                            ref={codeInput}
                            onChange={handleSetCode}
                            isValid={!(touched.code && errors.code)}
                            disabled={submit || cancel}
                            filterChars={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
                            filterCharsIsWhitelist
                            pattern="[0-9]"
                            helperText={errors.code}
                          />
                        </SuiBox>
                        <Error error={error} color="primary" />
                      </TimelineItem>
                      <TimelineItem
                        key="check"
                        color="success"
                        icon="check_circle_outline"
                        title="Check"
                        description="Check the code by entering the following Check button:"
                      >
                        {submit && (
                          <SuiBox mt={1} mb={1}>
                            {" "}
                            <SuiSpinner size={50} minHeight={50} />{" "}
                          </SuiBox>
                        )}
                        <SuiBox mb={2} mt={2}>
                          {cancel ? (
                            <SuiButton
                              variant="gradient"
                              color="dark"
                              fullWidth
                              disabled={submit}
                              onClick={() => {
                                setRedirect("/");
                              }}
                            >
                              cancel
                            </SuiButton>
                          ) : (
                            <SuiButton
                              variant="gradient"
                              color="dark"
                              fullWidth
                              disabled={submit}
                              onClick={() => {
                                formik.current.submitForm();
                              }}
                            >
                              check
                            </SuiButton>
                          )}
                        </SuiBox>
                      </TimelineItem>
                    </TimelineList>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Form>
          </Card>
        )}
      </Formik>
    </BasicLayout>
  );
}

GoogleAuthenticatorActivate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      refId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(GoogleAuthenticatorActivate);
