/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState } from "react";

// PropTypes
import PropTypes from "prop-types";

// i18n
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";

// Components
import SMSAuthenticatorEditDialog from "layouts/profile/authenticators/components/SMSAuthenticatorEditDialog";
import DisableAuthenticatorDialog from "layouts/profile/authenticators/components/DisableAuthenticatorDialog";
import SMSAuthenticatorEnableDialog from "layouts/profile/authenticators/components/SMSAuthenticatorEnableDialog";
// Images
import sms from "assets/images/logos/sms.png";

const SMSAuthenticator = ({ auth, load, t }) => {
  const [edit, setEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [enable, setEnable] = useState(false);
  const handleClose = () => {
    setEdit(false);
    setDisable(false);
    setEnable(false);
    load();
  };
  const { borderWidth, borderColor } = borders;
  return (
    <Grid item xs={12} md={6}>
      {edit && <SMSAuthenticatorEditDialog auth={auth} handleClose={handleClose} />}
      {enable && <SMSAuthenticatorEnableDialog auth={auth} handleClose={handleClose} />}
      {disable && <DisableAuthenticatorDialog auth={auth} handleClose={handleClose} />}
      <SuiBox
        border={`${borderWidth[1]} solid ${borderColor}`}
        borderRadius="lg"
        display="block"
        alignItems="center"
        p={3}
      >
        <SuiBox display="flex" justifyContent="space-between" alignItems="center">
          <SuiBox component="img" src={sms} alt="SMS Authenticator" width="10%" mr={2} />
          <SuiTypography variant="h6" fontWeight="medium" width="90%">
            {auth.mobilePhone}
          </SuiTypography>
          <SuiBox ml="auto" lineHeight={0}>
            <Tooltip title={t("profile:authenticator-edit")} placement="top">
              <Icon
                sx={{ cursor: "pointer" }}
                fontSize="small"
                onClick={() => {
                  setEdit(true);
                }}
              >
                edit
              </Icon>
            </Tooltip>
          </SuiBox>
        </SuiBox>

        <SuiBox
          display="flex"
          borderTop={`${borderWidth[1]} dashed ${borderColor}`}
          justifyContent="flex-end"
          mt={2}
          p={1}
        >
          {auth.status === 0 ? (
            <Tooltip title={t("profile:authenticator-disable")} placement="top">
              <SuiButton
                color="dark"
                variant="outlined"
                size="small"
                sx={{
                  borderRadius: "3px",
                  borderColor: { borderColor },
                  "&:hover": { backgroundColor: "#f1f1f1" },
                  "&:focus": { backgroundColor: "#f1f1f1" },
                }}
                onClick={() => {
                  setEnable(true);
                }}
              >
                <Trans>enable</Trans>
              </SuiButton>
            </Tooltip>
          ) : (
            <Tooltip title={t("profile:authenticator-enable")} placement="top">
              <SuiButton
                color="dark"
                variant="outlined"
                size="small"
                sx={{
                  borderRadius: "3px",
                  borderColor: { borderColor },
                  "&:hover": { backgroundColor: "#f1f1f1" },
                  "&:focus": { backgroundColor: "#f1f1f1" },
                }}
                onClick={() => {
                  setDisable(true);
                }}
              >
                <Trans>disable</Trans>
              </SuiButton>
            </Tooltip>
          )}
        </SuiBox>
      </SuiBox>
    </Grid>
  );
};

SMSAuthenticator.propTypes = {
  auth: PropTypes.shape({
    mobilePhone: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
  load: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SMSAuthenticator);
