/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable class-methods-use-this */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Link from "@mui/material/Link";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";

// Constants
import Constants from "constants/Constants";

// Actions
import * as web3 from "actions/web3";

import * as CryptoUtil from "utils/CryptoUtil";

const { TRANSACTION_TYPES, TRANSACTION_STATUSES } = Constants;
// Soft UI Dashboard PRO React base styles

const utils = require("web3-utils");

function Stage3({ name, currency, t }) {
  const dispatch = useDispatch();
  const crypto = useSelector((state) => state.appStore.assets[name]);
  const exchangeRates = useSelector((state) => state.appStore.exchangeRates.exchanges[name].price);
  const exchangeRate = exchangeRates[currency];

  const {
    status,
    error,
    symbol,
    type,
    from,
    to,
    value,
    decimals,
    gasPrice,
    gasLimit,
    transactionHash,
  } = useSelector((state) => state.web3Store.transactions[name]);

  const [more, setMore] = useState(false);

  let equivalent = null;

  if (type === TRANSACTION_TYPES.SEND && !crypto.test) {
    equivalent = CryptoUtil.toAmountAsString(value, decimals, 2, exchangeRate);
    equivalent = value > 0 && equivalent < 0.01 ? "< 0.0.1" : equivalent;
    equivalent = (
      <>
        {equivalent} {currency}
      </>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid spacing={2} item xs={12}>
        <Box sx={{ margin: 1 }}>
          {status === TRANSACTION_STATUSES.PENDING && (
            <SuiTypography component="p" variant="h5" align="center" width="100%">
              <Trans>crypto:transaction-status-description-1</Trans>
            </SuiTypography>
          )}
          {status === TRANSACTION_STATUSES.REJECTED && (
            <SuiTypography
              component="p"
              variant="h5"
              align="center"
              noWrap
              width="100%"
              color="error"
            >
              <Trans>{`errors:${error.code}`}</Trans>
            </SuiTypography>
          )}
          {status === TRANSACTION_STATUSES.FAILED && (
            <SuiTypography
              component="p"
              variant="h5"
              align="center"
              noWrap
              width="100%"
              color="warning"
            >
              <Trans>crypto:transaction-status-description-3</Trans>
            </SuiTypography>
          )}

          {status === TRANSACTION_STATUSES.FINISHED && (
            <SuiTypography component="p" variant="h5" align="center" noWrap width="100%">
              <Trans>crypto:transaction-status-description-4</Trans>
            </SuiTypography>
          )}

          <TableContainer component={Box}>
            <Table aria-label="collapsible table">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <h4>
                      <Trans>crypto:transaction-type</Trans>
                    </h4>
                  </TableCell>
                  <TableCell>
                    <Trans values={{ symbol }}>{`crypto:transaction-type-${type}`}</Trans>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <h4>
                      <Trans>crypto:amount</Trans>
                    </h4>
                    {equivalent && <Trans>crypto:equivalent</Trans>}
                  </TableCell>
                  <TableCell>
                    <h4>
                      {CryptoUtil.toAmountAsString(value, decimals)} {symbol}
                    </h4>
                    {equivalent}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <h4>
                      <Trans>crypto:status</Trans>
                    </h4>
                  </TableCell>
                  <TableCell align="left">
                    <>
                      {status === TRANSACTION_STATUSES.PENDING && (
                        <SuiButton variant="gradient" color="info" disabled>
                          <Trans>crypto:transaction-status-1</Trans>
                        </SuiButton>
                      )}
                      {status === TRANSACTION_STATUSES.REJECTED && (
                        <SuiButton variant="gradient" color="warning" disabled>
                          <Trans>crypto:transaction-status-2</Trans>
                        </SuiButton>
                      )}
                      {status === TRANSACTION_STATUSES.FAILED && (
                        <SuiButton variant="gradient" color="error" disabled>
                          <Trans>crypto:transaction-status-3</Trans>
                        </SuiButton>
                      )}
                      {status === TRANSACTION_STATUSES.FINISHED && (
                        <SuiButton variant="gradient" color="success" disabled>
                          <Trans>crypto:transaction-status-4</Trans>
                        </SuiButton>
                      )}
                    </>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Trans>crypto:from-wallet</Trans>
                  </TableCell>
                  <TableCell>
                    {from} <SuiIconCopyToClipboard text={from} name={t("from")} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Trans>crypto:to-wallet</Trans>
                  </TableCell>
                  <TableCell>
                    {to} <SuiIconCopyToClipboard text={to} name={t("to")} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <h4>
                      <Trans>crypto:network-fee</Trans>
                    </h4>
                    <Trans>crypto:equivalent</Trans>
                  </TableCell>
                  <TableCell>
                    <h4>
                      {utils.fromWei((gasPrice * gasLimit).toString())} {name.symbol}
                    </h4>
                    {utils.fromWei((gasPrice * gasLimit).toString(), "Gwei")} Gwei
                  </TableCell>
                </TableRow>
                {transactionHash && (
                  <TableRow>
                    <TableCell>
                      <Trans>crypto:hash</Trans>
                    </TableCell>
                    <TableCell>
                      <div>
                        <Link
                          sx={{ focusVisible: { outline: "none" } }}
                          href={crypto.scan + transactionHash}
                          target="_blank"
                          underline="none"
                        >
                          {transactionHash}
                        </Link>{" "}
                        <SuiIconCopyToClipboard text={transactionHash} name={t("hash")} />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Collapse in={more} timeout="auto" unmountOnExit>
              <>
                {" "}
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: "30%" }}>
                        <Trans>crypto:gasLimit</Trans>
                      </TableCell>
                      <TableCell>{gasLimit}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Trans>crypto:gasPrice</Trans>
                      </TableCell>
                      <TableCell>
                        {utils.fromWei(gasPrice.toString())} {crypto.symbol} (
                        {utils.fromWei(gasPrice.toString(), "Gwei")} Gwei)
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            </Collapse>
            <SuiBox pt="0.75rem" pl="1rem" pb="0.2rem">
              <Link
                sx={{ focusVisible: { outline: "none" } }}
                href="#"
                onClick={() => {
                  setMore(!more);
                }}
              >
                <Trans>{more ? "see-less" : "see-more"}</Trans>{" "}
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setMore(!more);
                  }}
                >
                  {more ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Link>
            </SuiBox>
          </TableContainer>
          <Divider variant="middle" />
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <SuiTypography component="label" variant="caption" textTransform="capitalize">
              <Trans values={{ one: 3, two: 3 }}>step-of</Trans>
            </SuiTypography>
            <Grid item xs={5}>
              {status !== TRANSACTION_STATUSES.PENDING && (
                <SuiButton
                  name="submit"
                  component={SuiButton}
                  onClick={() => {
                    dispatch(web3.clearTransaction(name));
                  }}
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  <Trans>finish</Trans>
                </SuiButton>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

Stage3.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  name: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Stage3);
