/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

import { Trans, withTranslation } from "react-i18next";

import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiAlert from "components/sui/SuiAlert";

// Soft UI Dashboard PRO React example components
import DefaultLayout from "components/layout/LayoutContainers/DefaultLayout";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import errorIcon from "assets/images/icons/error.svg";

const { values } = breakpoints;

function Error({ error, title, titleB, names }) {
  return (
    <DefaultLayout title={titleB} names={names}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <SuiBox mb={3} p={1}>
            <SuiTypography
              variant={window.innerWidth < values.sm ? "h4" : "h3"}
              textTransform="capitalize"
              fontWeight="bold"
            >
              {title}
            </SuiTypography>
          </SuiBox>
        </Grid>
        <SuiBox component="img" src={errorIcon} alt="Rocketship" mb={3} width="200px" />
        <SuiBox>
          <SuiAlert alert={error} color="primary">
            <Trans>{`error:${error.code}`}</Trans>
          </SuiAlert>
        </SuiBox>
      </Grid>
    </DefaultLayout>
  );
}

// Setting default values for the props of DashboardNavbar
Error.defaultProps = {
  titleB: undefined,
  names: [],
};

Error.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  titleB: PropTypes.string,
  names: PropTypes.arrayOf(PropTypes.string),
};

export default withTranslation()(Error);
