/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/sui/SuiTypography";
import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import QRCode from "react-qr-code";
import getSymbolFromCurrency from "currency-symbol-map";

import * as CryptoUtil from "utils/CryptoUtil";

function Receive({ name, asset, t }) {
  const currency = useSelector((state) => state.appStore.settings.defaultCurrency);
  const exchangeRates = useSelector((state) => state.appStore.exchangeRates.exchanges[name].price);
  const exchangeRate = exchangeRates[currency];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm="auto">
        <Box>
          <QRCode value={window.mydappwallet.wallet.EVM} size={150} />
        </Box>
      </Grid>
      <Grid spacing={2} item xs={12} sm="auto">
        <Grid spacing={2} item xs={12}>
          <SuiTypography variant="h6" fontWeight="medium" color="info" textTransform="capitalize">
            <Trans>crypto:receive-balance</Trans>
          </SuiTypography>
        </Grid>

        <Grid spacing={1} item xs={12}>
          <SuiTypography
            variant="body1"
            fontWeight="medium"
            color="text"
            textTransform="capitalize"
          >
            {CryptoUtil.toAmountAsString(asset.balance, asset.decimals)} {asset.symbol}
          </SuiTypography>
          {!crypto.test && (
            <SuiTypography
              variant="body2"
              fontWeight="light"
              color="text"
              textTransform="capitalize"
              sx={{ lineHeight: 1 }}
            >
              {getSymbolFromCurrency(currency)}{" "}
              {CryptoUtil.toAmountAsString(asset.balance, asset.decimals, 2, exchangeRate)}
            </SuiTypography>
          )}
        </Grid>
        <Grid spacing={2} item xs={12} mt={2}>
          <SuiTypography variant="h6" fontWeight="medium" color="info" textTransform="capitalize">
            <Trans>crypto:receive-address</Trans>
          </SuiTypography>
        </Grid>
        <Grid spacing={2} item xs={12}>
          <SuiTypography
            variant="body2"
            fontWeight="medium"
            color="text"
            textTransform="capitalize"
          >
            {window.mydappwallet.wallet.EVM}{" "}
            <SuiIconCopyToClipboard
              text={window.mydappwallet.wallet.EVM}
              name={t("crypto:receive-address")}
            />
          </SuiTypography>
        </Grid>
      </Grid>
    </Grid>
  );
}

Receive.propTypes = {
  name: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    balance: PropTypes.number,
    decimals: PropTypes.number,
    symbol: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = () => ({});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Receive));
