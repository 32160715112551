import { Default } from "react-spinners-css";

// react-router-dom components

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
// RTL plugins

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";

function Spinner({ background, size, minHeight }) {
  return (
    <SuiBox
      width="100%"
      height="100%"
      minHeight={minHeight}
      bgColor={background}
      sx={{ overflowX: "hidden", position: "relative" }}
    >
      <Default
        color={colors.info.main}
        size={size}
        style={{
          position: "absolute",
          // margin: "-25px 0 0 -25px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </SuiBox>
  );
}

Spinner.defaultProps = {
  background: "default",
  size: 200,
  minHeight: 400,
};

// Typechecking props for the PageLayout
Spinner.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  size: PropTypes.number,
  minHeight: PropTypes.number,
};

export default Spinner;
