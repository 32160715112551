/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
// prettier-ignore
const Jsonrpc = require('../lib/jsonrpc.js');
const { errors } = require("web3-core-helpers");
const XHR2 = require("xhr2-cookies").XMLHttpRequest; // jshint ignore: line
const http = require("http");
const https = require("https");
const Web3 = require("web3");
const MyDappWalletWeb3Provider = require("mydappwallet/web3/provider");
const formatter = require("../lib/formatters");
// const MyDappWalletWeb3 = require("mydappwallet/web3/web3");
// const Method = require("web3-core-method");
// var abi = require('web3-eth-abi');

const PSD2Provider = function PSD2Provider(host, mydappwallet, options) {
  this.mydappwallet = mydappwallet;
  this.withCredentials = options.withCredentials || false;
  this.timeout = options.timeout || 0;
  this.headers = options.headers;
  this.sessionId = 0;
  this.user = undefined;
  this.agent = options.agent;
  this.redirect = options.redirect;
  this.connected = false;
  this.nextId = 42;
  // keepAlive is true unless explicitly set to false
  const keepAlive = options.keepAlive !== false;
  this.host = host || "http://localhost:8545";
  if (!this.agent) {
    if (this.host.substring(0, 5) === "https") {
      this.httpsAgent = new https.Agent({ keepAlive });
    } else {
      this.httpAgent = new http.Agent({ keepAlive });
    }
  }
  this.method = this.method.bind(this);

};


PSD2Provider.prototype.getBalance = function (iban, callback) {
  const _this = this;
  const payload = { jsonrpc: "2.0", id: _this.nextId++, method: "getBalance", params: [iban] };
  this.send(
    payload,
    _this._jsonrpcResultCallback((error, result) => {
      if (callback) callback(error, result);
    }, payload)
  );
  return true;
};


PSD2Provider.prototype.method = function (name) {
  this[name] = function (params, callback) {
    const _this = this;
    params = formatter.hexParamsFormatter(params);

    const payload = { jsonrpc: "2.0", id: _this.nextId++, method: name, params: [params] };
    this.send(
      payload,
      _this._jsonrpcResultCallback((error, result) => {
        if (callback) callback(error, result);
      }, payload)
    );
    return true;
  };
};

PSD2Provider.prototype.event = function (event, err, result) {
  if (result && result.info) {
    event.info = result.info;
  }
  if (err) {
    event.error = err;
  } else if (result && result.error) {
    event.error = result.error;
  } else if (!Jsonrpc.isValidResponse(result)) {
    return (event.error = errors.InvalidResponse(result));
  } else {
    event.result = result.result;
  }

  switch (event.target) {
    case "mdw-inpage":
      if (window.opener) {
        window.opener.postMessage(event, "*");
      }

      break;
  }
};


PSD2Provider.prototype._prepareRequest = function () {
  let request;
  // the current runtime is a browser
  if (typeof XMLHttpRequest !== "undefined") {
    request = new XMLHttpRequest();
  } else {
    request = new XHR2();
    const agents = {
      httpsAgent: this.httpsAgent,
      httpAgent: this.httpAgent,
      baseUrl: this.baseUrl,
    };
    if (this.agent) {
      agents.httpsAgent = this.agent.https;
      agents.httpAgent = this.agent.http;
      agents.baseUrl = this.agent.baseUrl;
    }
    request.nodejsSet(agents);
  }

  request.open("POST", this.host, true);
  request.setRequestHeader("ApiKey", this.mydappwallet.apiKey);
  if (this.sessionId) request.setRequestHeader("SessionId", this.mydappwallet.sessionId);
  request.setRequestHeader("Content-Type", "application/json");
  //
  request.timeout = this.timeout;
  request.withCredentials = this.withCredentials;
  if (this.headers) {
    this.headers.forEach((header) => {
      request.setRequestHeader(header.name, header.value);
    });
  }
  return request;
};

PSD2Provider.prototype.send = function (payload, callback) {
  const _this = this;
  const request = this._prepareRequest();
  request.onreadystatechange = function () {
    if (request.readyState === 4 && request.timeout !== 1) {
      let result = request.responseText;
      let error = null;
      try {
        result = JSON.parse(result);
      } catch (e) {
        error = _this.InvalidResponse(request.responseText);
      }
      _this.connected = true;
      if (result.event) {
        _this.event(result.event, error, result);
      }
      callback(error, result);
    }
  };
  request.ontimeout = function () {
    _this.connected = false;
    callback(errors.ConnectionTimeout(this.timeout));
  };
  try {
    request.send(JSON.stringify(payload));
  } catch (error) {
    this.connected = false;
    callback(errors.InvalidConnection(this.host));
  }
};

// eslint-disable-next-line no-underscore-dangle
PSD2Provider.prototype._jsonrpcResultCallback = function (callback, payload) {
  const _this = this;
  return function (err, result) {
    if (result && result.id && payload.id !== result.id) {
      return callback(
        new Error(
          `Wrong response id ${result.id} (expected: ${payload.id}) in ${JSON.stringify(payload)}`
        )
      );
    }
    if (err) {
      return callback(err);
    }
    if (result && result.error) {
      return callback(_this.ErrorResponse(result));
    }
    if (!Jsonrpc.isValidResponse(result)) {
      return callback(errors.InvalidResponse(result));
    }
    callback(null, result.result, result.info);
  };
};

PSD2Provider.prototype.InvalidResponse = function (result) {
  const message =
    !!result && !!result.error && !!result.error.message
      ? result.error.message
      : `Invalid JSON RPC response: ${JSON.stringify(result)}`;
  const err = new Error(message);
  const code = !!result.error && !!result.error.code ? result.error.code : 500;
  err.code = code;
  return err;
};

PSD2Provider.prototype.ErrorResponse = function (result) {
  const message =
    !!result && !!result.error && !!result.error.message
      ? result.error.message
      : JSON.stringify(result);
  const data = !!result.error && !!result.error.data ? result.error.data : null;
  const err = new Error(message);
  err.data = data;
  const code = !!result.error && !!result.error.code ? result.error.code : null;
  err.code = code;
  return err;
};

module.exports = PSD2Provider;
