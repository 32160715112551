import { forwardRef } from "react";
import { PropTypes } from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiTokenIcon from "components/sui/SuiTokenIcon";

import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

import * as CryptoUtil from "utils/CryptoUtil";
// Soft UI Dashboard PRO React helper functions
const { inputColors } = colors;
const { borderWidth } = borders;
// Soft UI Dashboard PRO React base styles

const FromField = forwardRef(({ asset, balance }, ref) => (
  <SuiBox
    borderRadius={borders.borderRadius.lg}
    border={`${borderWidth[1]} solid ${inputColors.borderColor.main}`}
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    p={1}
    ref={ref}
  >
    {asset.icon ? (
      <div
        className="d-inline-block item-icon-wrapper"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: asset.icon }}
      />
    ) : (
      <SuiTokenIcon address={asset.address} />
    )}

    <SuiBox width="100%" display="flex" flexDirection="column" lineHeight={1} ml={1}>
      <SuiTypography variant="button" fontWeight="medium">
        {asset.symbol}
      </SuiTypography>
      <SuiTypography variant="caption" fontWeight="medium">
        {CryptoUtil.toAmountAsString(balance, asset.decimals)} {asset.symbol}
      </SuiTypography>
    </SuiBox>
  </SuiBox>
));

FromField.propTypes = {
  asset: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    decimals: PropTypes.number.isRequired,
  }).isRequired,
  balance: PropTypes.number.isRequired,
};

export default withTranslation()(FromField);
