/* eslint-disable class-methods-use-this */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
// Soft UI Dashboard PRO React components
import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";
import SuiFormField from "components/sui/SuiFormField";
import SuiButton from "components/sui/SuiButton";
import SuiInput from "components/sui/SuiInput";
import SuiAlert from "components/sui/SuiAlert";
import * as Yup from "yup";
// eslint-disable-next-line no-unused-vars
import { Formik, Form } from "formik";

// Actions
import * as app from "actions/app";

// eslint-disable-next-line import/no-named-as-default
import Row from "layouts/asset/evm/tabs/Assets/Row";
import DeleteAssetDialog from "layouts/asset/evm/tabs/Assets/DeleteAssetDialog";

const abi = require("erc-20-abi");

const initialValues = { address: "", name: "", symbol: "", decimals: 18 };

function Assets({ asset, t }) {
  const dispatch = useDispatch();
  const tokens = useSelector((state) => state.appStore.tokens);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [deleteAsset, setDeleteAsset] = useState(false);
  const formik = useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteAsset = (index, row) => {
    setDeleteAsset(row);
  };

  const handleDeleteAssetkClose = () => {
    setDeleteAsset(false);
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validateAddress = (value) => {
    if (!value) return true;
    switch (asset.type) {
      case "EVM":
        return asset.web3.utils.isAddress(value);
      default:
        return false;
    }
  };

  const validateToken = (value) => {
    const { web3 } = asset;

    if (!web3.utils.isAddress(value)) {
      formik.setFieldValue("symbol", "", true);
      return false;
    }

    try {
      const erc20 = new web3.eth.Contract(abi, value);
      erc20.methods.name().call((_error, result) => {
        if (_error) {
          formik.current.setFieldValue("name", "", true);
          formik.current.setFieldValue("symbol", "", true);
          formik.current.setFieldValue("decimals", 18, false);
          return false;
        }
        formik.current.setFieldValue("name", result, true);
        erc20.methods.symbol().call((__error, _result) => {
          if (__error) {
            formik.current.setFieldValue("symbol", result.slice(0, 3), true);
            formik.current.setFieldValue("decimals", 18, false);
            formik.current.setFieldValue("decimals", 18, false);
            return false;
          }
          formik.current.setFieldValue("symbol", _result, true);
          erc20.methods.decimals().call((___error, __result) => {
            if (___error) {
              formik.current.setFieldValue("decimals", 18, false);
              return false;
            }
            formik.current.setFieldValue("decimals", __result, false);
            return true;
          });
          return false;
        });
        return false;
      });
    } catch (_error) {
      formik.current.setFieldValue("name", "", true);
      formik.current.setFieldValue("symbol", "", true);
      formik.current.setFieldValue("decimals", 18, false);
    }
    return false;
  };

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(2, t("yup:min", { name: t("name"), char: 2 }))
        .max(50, t("yup:max", { name: t("name"), char: 50 }))
        .required(t("yup:required", { name: t("name") })),
      address: Yup.string()
        .required(t("yup:required", { name: t("address") }))
        .test({
          name: "address",
          exclusive: true,
          message: t("yup:format", { name: t("address") }),
          test: validateAddress,
        }),
      symbol: Yup.string()
        .required(t("yup:required", { name: t("symbol") }))
        .min(2, t("yup:min", { name: t("symbol"), char: 2 }))
        .max(10, t("yup:max", { name: t("symbol"), char: 10 })),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  const onSubmited = (_error) => {
    if (_error) {
      switch (_error.code) {
        case 1100:
          formik.current.errors.address = t("error:1100");
          break;
        default:
          setError(_error);
      }
    } else {
      formik.current.resetForm();
    }
    setSubmit(false);
  };

  const onSubmit = (values) => {
    setSubmit(true);
    setError(false);
    dispatch(app.tokenAdd({ ...values, network: asset.chain }, onSubmited));
  };

  return (
    <>
      <DeleteAssetDialog
        open={deleteAsset}
        row={deleteAsset}
        handleClose={handleDeleteAssetkClose}
      />
      <Grid spacing={2} item xs={12} sm="auto">
        <Grid spacing={2} item xs={12}>
          <TableContainer component={Box}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Trans>crypto:assets-col1</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>crypto:assets-col2</Trans>
                  </TableCell>
                  <TableCell align="left">
                    <Trans>crypto:assets-col3</Trans>
                  </TableCell>
                  <TableCell align="left">
                    <Trans>crypto:assets-col4</Trans>
                  </TableCell>
                  <TableCell align="center">
                    <Trans>crypto:assets-col5</Trans>
                  </TableCell>
                  <TableCell align="center">
                    <Trans>crypto:assets-col6</Trans>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tokens
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Row
                      key={row.id}
                      row={row}
                      index={index}
                      handleDeleteAsset={handleDeleteAsset}
                    />
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tokens.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              styleOverrides={{
                select: {
                  width: "50px",
                },
              }}
            />
          </TableContainer>
        </Grid>
        <Divider />
        <SuiBox p={1}>
          <SuiTypography variant="h5">
            <Trans>crypto:assets-add</Trans>
          </SuiTypography>
        </SuiBox>
        <Formik
          initialValues={initialValues}
          validate={validate(validationSchema)}
          onSubmit={onSubmit}
          innerRef={formik}
        >
          {({ values, errors, handleChange, handleSubmit }) => (
            <SuiBox component="form" onSubmit={handleSubmit} noValidate name="addForm">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SuiFormField
                    label={t("address")}
                    name="address"
                    placeholder={t("address")}
                    error={!!errors.address}
                    helperText={errors.address}
                    onChange={(e) => {
                      handleChange(e);
                      validateToken(e.target.value);
                    }}
                    value={values.address}
                    disabled={submit}
                    component={SuiInput}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SuiFormField
                    label={t("name")}
                    name="name"
                    placeholder={t("name")}
                    error={!!errors.name}
                    helperText={errors.name}
                    onChange={handleChange}
                    value={values.name}
                    disabled={submit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SuiFormField
                    label={t("symbol")}
                    name="symbol"
                    placeholder={t("symbol")}
                    error={!!errors.symbol}
                    helperText={errors.symbol}
                    onChange={handleChange}
                    value={values.symbol}
                    disabled={submit}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SuiFormField
                    label={t("decimals")}
                    name="decimals"
                    placeholder={t("decimals")}
                    error={!!errors.decimals}
                    helperText={errors.decimals}
                    onChange={handleChange}
                    value={values.decimals}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    height="100%"
                  >
                    <SuiFormField
                      label="&nbsp;"
                      name="submit"
                      disabled={submit}
                      component={SuiButton}
                      onClick={() => {
                        formik.current.submitForm();
                      }}
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      <Trans>add</Trans>
                    </SuiFormField>
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
          )}
        </Formik>
        {error && (
          <Grid mt={3}>
            <SuiBox>
              <SuiAlert alert={error} color="primary">
                <Trans>{`error:${error.code}`}</Trans>
              </SuiAlert>
            </SuiBox>
          </Grid>
        )}
      </Grid>
    </>
  );
}

Assets.propTypes = {
  asset: PropTypes.shape({
    chain: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    web3: PropTypes.PropTypes.shape({
      eth: PropTypes.PropTypes.shape({
        Contract: PropTypes.func,
      }),
      utils: PropTypes.PropTypes.shape({
        isAddress: PropTypes.func,
      }),
    }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Assets);
