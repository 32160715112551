// i18n
import { Trans, withTranslation } from "react-i18next";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/sui/SuiTypography";

const Step = ({ x, from }) => (
  <SuiTypography component="label" variant="caption" textTransform="capitalize">
    <Trans values={{ one: x, two: from }}>step-of</Trans>
  </SuiTypography>
);

// Typechecking props for the PageLayout
Step.propTypes = {
  x: PropTypes.number.isRequired,
  from: PropTypes.number.isRequired,
};

export default withTranslation()(Step);
