/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import Asset from "layouts/asset";
import Default from "layouts/default";
import Applications from "layouts/developers/applications";
import Application from "layouts/developers/application";
import Contract from "layouts/developers/contract";
import Auth from "layouts/pages/auth";

import ChangePassword from "layouts/profile/changePassword";
import ChangeEmail from "layouts/profile/changeEmail";
import Authenticators from "layouts/profile/authenticators";
import Machines from "layouts/profile/machines";
import GoogleAuthenticatorActivate from "layouts/pages/ga-activate";
import ResetPassword2 from "layouts/pages/auth/reset-password2";
import LockBasic from "layouts/pages/lock";
import VerificationBasic from "layouts/pages/2-step-verification";
import Error from "layouts/pages/error";

import Legal from "layouts/pages/legal";
import Privacy from "layouts/pages/legal/privacy";
import Terms from "layouts/pages/legal/terms";
// Soft UI Dashboard PRO React icons
import Shop from "components/Icons/Shop";
import Document from "components/Icons/Document";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Default",
        key: "default",
        route: "/home/default",
        component: Default,
        authorize: ["authenticated"],
      },
      {
        name: "Error",
        key: "error",
        collapse: [
          {
            name: "Error",
            key: "error",
            route: "/error/:code",
            component: Error,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Crypto",
    key: "crypto",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Crypto",
        key: "crypto",
        route: "/crypto/:name",
        component: Asset,
        authorize: ["authenticated"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Developers",
    key: "developers",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Applications",
        key: "applications",
        route: "/developers/applications",
        component: Applications,
        authorize: ["developer"],
      },
      {
        name: "Application",
        key: "application",
        route: "/developers/applications/:uid",
        component: Application,
        authorize: ["developer"],
      },
      {
        name: "Contract",
        key: "contract",
        route: "/developers/applications/:uid/:address",
        component: Contract,
        authorize: ["developer"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Change Password",
        key: "changePassword",
        route: "/profile/changePassword",
        component: ChangePassword,
        authorize: ["authenticated"],
      },
      {
        name: "Change Email",
        key: "changeEmail",
        route: "/profile/changeEmail",
        component: ChangeEmail,
        authorize: ["authenticated"],
      },
      {
        name: "Your machines",
        key: "machines",
        route: "/profile/machines",
        component: Machines,
        authorize: ["authenticated"],
      },
      {
        name: "Authenticators",
        key: "authenticators",
        route: "/profile/authenticators",
        component: Authenticators,
        authorize: ["authenticated"],
      },
      {
        name: "GoogleAuthenticatorActivate",
        key: "ga-activate",
        route: "/profile/ga-activate/:refId",
        component: GoogleAuthenticatorActivate,
        authorize: ["authenticated"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Legal",
    key: "legal",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Legal",
        key: "legal",
        route: "/legal",
        component: Legal,
      },
      {
        name: "Privacy",
        key: "privacy",
        route: "/legal/privacy",
        component: Privacy,
      },
      {
        name: "Terms & Condition",
        key: "terms",
        route: "/legal/terms",
        component: Terms,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Authentication",
    key: "auth",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Auth",
        key: "auth",
        collapse: [
          {
            name: "Auth",
            key: "auth",
            route: "/auth",
            component: Auth,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password2",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/auth/reset-password/:refId",
            component: ResetPassword2,
          },
        ],
      },
      {
        name: "Lock",
        key: "lock",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/lock/basic",
            component: LockBasic,
          },
        ],
      },
      {
        name: "2-Step Verification",
        key: "2-step-verification",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/verification/basic",
            component: VerificationBasic,
          },
        ],
      },
    ],
  },
];

export default routes;
