import { useState, useRef } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";
import SuiFormField from "components/sui/SuiFormField";
import SuiAlert from "components/sui/SuiAlert";

import { Formik } from "formik";
import * as Yup from "yup";
import * as app from "actions/app";

function EditBookDialog({ row, type, open, handleClose, addressBookEdit, t }) {
  const [error, setError] = useState(false);
  const formik = useRef(null);
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, t("yup:min", { name: t("name"), char: 3 }))
        .required(t("yup:required", { name: t("name") })),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  const onSubmited = (_error) => {
    if (error) {
      setError(_error);
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    addressBookEdit({ address: row.address, name: values.name, type }, onSubmited);
    return true;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Formik
        initialValues={row}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <SuiBox component="form" onSubmit={handleSubmit} noValidate name="editForm">
            <DialogTitle>
              <Trans>crypto:books-edit-title</Trans>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Trans>crypto:books-edit-subtitle</Trans>
              </DialogContentText>
              <Grid item mt={1}>
                <SuiFormField
                  label={t("name")}
                  name="name"
                  placeholder={t("name")}
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                  value={values.name}
                />
              </Grid>
              {error && (
                <Grid mt={3}>
                  <SuiBox>
                    <SuiAlert alert={error} color="primary">
                      <Trans>{`error-${error.code}`}</Trans>
                    </SuiAlert>
                  </SuiBox>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <SuiButton onClick={handleClose}>
                <Trans>cancel</Trans>
              </SuiButton>
              <SuiButton
                onClick={() => {
                  formik.current.submitForm();
                }}
              >
                <Trans>save</Trans>
              </SuiButton>
            </DialogActions>
          </SuiBox>
        )}
      </Formik>
    </Dialog>
  );
}

EditBookDialog.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addressBookEdit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  addressBookEdit: (values, callback) => dispatch(app.addressBookEdit(values, callback)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditBookDialog));
