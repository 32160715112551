"use statics";

export default {
  APP_INIT_START: "APP_INIT_START",
  APP_INIT_SUCCESS: "APP_INIT_SUCCESS",
  APP_INIT_FAILED: "APP_INIT_FAILED",

  APP_INIT_CHAINS: "APP_INIT_CHAINS",

  APP_LOADING_START: "APP_LOADING_START",
  APP_LOADING_STOP: "APP_LOADING_STOP",
  APP_ERROR: "APP_ERROR",
  APP_ERROR_CLOSE: "APP_ERROR_CLOSE",
  APP_INFO: "APP_INFO",
  APP_INFO_CLOSE: "APP_INFO_CLOSE",

  APP_CHANGE_SETTINGS: "APP_CHANGE",

  APP_CONFIRM_TRANSACTION: "APP_CONFIRM_TRANSACTION",

  AUTH_SIGN_IN: "AUTH_SIGN_IN",
  AUTH_SIGN_IN2: "AUTH_SIGN_IN2",
  AUTH_SIGN_IN3: "AUTH_SIGN_IN3",
  AUTH_SIGN_OUT: "AUTH_SIGN_OUT",
  AUTH_SIGN_UP: "AUTH_SIGN_UP",
  AUTH_SIGN_UP2: "AUTH_SIGN_UP2",
  AUTH_SIGN_UP3: "AUTH_SIGN_UP3",
  AUTH_CHANGE_PASSWORD: "AUTH_CHANGE_PASSWORD",
  AUTH_RESET_PASSWORD: "AUTH_RESET_PASSWORD",

  PROFILE_AUTHENTICATOR_EDIT_SUCCESS: "PROFILE_AUTHENTICATOR_EDIT_SUCCESS",
  PROFILE_AUTHENTICATOR_ENABLE_SUCCESS: "PROFILE_AUTHENTICATOR_ENABLE_SUCCESS",
  PROFILE_AUTHENTICATOR_ENABLE_FAILED: "PROFILE_AUTHENTICATOR_ENABLE_FAILED",
  PROFILE_AUTHENTICATOR_DISABLE_SUCCESS: "PROFILE_AUTHENTICATOR_DISABLE_SUCCESS",
  PROFILE_AUTHENTICATOR_DISABLE_FAILED: "PROFILE_AUTHENTICATOR_DISABLE_FAILED",

  PROFILE_MACHINES: "PROFILE_MACHINES",
  PROFILE_MACHINE_DELETE: "PROFILE_MACHINE_DELETE",

  APP_BUYSELL_TRANSATION: "APP_BUYSELL_TRANSATION",
  APP_BUYSELL_TRANSATION_CLEAR: "APP_BUYSELL_TRANSATION_CLEAR",

  APP_BUY: "APP_BUY",

  APP_AUTHENTICATORS_SUCCESS: "APP_AUTHENTICATORS_SUCCESS",
  APP_AUTHENTICATOR: "APP_AUTHENTICATOR",
  APP_AUTHENTICATOR_CONFIRM: "APP_AUTHENTICATOR_CONFIRM",
  APP_REFLINK: "APP_REFLINK",
  APP_GAS_PRICE: "APP_GAS_PRICE",
  APP_EXCHANGE_RATES_SUCCESS: "APP_EXCHANGE_RATES_SUCCESS",
  APP_BALANCE_SUCCESS: "APP_BALANCE_SUCCESS",
  APP_HISTORY_SUCCESS: "APP_HISTORY_SUCCESS",
  APP_TRANSACTION_SUCCESS: "APP_TRANSACTION_SUCCESS",
  APP_TOKENS: "APP_TOKENS",
  APP_TOKEN_ADD: "APP_TOKEN_ADD",
  APP_TOKEN_DELETE: "APP_TOKEN_DELETE",
  APP_ADDRESSBOOK_SUCCESS: "APP_ADDRESSBOOK_SUCCESS",
  APP_ADDRESSBOOK_ADD: "APP_ADDRESSBOOK_ADD",
  APP_ADDRESSBOOK_EDIT: "APP_ADDRESSBOOK_EDIT",
  APP_ADDRESSBOOK_DELETE: "APP_ADDRESSBOOK_DELETE",

  APP_CONNECT: "APP_CONNECT",
  APP_APPLICATIONS: "APP_APPLICATIONS",
  APP_APPLICATION: "APP_APPLICATION",
  APP_APPLICATION_ADD: "APP_APPLICATION_ADD",
  APP_APPLICATION_EDIT: "APP_APPLICATION_EDIT",
  APP_CONTRACT: "APP_CONTRACT",
  APP_CONTRACT_ADD: "APP_CONTRACT_ADD",
  APP_CONTRACT_DELETE: "APP_CONTRACT_DELETE",

  WEB3_CREATE_TRANSACTION: "WEB3_CREATE_TRANSACTION",

  WEB3_SEND_TRANSACTION: "WEB3_SEND_TRANSACTION",
  WEB3_CONFIRM_TRANSACTION: "WEB3_CONFIRM_TRANSACTION",
  WEB3_UPDATE_TRANSACTION: "WEB3_UPDATE_TRANSACTION",
  WEB3_ERROR_TRANSACTION: "WEB3_ERROR_TRANSACTION",

  WEB3_CONFIRM_TRANSACTION_SUCESS: "WEB3_CONFIRM_TRANSACTION_SUCESS",

  WEB3_CLEAR_TRANSACTION: "WEB3_CLEAR_TRANSACTION",

  LIST: "LIST",
  HIDDENLIST: "HIDDENLIST",
  HIDDENITEM: "HIDDENITEM",
};
