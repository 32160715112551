import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components

import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React example components

import SuiBox from "components/sui/SuiBox";
import SuiFormField from "components/sui/SuiFormField";
import SuiButton from "components/sui/SuiButton";
import SuiSelect from "components/sui/SuiSelect";
import SuiAlert from "components/sui/SuiAlert";
// Soft UI Dashboard PRO React base styles

import * as Yup from "yup";
import { Formik } from "formik";

// i18m
import { Trans, withTranslation } from "react-i18next";

// Actions
import * as app from "actions/app";

const utils = require("web3-utils");

const initialValues = {
  name: "",
  chain: null,
  address: "",
  abi: "",
};

const ContractAddForm = ({ uid, handleAdd, t }) => {
  const dispatch = useDispatch();

  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [chainList] = useState([
    { value: 1, label: t("network-1") },
    { value: 56, label: t("network-56") },
    { value: 137, label: t("network-137") },
    { value: 888, label: t("network-888") },
  ]);
  const formik = useRef(null);
  const onSubmited = (_error, result) => {
    if (_error) {
      switch (_error.code) {
        case 9100:
          formik.current.errors.name = t("errors:9100");
          break;
        default:
          setError(_error);
      }
    } else {
      formik.current.resetForm();
      handleAdd(result);
    }
    setSubmit(false);
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(app.contractAdd({ ...values, uid }, onSubmited));
    return true;
  };

  const handleSelectChain = (e) => {
    formik.current.setFieldValue("chain", e.value);
    return true;
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validateAddress = (value) => {
    if (!value) return true;
    try {
      return utils.isAddress(value);
    } catch (e) {
      return false;
    }
  };

  const validateABI = (value) => {
    if (!value) return true;
    try {
      JSON.parse(value);

      return true;
    } catch (e) {
      return false;
    }
  };

  const validationSchema = () =>
    Yup.object().shape({
      address: Yup.string()
        .required(t("yup:required", { name: t("address") }))
        .test({
          name: "address",
          exclusive: false,
          message: t("yup:format", { name: t("address") }),
          test: validateAddress,
        }),
      name: Yup.string()
        .min(3, t("yup:min", { name: t("name"), char: 3 }))
        .required(t("yup:required", { name: t("name") })),
      abi: Yup.string()
        .required(t("yup:required", { name: t("ABI") }))
        .test({
          name: "abi",
          exclusive: false,
          message: t("yup:format", { name: t("ABI") }),
          test: validateABI,
        }),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  return (
    <Card name="applications" sx={{ width: "100%" }}>
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <SuiBox component="form" onSubmit={handleSubmit} noValidate name="addForm">
            <Grid container spacing={3} ml={1} mt={1} mb={3}>
              <Grid item xs={12} sm={4}>
                <SuiFormField
                  component={SuiSelect}
                  options={chainList}
                  label={t("chain")}
                  name="chain"
                  placeholder={t("developers:app-contract-add-select-chain")}
                  error={!!touched.chain && !!errors.chain}
                  helperText={errors.chain}
                  onChange={handleSelectChain}
                  disabled={submit}
                  defaultValue={initialValues.chain}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SuiFormField
                  label={t("address")}
                  name="address"
                  placeholder={t("address")}
                  error={!!touched.address && !!errors.address}
                  helperText={errors.address}
                  onChange={handleChange}
                  value={values.address}
                  disabled={submit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SuiFormField
                  label={t("name")}
                  name="name"
                  placeholder={t("name")}
                  error={!!touched.name && !!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                  value={values.name}
                  disabled={submit}
                />
              </Grid>

              <Grid item xs={11}>
                <SuiFormField
                  label={t("ABI")}
                  multiline
                  rows={10}
                  name="abi"
                  placeholder={t("ABI")}
                  error={!!touched.abi && !!errors.abi}
                  helperText={errors.abi}
                  onChange={handleChange}
                  value={values.abi}
                  disabled={submit}
                />
              </Grid>
              {error && (
                <Grid item xs={11}>
                  <SuiBox>
                    <SuiAlert alert={error} color="primary">
                      <Trans>{`errors:${error.code}`}</Trans>
                    </SuiAlert>
                  </SuiBox>
                </Grid>
              )}
              <Grid item xs={12} sm={2}>
                <SuiFormField
                  label="&nbsp;"
                  name="submit"
                  disabled={submit}
                  component={SuiButton}
                  onClick={() => {
                    formik.current.submitForm();
                  }}
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  <Trans>add</Trans>
                </SuiFormField>
              </Grid>
            </Grid>
          </SuiBox>
        )}
      </Formik>
    </Card>
  );
};

ContractAddForm.propTypes = {
  uid: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ContractAddForm);
