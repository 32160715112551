import React from "react";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";

import SuiAlert from "components/sui/SuiAlert";

const Error = ({ error, t, ...attrs }) => {
  if (error && error.code) {
    return (
      <SuiAlert {...attrs}>
        <Trans>{t(`errors:${error.code}`, { data: error.data })}</Trans>
      </SuiAlert>
    );
  }

  return null;
};

// Setting default values for the props of Alert
Error.defaultProps = {
  error: undefined,
};

// Typechecking props for the Alert
Error.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.number,
    data: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Error);
