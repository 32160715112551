/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
// Soft UI Dashboard PRO React helper functions
const { inputColors } = colors;
const { borderWidth, borderRadius } = borders;
const { inputBoxShadow } = boxShadows;

export default {
  styleOverrides: {
    elevation10: {
      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}!important`,
      borderRadius: `${borderRadius.md} !important`,
    },
    elevation11: {
      border: `${borderWidth[1]} solid ${inputColors.borderColor.focus}!important`,
      boxShadow: `${inputBoxShadow.focus}!important`,
      borderRadius: `${borderRadius.md} !important`,
    },
    elevation12: {
      border: `${borderWidth[1]} solid ${inputColors.error}!important`,
      borderRadius: `${borderRadius.md} !important`,
    },
    elevation13: {
      border: `${borderWidth[1]} solid ${inputColors.error}!important`,
      boxShadow: `${inputBoxShadow.error}!important`,
      borderRadius: `${borderRadius.md} !important`,
    },
  },
};
