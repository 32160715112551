import styled from "styled-components";
import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";

const AuthenticatorsRoot = styled.div`
  margin: ${pxToRem(30)} 0;
  .title-back-link {
    font-size: ${pxToRem(16)} !important;
    line-height: ${pxToRem(40)} !important;
    color: #92929d !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600 !important;
  }
  .crypto-switch-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .close-btn-block {
    position: absolute;
    top: 0;
    right: 0;
  }
  .show-hidden-wallet-block {
    .badge__light {
      > span {
        height: 16px;
        min-width: 16px;
        font-weight: 400;
        font-size: 10px;
        padding: 2px 4px;
        color: #000;
      }
    }
  }
  .searchinput {
    width: 22px;
    height: 22px;
    overflow: visible;
  }
  .block-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    margin-bottom: 30px;
  }
  .list-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 36px;
    color: #a1a1a1;
    font-weight: 600;
  }
  .sort-by-dropdown {
    letter-spacing: 0;
    span {
      color: #444;
      font-weight: 600;
      margin-left: 4px;
    }
  }
  .link-blue {
    font-size: 14px;
    font-weight: 700 !important;
    color: rgb(23, 193, 232) !important;
    letter-spacing: -0.04em;
  }
  .link-show-more {
    display: flex;
    justify-content: flex-start;
    padding: 16px 0 16px 28px;
    .icon-arrow {
      font-size: 24px !important;
      margin-left: -4px;
      margin-right: 30px;
    }
  }
  .export-link {
    font-size: 14px;
    display: flex;
    font-weight: 700 !important;
    color: rgb(23, 193, 232) !important;
    letter-spacing: -0.04em;
    &:hover {
      text-decoration: underline;
    }
    .csv-icon {
      margin-left: 10px;
      width: 18px;
      height: auto;
      border-radius: 0;
    }
  }
  .crypto_wrapper {
    display: flex;
    flex-wrap: wrap;
    .crypto__list-items {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 10px;
      margin: 0 0 20px 0;
      @media (min-width: 992px) {
        width: 50%;
      }
      @media (min-width: 1500px) {
        width: 33.33%;
      }
      .crypto__list-items_wrap {
        width: 100%;
        padding: 15px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
        border-radius: 6px;
      }
      .crypto__list-items__rightData {
        display: flex;
        flex: 1;
        width: 100%;
        .crypto__list-items__rightData_top {
          border-bottom: 1px dashed #ececec;
          width: 100%;
          padding: 0 0 15px 0;
          margin-bottom: 15px;
        }
        .crypto-item-title {
          color: ${colors.black.main};
          font-size: ${pxToRem(15)};
          font-weight: 700;
          line-height: ${pxToRem(24)};
          .badge_top {
            margin-top: -10px !important;
            margin-left: 7px !important;
            > span {
              font-weight: 900;
              font-size: ${pxToRem(10)};
              line-height: ${pxToRem(16)};
              color: rgb(103, 116, 142);
              border-radius: 0;
              display: inline-block;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              padding: 0;
            }
          }
        }
        .crypto-sub-title-block {
          padding-top: 5px;
          .crypto-sub-title {
            font-size: ${pxToRem(12)};
            color: #455a64;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .crypto-qty {
          font-size: ${pxToRem(12)};
          line-height: ${pxToRem(20)};
          color: #a1a1a1;
        }
      }
      .crypto-btn-block {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-left: -22px;
        @media (min-width: 768px) {
          flex-wrap: nowrap;
          margin-left: 0;
        }
        .crypto-btn {
          ~ .crypto-btn {
            margin-left: 10px;
          }
        }
      }
      .crypto-btn {
        border: 1px solid #e4e4e4;
        background-color: ${colors.white.main};
        border-radius: 3px;
        padding: 6px 10px;
        font-size: 13px;
        line-height: 20px;
        color: #656565;
        min-width: 100px;
        min-height: unset;
        text-transform: none;
        font-weight: 500;
        letter-spacing: 0;
        white-space: nowrap;
        transform: scale(1, 1);
        opacity: 1;

        &:hover,
        &:focus {
          opacity: 1;
          transform: scale(1, 1);
          border: 1px solid #e4e4e4;
          background-color: #f1f1f1;
        }
        &:focus {
          box-shadow: none;
        }
        &:active {
          background-color: #f1f1f1;
        }
      }
      &:last-child {
        .crypto__list-items__rightData {
          border-bottom: 0;
        }
      }
      &.hide-crypto-item {
        .crypto-avatar-block {
          .crypto-avatar-img {
            .MuiAvatar-img {
              -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
            }
          }
        }
        .crypto__list-items__rightData {
          .crypto-item-title,
          .crypto-sub-title {
            color: rgb(103, 116, 142);
          }
          .crypto-sub-title-block {
            .MuiAvatar-img {
              -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
            }
          }
        }
      }
    }
  }
  .badge__light {
    .MuiBadge-badge {
      background: #f1f1f1;
      color: #555;
      border-radius: 100px;
      font-size: 14px;
      padding: 5px 8px;
    }
  }
  .input__control {
    .input__control_inn {
      height: 38px !important;
      border-color: #e2e2ea !important;
      &:focus {
        border-color: rgba(113, 144, 235, 0.5) !important;
      }
    }
    .MuiInputBase-input {
      min-width: 100%;
      font-weight: 600;
    }
  }
`;

export default AuthenticatorsRoot;
