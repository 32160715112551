import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// Soft UI Dashboard PRO React components
import SuiSpinner from "components/sui/SuiSpinner";
import SuiAlert from "components/sui/SuiAlert";
import SuiBox from "components/sui/SuiBox";

// Images
import listEmptyIcon from "assets/images/icons/list-empty.png";

import * as app from "actions/app";

import Row from "layouts/asset/evm/tabs/History/Row";

function History({ name, asset }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (loading) {
      dispatch(
        app.history(name, null, (_error, result) => {
          if (_error) {
            setError(_error);
          } else {
            setData(result);
          }
          setLoading(false);
        })
      );
    }
  }, [loading]);

  const handleOpenDetails = (id) => {
    if (open === id) setOpen(false);
    else setOpen(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <SuiSpinner />;
  if (error) {
    return (
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <SuiBox component="img" src={listEmptyIcon} alt="Rocketship" mb={3} width="200px" />
        <SuiBox>
          <SuiAlert alert={error} color="primary">
            <Trans>{`error-${error.code}`}</Trans>
          </SuiAlert>
        </SuiBox>
      </Grid>
    );
  }

  if (data.length === 0) {
    return (
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <SuiBox component="img" src={listEmptyIcon} alt="Rocketship" mb={3} width="200px" />
        <SuiBox>
          <Trans>crypto:empty-history</Trans>
        </SuiBox>
      </Grid>
    );
  }
  return (
    <TableContainer component={Box}>
      <Table aria-label="collapsible table" component="div">
        <TableHead component="div">
          <TableRow>
            <TableCell align="center">
              <Trans>crypto:history-col1</Trans>
            </TableCell>
            <TableCell>
              <Trans>crypto:history-col2</Trans>
            </TableCell>
            <TableCell align="left">
              <Trans>crypto:history-col3</Trans>
            </TableCell>
            <TableCell align="right">
              <Trans>crypto:history-col4</Trans>
            </TableCell>
            <TableCell align="center">
              <Trans>crypto:history-col5</Trans>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <Row
              key={row.id}
              row={row}
              asset={asset}
              open={row.id === open}
              handleOpen={handleOpenDetails}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          select: {
            width: "50px",
          },
        }}
      />
    </TableContainer>
  );
}

History.propTypes = {
  name: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    chain: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default withTranslation()(History);
