/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { NavLink } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

// Frame layout components
import CoverLayout from "layouts/pages/components/CoverLayout";

function Privacy() {
  return (
    <CoverLayout
      title="Legal information"
      description="This page contains links to important legal information for myDAPPwallet."
    >
      <Card>
        <SuiBox p={3} mb={1} textAlign="left">
          <SuiBox mt={1}>
            <SuiTypography
              component={NavLink}
              to="/legal/privacy"
              variant="h4"
              fontWeight="bold"
              sx={{ coursor: "pointer" }}
            >
              Privacy policy
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="inherit" fontWeight="medium">
              This describes myDAPPwallet policies and procedures regarding the collection, use and
              disclosure of information received by DBRIDGE SP Z.O.O.
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={5}>
            <SuiTypography
              component={NavLink}
              to="/legal/terms"
              variant="h4"
              fontWeight="bold"
              sx={{ coursor: "pointer" }}
            >
              Terms & Condition
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="inherit" fontWeight="medium">
              This describes myDAPPwallet policies and procedures regarding the collection, use and
              disclosure of information received by DBRIDGE SP Z.O.O.
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </Card>
    </CoverLayout>
  );
}

Privacy.propTypes = {};

export default Privacy;
