/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React layout components
import DefaultNavbar from "components/layout/Navbars/DefaultNavbar";
import Footer from "components/layout/Footer";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import Info from "components/layout/Info";
// Soft UI Dashboard PRO React context
import { useSoftUIController, setLayout } from "context";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

// Actions
import * as app from "actions/app";

function DefaultLayout({ title, names, children }) {
  const [controller, dispatch] = useSoftUIController();
  const dispatcher = useDispatch();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const appStore = useSelector((state) => state.appStore);
  useEffect(() => {
    setLayout(dispatch, "default");
  }, [pathname]);

  const infoClose = () => {
    dispatcher(app.infoClose());
  };

  return (
    <SuiBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(324),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <DefaultNavbar routes={pageRoutes} title={title} names={names} />
      <Info info={appStore.info} color="primary" dismissible onClick={infoClose} />
      {children}
      <Footer />
    </SuiBox>
  );
}

DefaultLayout.defaultProps = {
  title: null,
  names: [],
};

// Typechecking props for the DashboardLayout
DefaultLayout.propTypes = {
  title: PropTypes.string,
  names: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
