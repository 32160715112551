/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// react-router-dom components
import { Redirect } from "react-router-dom";

import { PropTypes } from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTabPanel from "components/sui/SuiTabPanel";
import SuiSpinner from "components/sui/SuiSpinner";
// @mui material components
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Tabs
import History from "layouts/asset/erc20/tabs/History";
import Receive from "layouts/asset/erc20/tabs/Receive";
import Send from "layouts/asset/erc20/tabs/Send";
import Books from "layouts/asset/common/tabs/Books";
import BuySell from "layouts/asset/common/tabs/BuySell";
// Actions
import * as app from "actions/app";

function a11yProps(index) {
  return {
    id: `erc20-tab-${index}`,
    "aria-controls": `erc20-tabpanel-${index}`,
  };
}

function ERC20({ name, asset, t }) {
  const dispatch = useDispatch();
  const [panel, setPanel] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    dispatch(
      app.addressBook("EVM", (__error) => {
        if (__error) {
          setLoading(false);
          setError(__error);
        } else {
          dispatch(
            app.gasPrice(name, (___error) => {
              if (___error) {
                setLoading(false);
                setError(___error);
              } else {
                setLoading(false);
              }
            })
          );
        }
      })
    );
  }, []);

  if (loading)
    return (
      <Card sx={{ width: "100%" }}>
        <SuiSpinner />
      </Card>
    );
  if (error) return <Redirect to={`/error/${error.code}`} />;

  return (
    <Card name="erc20" sx={{ width: "100%" }}>
      <SuiBox sx={{ width: "100%" }}>
        <SuiBox sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={panel}
            onChange={(_event, newPanel) => {
              setPanel(newPanel);
            }}
            aria-label="erc20 tabs"
          >
            <Tab label={t("crypto:tab-history")} {...a11yProps(0)} />
            <Tab label={t("crypto:tab-receive")} {...a11yProps(1)} />
            <Tab label={t("crypto:tab-send")} {...a11yProps(2)} />
            <Tab label={t("crypto:tab-books")} {...a11yProps(3)} />
            {asset.brokers && <Tab label={t("crypto:tab-buy")} {...a11yProps(4)} />}
          </Tabs>
        </SuiBox>
        <SuiTabPanel value={panel} index={0}>
          <History name={name} asset={asset} />
        </SuiTabPanel>
        <SuiTabPanel value={panel} index={1}>
          <Receive name={name} asset={asset} />
        </SuiTabPanel>
        <SuiTabPanel value={panel} index={2}>
          <Send name={name} asset={asset} />
        </SuiTabPanel>
        <SuiTabPanel value={panel} index={3}>
          <Books type="EVM" />
        </SuiTabPanel>
        <SuiTabPanel value={panel} index={4}>
          <BuySell name={name} defaultAsset={asset} />
        </SuiTabPanel>
      </SuiBox>
    </Card>
  );
}
ERC20.propTypes = {
  name: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    chain: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    brokers: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ERC20);
