/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable class-methods-use-this */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Grid from "@mui/material/Grid";

import Link from "@mui/material/Link";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";
import SuiCodeInput from "components/sui/SuiCodeInput";
import Error from "components/layout/Error";

// Constants
import Constants from "constants/Constants";

import { Formik } from "formik";

import * as Yup from "yup";

import * as CryptoUtil from "utils/CryptoUtil";

// Actions
import * as _web3 from "actions/web3";

const { TRANSACTION_TYPES } = Constants;

const utils = require("web3-utils");

const initialValues = {
  code: "",
};

function Stage2({ name, currency, t }) {
  const dispatch = useDispatch();
  const crypto = useSelector((state) => state.appStore.assets[name]);
  const exchangeRates = useSelector((state) => state.appStore.exchangeRates.exchanges[name].price);
  const exchangeRate = exchangeRates[currency];
  const { type, from, to, value, decimals, symbol, gasPrice, gasLimit } = useSelector(
    (state) => state.web3Store.transactions[name]
  );

  const [submitCounter, setSubmitCounter] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [reject, setReject] = useState(false);
  const [more, setMore] = useState(false);
  const formik = useRef(null);
  const codeInput = useRef(null);

  const handleChangeCode = (e) => {
    formik.current.setFieldValue("code", e);
  };

  const handleBack = () => {
    dispatch(_web3.clearTransaction(name));
  };

  const validationSchema = () =>
    Yup.object().shape({
      code: Yup.string()
        .required(t("yup-required", { name: t("code") }))
        .min(6),
    });

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  const onSubmited = (error) => {
    if (error) {
      switch (error.code) {
        case 4300:
          formik.current.setFieldValue("code", initialValues.code, false);
          codeInput.current.reset();
          if (submitCounter > 1) {
            setReject(true);
          }
        // eslint-disable-next-line no-fallthrough
        default:
      }
      formik.current.errors.code = t(`errors:${error.code}`);
    }
    setSubmit(false);
  };

  const onSubmit = (values) => {
    setSubmit(true);
    setSubmitCounter(submitCounter + 1);
    dispatch(_web3.confirmTransaction(name, values.code, onSubmited));
  };

  let amount = 0;
  let equivalent = null;
  amount = (
    <>
      {CryptoUtil.toAmountAsString(value, decimals)} {symbol}
    </>
  );
  if (type === TRANSACTION_TYPES.SEND && !crypto.test) {
    equivalent = CryptoUtil.toAmountAsString(value, decimals, 2, exchangeRate);
    equivalent = value > 0 && equivalent < 0.01 ? "< 0.0.1" : equivalent;
    equivalent = (
      <>
        {equivalent} {currency}
      </>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid spacing={2} item xs={12}>
        <Box sx={{ margin: 1 }}>
          <SuiTypography component="p" variant="h5" align="center" width="100%">
            Are you sure you want to
          </SuiTypography>
          <SuiTypography component="p" variant="h4" align="center" width="100%" color="info">
            <Trans>send</Trans> {amount}?
          </SuiTypography>
          <TableContainer component={Box}>
            <Table aria-label="collapsible table">
              <TableBody>
                {" "}
                <TableRow>
                  <TableCell>
                    <h4>
                      <Trans>crypto:amount</Trans>
                    </h4>
                    {equivalent && <Trans>crypto:equivalent</Trans>}
                  </TableCell>
                  <TableCell>
                    <h4>{amount}</h4>
                    {equivalent && equivalent}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Trans>crypto:from-wallet</Trans>
                  </TableCell>
                  <TableCell>
                    {from} <SuiIconCopyToClipboard text={from} name={t("from")} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Trans>crypto:to-wallet</Trans>
                  </TableCell>
                  <TableCell>
                    {to} <SuiIconCopyToClipboard text={to} name={t("to")} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <h4>
                      <Trans>crypto:network-fee</Trans>
                    </h4>
                    <Trans>crypto:equivalent</Trans>
                  </TableCell>
                  <TableCell>
                    <h4>
                      {utils.fromWei((gasPrice * gasLimit).toString())} {crypto.symbol}
                    </h4>
                    {utils.fromWei((gasPrice * gasLimit).toString(), "Gwei")} Gwei
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Collapse in={more} timeout="auto" unmountOnExit>
              <>
                {" "}
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: "30%" }}>
                        <Trans>crypto:gasLimit</Trans>
                      </TableCell>
                      <TableCell>{gasLimit}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Trans>crypto:gasPrice</Trans>
                      </TableCell>
                      <TableCell>
                        {utils.fromWei(gasPrice.toString())} {crypto.symbol} (
                        {utils.fromWei(gasPrice.toString(), "Gwei")} Gwei)
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            </Collapse>
            <SuiBox pt="0.75rem" pl="1rem" pb="0.2rem">
              <Link
                sx={{ focusVisible: { outline: "none" } }}
                href="#"
                onClick={() => {
                  setMore(!more);
                }}
              >
                <Trans>{more ? "see-less" : "see-more"}</Trans>{" "}
                <IconButton aria-label="expand row" size="small" onClick={() => setMore(!more)}>
                  {more ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Link>
            </SuiBox>
          </TableContainer>
          <Divider variant="middle" />
          <Formik
            initialValues={initialValues}
            validate={validate(validationSchema)}
            onSubmit={onSubmit}
            innerRef={formik}
          >
            {({ values, errors, touched, handleSubmit }) => (
              <SuiBox component="form" onSubmit={handleSubmit} noValidate name="confirmForm">
                {reject ? (
                  <Error error={{ code: 4400 }} color="primary" />
                ) : (
                  <>
                    <Grid container spacing={2} wrap="nowrap">
                      <Grid item>
                        <PhonelinkLockIcon fontSize="large" />
                      </Grid>
                      <Grid item>
                        <SuiTypography variant="caption" component="div">
                          Enter the secret code from your Authenticator to confirm the transaction.{" "}
                          <br />
                          Please open the Google Authenticator application on your mobile phone.
                        </SuiTypography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} wrap="nowrap">
                      <Grid item ml={7} xs={12}>
                        <SuiCodeInput
                          name="code"
                          type="number"
                          ref={codeInput}
                          fields={6}
                          value={values.code}
                          onChange={handleChangeCode}
                          isValid={!(touched.code && errors.code)}
                          filterChars={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
                          filterCharsIsWhitelist
                          pattern="[0-9]"
                          helperText={errors.code}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Divider variant="middle" />
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} md={7}>
                    <SuiTypography component="label" variant="caption" textTransform="capitalize">
                      <Trans values={{ one: 2, two: 3 }}>step-of</Trans>
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <Link
                      underline="hover"
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      <Trans>back</Trans>
                    </Link>
                  </Grid>

                  <Grid item xs={4} md={3}>
                    {reject ? (
                      <SuiButton
                        name="submit"
                        disabled={submit}
                        component={SuiButton}
                        onClick={() => {
                          handleBack();
                        }}
                        variant="gradient"
                        color="info"
                        fullWidth
                      >
                        <Trans>cancel</Trans>
                      </SuiButton>
                    ) : (
                      <SuiButton
                        name="submit"
                        disabled={submit}
                        component={SuiButton}
                        onClick={() => {
                          formik.current.submitForm();
                        }}
                        variant="gradient"
                        color="info"
                        fullWidth
                      >
                        <Trans>confirm</Trans>
                      </SuiButton>
                    )}
                  </Grid>
                </Grid>
              </SuiBox>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
}

Stage2.propTypes = {
  name: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Stage2);
