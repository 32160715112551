import { useState, useRef } from "react";
import { PropTypes } from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiQRScanner from "components/sui/SuiQRScanner";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import Tooltip from "@mui/material/Tooltip";

import QrCodeIcon from "@mui/icons-material/QrCode";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";

import ToTextField from "layouts/asset/evm/tabs/Send/components/ToTextField";

function ToField({ addressBook, filterOptions, value, touched, error, onChange, onBlur, t }) {
  const toRef = useRef();
  const [qrscanShow, handleScanClick] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const handleScan = (data) => {
    let address = data;
    if (data) {
      if (data.startsWith("ethereum:")) address = data.substring(9);
      if (address[address.length - 2] === "@") address = address.substring(0, address.length - 2);
      onChange(address);
      toRef.current.focus();
    }
    handleScanClick(false);
  };

  let elevation;
  if (touched && error) {
    elevation = highlight ? 13 : 12;
  } else {
    elevation = highlight ? 11 : 10;
  }
  return (
    <>
      <Paper
        elevation={elevation}
        sx={{
          display: "flex",
          borderRadius: borders.borderRadius.lg,
          boxShadow: 0,
          position: "relative",
          "&:hover": { outline: 0 },
        }}
      >
        <Autocomplete
          disablePortal
          id="to"
          options={addressBook}
          getOptionLabel={(option) => option.address}
          filterOptions={filterOptions}
          renderOption={(props, option) => <SuiBox {...props}>{option.name}</SuiBox>}
          sx={{ width: "100%" }}
          onHighlightChange={() => {
            setHighlight(true);
          }}
          disableClearable
          freeSolo
          onInputChange={(_e, _value) => {
            onChange(_value);
          }}
          renderInput={(params) => {
            const inputParams = {
              ...params,
              inputProps: { ...params.inputProps, value },
            };
            return (
              <ToTextField
                inputRef={toRef}
                name="to"
                placeholder={t("to")}
                sx={{ border: 0, notchedOutline: { border: 0 } }}
                {...inputParams}
                onBlur={(e) => {
                  setHighlight(false);
                  onBlur(e);
                }}
                onFocus={() => {
                  setHighlight(true);
                }}
              />
            );
          }}
        />
        <Divider sx={{ height: 48, m: 0.5 }} orientation="vertical" />
        <Tooltip title={t("crypto:to-tooltip")} placement="bottom">
          <IconButton
            sx={{ p: "10px" }}
            aria-label="directions"
            onClick={() => {
              if (!qrscanShow) {
                toRef.current.focus();
                setHighlight(true);
                handleScanClick(true);
              }
            }}
          >
            <QrCodeIcon />
          </IconButton>
        </Tooltip>
        {qrscanShow && (
          <SuiQRScanner
            handleScan={handleScan}
            handleScanClose={() => {
              handleScanClick(false);
              setHighlight(false);
            }}
          />
        )}
      </Paper>
      <FormHelperText error id="error">
        {touched ? error : ""}
      </FormHelperText>
    </>
  );
}
ToField.defaultProps = {
  touched: false,
  error: false,
};

ToField.propTypes = {
  addressBook: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, address: PropTypes.string.isRequired })
  ).isRequired,
  filterOptions: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ToField);
