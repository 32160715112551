import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";

import Row from "layouts/developers/application/components/Row";

import { Trans, withTranslation } from "react-i18next";

// Images
import listEmptyIcon from "assets/images/icons/list-empty.png";

function ContractList({ uid, tableData, handleEditContract, handleDeleteContract }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Card name="applications" sx={{ width: "100%" }}>
      <TableContainer component={Box}>
        <Table aria-label="collapsible table" component="div">
          <TableHead component="div">
            <TableRow>
              <TableCell align="center">
                <Trans>developers:app-contracts-col1</Trans>
              </TableCell>
              <TableCell>
                <Trans>developers:app-contracts-col2</Trans>
              </TableCell>
              <TableCell align="left">
                <Trans>developers:app-contracts-col3</Trans>
              </TableCell>
              <TableCell align="center">
                <Trans>developers:app-contracts-col4</Trans>
              </TableCell>
              <TableCell align="center">
                <Trans>developers:app-contracts-col5</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody component="div">
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.address}
                  row={row}
                  index={index}
                  uid={uid}
                  handleEditContract={handleEditContract}
                  handleDeleteContract={handleDeleteContract}
                />
              ))}
          </TableBody>
        </Table>
        {tableData.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              select: {
                width: "50px",
              },
            }}
          />
        )}
      </TableContainer>
      {tableData.length === 0 && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          mt={2}
          mb={2}
        >
          <SuiBox component="img" src={listEmptyIcon} alt="Rocketship" mb={3} width="200px" />
          <SuiBox>
            <Trans>developers:app-contracts-empty</Trans>
          </SuiBox>
        </Grid>
      )}
    </Card>
  );
}

ContractList.propTypes = {
  uid: PropTypes.string.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleEditContract: PropTypes.func.isRequired,
  handleDeleteContract: PropTypes.func.isRequired,
};

export default withTranslation()(ContractList);
