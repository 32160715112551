/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSoftUIController } from "context";
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Link from "@mui/material/Link";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";
import SuiTypography from "components/sui/SuiTypography";
// Buy components
import FiatAmountField from "layouts/asset/common/tabs/BuySell/components/FiatAmountField";
import AssetAmountField from "layouts/asset/common/tabs/BuySell/components/AssetAmountField";
// Formik
import * as Yup from "yup";
import { Formik } from "formik";

import { RampInstantSDK } from "@ramp-network/ramp-instant-sdk";

// Utils
import * as CryptoUtil from "utils/CryptoUtil";

import * as app from "actions/app";

// Constants
import Constants from "constants/Constants";
import ActionTypes from "constants/ActionTypes";

const { BYSELL_TRANSACTION_STATUSES, BYSELL_TRANSACTION_TYPES, BROKERS } = Constants;
const RAMP_API_KEY = "e7qday2mkj4z6bmh7amqez3oro2mbv4ve7buy9dw";
const RAMP_API_URL = "https://api.demo.ramp.network/api";
const initialValues = {
  to: "",
  cryptoAmount: 0,
  fiatValue: 0,
};

function BuySell({ name, defaultAsset, t }) {
  const dispatch = useDispatch();
  const assets = useSelector((state) => state.appStore.assets);
  const transaction = useSelector((state) => state.appStore.buySellTransaction);
  const [currency, setCurrency] = useState(
    useSelector((state) => state.appStore.settings.defaultCurrency)
  );
  const [submit, setSubmit] = useState(false);
  const [asset, setAsset] = useState(defaultAsset);
  const [assetName, setAssetName] = useState(name);
  const [networkFee, setNetworkFee] = useState(false);
  const [brokerFee, setBrokerFee] = useState(false);
  const [minPurchaseAmount, setMinPurchaseAmount] = useState(1);
  const [maxPurchaseAmount, setMaxPurchaseAmount] = useState(1);
  const [minPurchaseCryptoAmount, setMinPurchaseCryptoAmount] = useState(0);
  const [maxPurchaseCryptoAmount, setMaxPurchaseCryptoAmount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [brokerId, setBrokerId] = useState(false);
  const [secret, setSecret] = useState(false);

  const exchangeRates = useSelector((state) => state.appStore.exchangeRates.exchanges);
  // eslint-disable-next-line no-unused-vars
  const broker = Object.keys(asset.brokers)[0];
  // const exchangeRate = exchangeRates[currency];
  const formik = useRef(null);
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    formik.current.validateForm();
  }, [minPurchaseAmount, maxPurchaseAmount, minPurchaseCryptoAmount, maxPurchaseCryptoAmount]);

  const handleFiatAmountChange = (amount) => {
    console.log("handleFiatAmountChange");
    if (!amount) {
      setNetworkFee(0);
      setBrokerFee(0);
      formik.current.setFieldValue("fiatValue", undefined);
      formik.current.setFieldValue("cryptoAmount", 0);
      return;
    }
    if (amount < 0) {
      formik.current.setFieldValue("fiatValue", 0);
      formik.current.setFieldValue("cryptoAmount", 0);
      setNetworkFee(0);
      setBrokerFee(0);
      return;
    }
    formik.current.setFieldValue("fiatValue", amount);
    switch (broker) {
      case BROKERS.RAMP:
        // eslint-disable-next-line no-case-declarations
        const body = JSON.stringify({
          mode: "ONRAMP",
          hostApiKey: RAMP_API_KEY,
          selectedCountryCode: "pl",
          in: { asset: { symbol: currency }, amount: { accounting: amount.toString() } },
          out: { asset: { symbol: asset.symbol, chain: asset.chain } },
          userTransferMethods: [{ type: "SEPA" }],
          deliveryMethods: null,
          legalRestrictions: [],
        });
        fetch(`${RAMP_API_URL}/offer/quote`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body,
        })
          .then((response) => response.json())
          .then((jsonData) => {
            if (jsonData.code) {
              switch (jsonData.code) {
                case "AMOUNT_TOO_LOW":
                  setMinPurchaseAmount(jsonData.limit);
                  formik.current.setFieldValue("cryptoAmount", "0");
                  break;
                case "AMOUNT_TOO_HIGH":
                  setMaxPurchaseAmount(jsonData.limit);
                  formik.current.setFieldValue(
                    "cryptoAmount",
                    jsonData.quote.out.amount.accounting
                  );
                  setNetworkFee(0);
                  setBrokerFee(0);
                  break;
                default:
              }
            } else {
              formik.current.setFieldValue("cryptoAmount", jsonData.out.amount.accounting);
              setNetworkFee(jsonData.fees.detailed[1].initialValue);
              setBrokerFee(jsonData.fees.detailed[0].initialValue);
              setMinPurchaseAmount(0);
              setMaxPurchaseAmount(0);
              setMinPurchaseCryptoAmount(0);
              setMaxPurchaseCryptoAmount(0);
            }
          })
          .catch((_error) => {
            // handle your errors here
            console.error(_error);
          });
        break;
      default:
        formik.current.setFieldValue(
          "cryptoAmount",
          (amount / exchangeRates[assetName].price[currency]).toFixed(asset.decimals)
        );
        setMinPurchaseAmount(exchangeRates[assetName].price[currency].toFixed(2));
        setMaxPurchaseAmount((exchangeRates[assetName].price[currency] * 10).toFixed(2));
        setMinPurchaseCryptoAmount(1);
        setMaxPurchaseCryptoAmount(10);
    }
  };

  const handleCurrencyChange = (newCurrency) => {
    console.log("handleCurrencyChange");
    setCurrency(newCurrency);
    switch (broker) {
      case BROKERS.RAMP:
        // eslint-disable-next-line no-case-declarations
        const body = JSON.stringify({
          mode: "ONRAMP",
          hostApiKey: RAMP_API_KEY,
          selectedCountryCode: "pl",
          in: {
            asset: { symbol: newCurrency },
            amount: { accounting: formik.current.values.fiatValue.toString() },
          },
          out: { asset: { symbol: asset.symbol, chain: asset.chain } },
          userTransferMethods: [{ type: "SEPA" }],
          deliveryMethods: null,
          legalRestrictions: [],
        });
        fetch(`${RAMP_API_URL}/offer/quote`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body,
        })
          .then((response) => response.json())
          .then((jsonData) => {
            if (jsonData.code) {
              switch (jsonData.code) {
                case "AMOUNT_TOO_LOW":
                  setMinPurchaseAmount(jsonData.limit);
                  break;
                case "AMOUNT_TOO_HIGH":
                  setMaxPurchaseAmount(jsonData.limit);
                  formik.current.setFieldValue(
                    "cryptoAmount",
                    jsonData.quote.out.amount.accounting
                  );
                  setNetworkFee(0);
                  setBrokerFee(0);
                  break;
                default:
              }
            } else {
              formik.current.setFieldValue("cryptoAmount", jsonData.out.amount.accounting);
              setNetworkFee(jsonData.fees.detailed[1].initialValue);
              setBrokerFee(jsonData.fees.detailed[0].initialValue);
              setMinPurchaseAmount(0);
              setMaxPurchaseAmount(0);
              setMinPurchaseCryptoAmount(0);
              setMaxPurchaseCryptoAmount(0);
            }
          })
          .catch((_error) => {
            // handle your errors here
            console.error(_error);
          });
        break;

      default:
        formik.current.setFieldValue(
          "cryptoAmount",
          (formik.current.values.fiatValue / exchangeRates[assetName].price[newCurrency]).toFixed(
            asset.decimals
          )
        );
        setMinPurchaseAmount(exchangeRates[assetName].price[newCurrency].toFixed(2));
        setMaxPurchaseAmount((exchangeRates[assetName].price[newCurrency] * 10).toFixed(2));
        setMinPurchaseCryptoAmount(1);
        setMaxPurchaseCryptoAmount(10);
    }
  };

  const handleAssetAmountChange = (amount) => {
    if (!amount) {
      setNetworkFee(0);
      setBrokerFee(0);
      formik.current.setFieldValue("fiatValue", 0);
      formik.current.setFieldValue("cryptoAmount", undefined);
      return;
    }
    if (amount < 0) {
      formik.current.setFieldValue("fiatValue", 0);
      formik.current.setFieldValue("cryptoAmount", 0);
      setNetworkFee(0);
      setBrokerFee(0);
      return;
    }
    console.log("handleAssetAmountChange");
    const value = CryptoUtil.toValueAsString(amount, asset.decimals);
    formik.current.setFieldValue("cryptoAmount", amount);

    switch (broker) {
      case BROKERS.RAMP:
        // eslint-disable-next-line no-case-declarations
        const body = JSON.stringify({
          mode: "ONRAMP",
          hostApiKey: RAMP_API_KEY,
          selectedCountryCode: "pl",
          in: {
            asset: { symbol: currency },
          },
          out: {
            asset: { symbol: asset.symbol, chain: asset.chain },
            amount: { accounting: amount.toString() },
          },
          userTransferMethods: [{ type: "SEPA" }],
          deliveryMethods: null,
          legalRestrictions: [],
        });
        fetch(`${RAMP_API_URL}/offer/quote`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body,
        })
          .then((response) => response.json())
          .then((jsonData) => {
            if (jsonData.code) {
              switch (jsonData.code) {
                case "AMOUNT_TOO_LOW":
                  setMinPurchaseCryptoAmount(jsonData.limit);
                  formik.current.setFieldValue("fiatValue", "0");
                  break;
                case "AMOUNT_TOO_HIGH":
                  setMaxPurchaseCryptoAmount(jsonData.limit);
                  formik.current.setFieldValue("fiatValue", jsonData.quote.in.amount.accounting);
                  setNetworkFee(0);
                  setBrokerFee(0);
                  break;
                default:
              }
            } else {
              formik.current.setFieldValue("fiatValue", jsonData.in.amount.accounting);
              setNetworkFee(jsonData.fees.detailed[1].initialValue);
              setBrokerFee(jsonData.fees.detailed[0].initialValue);
              setMinPurchaseAmount(0);
              setMaxPurchaseAmount(0);
              setMinPurchaseCryptoAmount(0);
              setMaxPurchaseCryptoAmount(0);
            }
          })
          .catch((_error) => {
            // handle your errors here
            console.error(_error);
          });
        break;

      default:
        formik.current.setFieldValue(
          "fiatValue",
          CryptoUtil.toAmountAsString(
            value,
            asset.decimals,
            2,
            exchangeRates[assetName].price[currency]
          )
        );
        setMinPurchaseAmount(exchangeRates[assetName].price[currency].toFixed(2));
        setMaxPurchaseAmount((exchangeRates[assetName].price[currency] * 10).toFixed(2));
        setMinPurchaseCryptoAmount(1);
        setMaxPurchaseCryptoAmount(10);
        formik.current.validateForm();
    }
  };

  const handleAssetChange = (newAssetName) => {
    console.log("handleAssetChange");
    if (newAssetName) {
      setAssetName(newAssetName);
      setAsset(assets[newAssetName]);
      switch (Object.keys(assets[newAssetName].brokers)[0]) {
        case BROKERS.RAMP:
          // eslint-disable-next-line no-case-declarations
          const body = JSON.stringify({
            mode: "ONRAMP",
            hostApiKey: RAMP_API_KEY,
            selectedCountryCode: "pl",
            in: {
              asset: { symbol: currency },
            },
            out: {
              asset: { symbol: assets[newAssetName].symbol, chain: assets[newAssetName].chain },
              amount: { accounting: formik.current.values.cryptoAmount.toString() },
            },
            userTransferMethods: [{ type: "SEPA" }],
            deliveryMethods: null,
            legalRestrictions: [],
          });
          fetch(`${RAMP_API_URL}/offer/quote`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body,
          })
            .then((response) => response.json())
            .then((jsonData) => {
              if (jsonData.code) {
                switch (jsonData.code) {
                  case "AMOUNT_TOO_LOW":
                    setMinPurchaseCryptoAmount(jsonData.limit);
                    break;
                  case "AMOUNT_TOO_HIGH":
                    setMaxPurchaseCryptoAmount(jsonData.limit);
                    formik.current.setFieldValue("fiatValue", jsonData.in.amount.accounting);
                    setNetworkFee(0);
                    setBrokerFee(0);
                    break;
                  default:
                }
              } else {
                formik.current.setFieldValue("fiatValue", jsonData.in.amount.accounting);
                setNetworkFee(jsonData.fees.detailed[1].initialValue);
                setBrokerFee(jsonData.fees.detailed[0].initialValue);
                setMinPurchaseAmount(0);
                setMaxPurchaseAmount(0);
                setMinPurchaseCryptoAmount(0);
                setMaxPurchaseCryptoAmount(0);
              }
              formik.current.validateForm();
            })
            .catch((_error) => {
              // handle your errors here
              console.error(_error);
            });
          break;
        default:
          setMinPurchaseAmount(exchangeRates[newAssetName].price[currency].toFixed(2));
          setMaxPurchaseAmount((exchangeRates[newAssetName].price[currency] * 10).toFixed(2));
          setMinPurchaseCryptoAmount(1);
          setMaxPurchaseCryptoAmount(10);
          formik.current.setFieldValue(
            "fiatValue",
            CryptoUtil.toAmountAsString(
              CryptoUtil.toValueAsString(
                formik.current.values.cryptoAmount,
                assets[newAssetName].decimals
              ),
              assets[newAssetName].decimals,
              2,
              exchangeRates[newAssetName].price[currency]
            )
          );
      }
    }
  };
  const eventHandler = (event) => {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(event));
    switch (event.type) {
      case "PURCHASE_CREATED":
        dispatch({
          type: ActionTypes.APP_BUYSELL_TRANSATION,
          payload: {
            transaction: {
              ...event.payload.purchase,
              type: BYSELL_TRANSACTION_TYPES.BUY,
              broker: BROKERS.RAMP,
              status: BYSELL_TRANSACTION_STATUSES[event.payload.purchase.status],
            },
          },
        });
        setBrokerId(event.payload.purchase.id);
        setSecret(event.payload.purchase.purchaseViewToken);
        dispatch(
          app.buySellTransaction(
            BROKERS.RAMP,
            event.payload.purchase.id,
            event.payload.purchase.purchaseViewToken,
            () => {}
          )
        );

        // eslint-disable-next-line no-case-declarations
        const interval = setInterval(() => {
          dispatch(
            app.buySellTransaction(
              BROKERS.RAMP,
              event.payload.purchase.id,
              event.payload.purchase.purchaseViewToken,
              (_error, trans) => {
                if (trans && trans.status >= BYSELL_TRANSACTION_STATUSES.FIAT_RECEIVED) {
                  clearInterval(interval);

                  setSubmit(false);
                }
              }
            )
          );
        }, 1000);

        console.log(event.payload.purchase.id);
        console.log(event.payload.purchase.purchaseViewToken);

        break;
      case "WIDGET_CLOSE_REQUEST_CONFIRMED":
        setSubmit(false);
        break;
      default:
    }
  };

  // eslint-disable-next-line no-unused-vars
  const onSubmited = (_error, result) => {
    if (result) {
      setBrokerId(result);
      dispatch(app.buySellTransaction(BROKERS.SANDBOX, result, null, () => {}));

      const interval = setInterval(() => {
        dispatch(
          app.buySellTransaction(
            BROKERS.SANDBOX,
            result,
            null,

            (__error, trans) => {
              if (trans && trans.status > BYSELL_TRANSACTION_STATUSES.RELEASING) {
                clearInterval(interval);
                dispatch(app.balance(assetName));
                setSubmit(false);
              }
            }
          )
        );
      }, 1000);
    } else {
      setError(_error);
    }
  };
  const onSubmit = (values) => {
    switch (broker) {
      case BROKERS.RAMP:
        new RampInstantSDK({
          hostAppName: "myDAPPwallet",
          hostLogoUrl: "https://assets.ramp.network/misc/test-logo.png",
          hostApiKey: RAMP_API_KEY,
          url: "https://app.demo.ramp.network",
          webhookStatusUrl: "https://webhooks.mydappwallet.com",
          fiatValue: values.fiatValue,
          userAddress: window.mydappwallet.wallet.EVM,
          defaultAsset: asset.brokers[broker],

          fiatCurrency: currency,
          // variant: "embedded-mobile",
        })
          .on("*", eventHandler)
          .show();
        break;
      default:
      case BROKERS.SANDBOX:
        dispatch(app.buy(assetName, values.cryptoAmount, values.fiatValue, currency, onSubmited));
    }
    setSubmit(true);
  };
  const handleReset = () => {
    dispatch({
      type: ActionTypes.APP_BUYSELL_TRANSATION_CLEAR,
    });
    setNetworkFee(0);
    setBrokerFee(0);
  };

  const validationSchema = () =>
    Yup.object().shape({
      fiatValue: Yup.number()
        .typeError(
          t("yup:moreThen", {
            name: t("crypto:amount"),
            value: minPurchaseAmount,
          })
        )
        .moreThan(
          0,
          t("yup:moreThen", {
            name: t("crypto:amount"),
            value: 0,
          })
        )
        .min(
          minPurchaseAmount,
          t("yup:minNumber", {
            name: t("crypto:amount"),
            value: minPurchaseAmount,
          })
        )
        .when("$other", {
          is: () => maxPurchaseAmount > 0,
          then: Yup.number().max(
            maxPurchaseAmount,
            t("yup:maxNumber", {
              name: t("crypto:amount"),
              value: maxPurchaseAmount,
            })
          ),
        }),

      cryptoAmount: Yup.number()
        .typeError(
          t("yup:moreThen", {
            name: t("crypto:amount"),
            value: minPurchaseCryptoAmount,
          })
        )

        .min(
          minPurchaseCryptoAmount,
          t("yup:minNumber", {
            name: t("crypto:amount"),
            value: minPurchaseCryptoAmount,
          })
        )
        .when("$other", {
          is: () => {
            console.log(maxPurchaseCryptoAmount);
            return maxPurchaseCryptoAmount > 0;
          },
          then: Yup.number().max(
            maxPurchaseCryptoAmount,
            t("yup:maxNumber", {
              name: t("crypto:amount"),
              value: maxPurchaseCryptoAmount,
            })
          ),
        }),
    });
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };
  /*
INITIALIZED: 0,
    PAYMENT_STARTED: 1,
    PAYMENT_IN_PROGRESS: 2,
    PAYMENT_FAILED: 3,
    PAYMENT_EXECUTE: 4,
    FIAT_SENT: 5,
    FIAT_RECEIVED: 6,
    RELEASING: 7,
    RELEASED: 8,
    EXPIRED: 9,
    CANCELLED: 10,
    */
  if (transaction) {
    const { status, cryptoAmount, type, fiatValue, fiatCurrency, baseRampFee } = transaction;
    const { decimals, symbol } = transaction.asset;
    return (
      <Grid container spacing={2}>
        <Grid spacing={2} item xs={12}>
          <Box sx={{ margin: 1 }}>
            {status < BYSELL_TRANSACTION_STATUSES.RELEASED &&
              status !== BYSELL_TRANSACTION_STATUSES.PAYMENT_FAILED && (
                <SuiTypography component="p" variant="h5" align="center" width="100%">
                  <Trans>{`crypto:bysell-transaction-status-description-${status}`}</Trans>
                </SuiTypography>
              )}
            {status >= BYSELL_TRANSACTION_STATUSES.EXPIRED && (
              <SuiTypography
                component="p"
                variant="h5"
                align="center"
                noWrap
                width="100%"
                color="error"
              >
                <Trans>{`crypto:bysell-transaction-status-description-${status}`}</Trans>
              </SuiTypography>
            )}
            {status === BYSELL_TRANSACTION_STATUSES.PAYMENT_FAILED && (
              <SuiTypography
                component="p"
                variant="h5"
                align="center"
                noWrap
                width="100%"
                color="warning"
              >
                <Trans>{`crypto:bysell-transaction-status-description-${status}`}</Trans>
              </SuiTypography>
            )}

            {status === BYSELL_TRANSACTION_STATUSES.RELEASED && (
              <SuiTypography component="p" variant="h5" align="center" noWrap width="100%">
                <Trans>{`crypto:bysell-transaction-status-description-${status}`}</Trans>
              </SuiTypography>
            )}

            <TableContainer component={Box}>
              <Table aria-label="collapsible table">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <h4>
                        <Trans>crypto:transaction-type</Trans>
                      </h4>
                    </TableCell>
                    <TableCell>
                      <Trans values={{ symbol }}>{`crypto:bysell-transaction-type-${type}`}</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h4>
                        <Trans>crypto:status</Trans>
                      </h4>
                    </TableCell>
                    <TableCell align="left">
                      <>
                        {status < BYSELL_TRANSACTION_STATUSES.RELEASED && (
                          <SuiButton variant="gradient" color="info" disabled>
                            <Trans>{`crypto:bysell-transaction-status-${status}`}</Trans>
                          </SuiButton>
                        )}
                        {status === BYSELL_TRANSACTION_STATUSES.PAYMENT_FAILED && (
                          <SuiButton variant="gradient" color="warning" disabled>
                            <Trans>{`crypto:bysell-transaction-status-${status}`}</Trans>
                          </SuiButton>
                        )}
                        {status >= BYSELL_TRANSACTION_STATUSES.EXPIRED && (
                          <SuiButton variant="gradient" color="error" disabled>
                            <Trans>{`crypto:bysell-transaction-status-${status}`}</Trans>
                          </SuiButton>
                        )}
                        {status === BYSELL_TRANSACTION_STATUSES.RELEASED && (
                          <SuiButton variant="gradient" color="success" disabled>
                            <Trans>{`crypto:bysell-transaction-status-${status}`}</Trans>
                          </SuiButton>
                        )}
                      </>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h4>
                        <Trans>crypto:purchase-amount</Trans>
                      </h4>
                    </TableCell>
                    <TableCell>
                      {CryptoUtil.toAmountAsString(cryptoAmount, decimals)} {symbol}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <h4>
                        <Trans>crypto:paid-amount</Trans>
                      </h4>
                    </TableCell>
                    <TableCell>
                      {fiatValue} {fiatCurrency}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h4>
                        <Trans>crypto:broker-fee</Trans>
                      </h4>
                    </TableCell>
                    <TableCell>
                      {baseRampFee} {fiatCurrency}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h4>
                        <Trans>crypto:network-fee</Trans>
                      </h4>
                    </TableCell>
                    <TableCell>
                      {networkFee} {fiatCurrency}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <SuiBox pt="0.75rem" pl="1rem" pb="0.2rem">
                <Link
                  sx={{ focusVisible: { outline: "none" } }}
                  href="#"
                  onClick={() =>
                    window.open(
                      `https://transactions.demo.ramp.network/#/details/${brokerId}?secret=${secret}`,
                      "_blank"
                    )
                  }
                >
                  <Trans>crypto:chack-transaction-status</Trans>
                </Link>
              </SuiBox>
            </TableContainer>
            <Divider variant="middle" />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <SuiTypography component="label" variant="caption" textTransform="capitalize">
                <Trans values={{ one: 3, two: 3 }}>step-of</Trans>
              </SuiTypography>
              <Grid item xs={5}>
                {!submit && (
                  <SuiButton
                    name="submit"
                    component={SuiButton}
                    onClick={() => {
                      handleReset();
                    }}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    <Trans>finish</Trans>
                  </SuiButton>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid spacing={2} item xs={12} sm={11} md={8} lg={6} xl={miniSidenav ? 6 : 8}>
        <Formik
          initialValues={initialValues}
          validate={validate(validationSchema)}
          onSubmit={onSubmit}
          innerRef={formik}
        >
          {({ values, errors, touched, handleSubmit, handleBlur }) => (
            <SuiBox component="form" onSubmit={handleSubmit} noValidate name="sendForm">
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Grid item>
                    <FiatAmountField
                      value={values.fiatValue}
                      error={errors.fiatValue}
                      touched={touched.fiatValue}
                      changeValue={handleFiatAmountChange}
                      onBlur={handleBlur}
                      asset={asset}
                      currency={currency}
                      setCurrency={handleCurrencyChange}
                      exchangeRates={exchangeRates[assetName].price}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid item>
                    <AssetAmountField
                      value={values.cryptoAmount}
                      error={errors.cryptoAmount}
                      touched={touched.cryptoAmount}
                      changeValue={handleAssetAmountChange}
                      onBlur={handleBlur}
                      asset={asset}
                      setAsset={handleAssetChange}
                      exchangeRates={exchangeRates[assetName].price}
                    />
                  </Grid>
                  {(brokerFee || networkFee) && (
                    <SuiTypography
                      component="p"
                      variant="caption"
                      align="left"
                      noWrap
                      width="100%"
                      ml={1}
                      mt={1}
                    >
                      {brokerFee && (
                        <>
                          <b>
                            <Trans>crypto:broker-fee</Trans>
                          </b>{" "}
                          {brokerFee} {currency}
                        </>
                      )}
                      {brokerFee && networkFee && " "}
                      {networkFee && (
                        <>
                          <b>
                            <Trans>crypto:network-fee</Trans>
                          </b>{" "}
                          {networkFee} {currency}
                        </>
                      )}
                    </SuiTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid item>
                    <SuiButton
                      name="submit"
                      component={SuiButton}
                      onClick={() => {
                        formik.current.submitForm();
                      }}
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={submit}
                    >
                      <Trans>buy</Trans>
                    </SuiButton>
                  </Grid>
                </Grid>
              </Grid>
            </SuiBox>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

BuySell.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  name: PropTypes.string.isRequired,
  defaultAsset: PropTypes.shape({
    chain: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    brokers: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(BuySell);
