import { useState } from "react";
import { useDispatch } from "react-redux";
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Modal from "@mui/material/Modal";
import SuiTypography from "components/sui/SuiTypography";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";

// Layout Components
import ErrorMsg from "components/layout/Error";

// Actions
import * as profile from "actions/profile";

// Constants
import Constants from "constants/Constants";

const { AUTHENTICATORS } = Constants;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "background.paper",
  boxShadow: 24,
  maxHeight: "90%",
  padding: 4,
};

function Step1({ auth, nextStep, setError, handleClose }) {
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);

  const onSubmited = (error) => {
    if (error) {
      setError(error);
    } else {
      nextStep();
    }
    setSubmit(false);
  };
  const onSubmit = () => {
    setSubmit(true);
    dispatch(profile.authenticatorDisable({ type: auth.type }, onSubmited));
    return true;
  };
  return (
    <>
      <SuiBox
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        mb={2}
        mt={1}
      >
        <SuiTypography variant="caption">
          <Trans>profile:authenticator-disable-step1</Trans>{" "}
          <b>
            <Trans>{`profile:authenticator-${AUTHENTICATORS.SMS}`}</Trans>
          </b>
          ?
        </SuiTypography>
      </SuiBox>
      <Divider variant="middle" />

      <Grid container direction="row" justifyContent="flex-end" alignItems="right">
        <Grid item xs={4} mr={1}>
          <SuiButton
            name="submit"
            disabled={submit}
            component={SuiButton}
            onClick={() => {
              onSubmit();
            }}
            variant="gradient"
            color="info"
            fullWidth
          >
            <Trans>yes</Trans>
          </SuiButton>
        </Grid>
        <Grid item xs={4}>
          <SuiButton
            name="submit"
            disabled={submit}
            component={SuiButton}
            onClick={() => {
              handleClose();
            }}
            variant="gradient"
            color="info"
            fullWidth
          >
            <Trans>no</Trans>
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
}

Step1.propTypes = {
  auth: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

function Step2({ handleClose }) {
  return (
    <>
      <Grid container spacing={2} wrap="nowrap" mb={2} mt={1}>
        <Grid item>
          <PhonelinkLockIcon fontSize="large" />
        </Grid>
        <Grid item>
          <SuiTypography variant="caption" component="div">
            <Trans>profile:authenticator-disable-step2</Trans>
          </SuiTypography>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item xs={5}>
          <SuiButton
            name="submit"
            component={SuiButton}
            onClick={() => {
              handleClose();
            }}
            variant="gradient"
            color="info"
            fullWidth
          >
            <Trans>finish</Trans>
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
}

Step2.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

function Error({ handleClose, error }) {
  return (
    <>
      <SuiBox mt={2}>
        <ErrorMsg error={error} color="primary" />
      </SuiBox>
      <Divider variant="middle" />
      <Grid container direction="row" justifyContent="flex-end" alignItems="right">
        <Grid item xs={5}>
          <SuiButton
            name="submit"
            component={SuiButton}
            onClick={() => {
              handleClose();
            }}
            variant="gradient"
            color="info"
            fullWidth
          >
            <Trans>cancel</Trans>
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
}

Error.propTypes = {
  handleClose: PropTypes.func.isRequired,
  error: PropTypes.shape({
    code: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
};

function DisableAuthenticatorDialog({ auth, handleClose, t }) {
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const nextStep = () => {
    setStep(step + 1);
  };
  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <SuiBox sx={style}>
          <SuiBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <SuiTypography variant="h4">
              <Trans>{`profile:authenticator-${AUTHENTICATORS.SMS}`}</Trans>
            </SuiTypography>
            <SuiBox
              sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={handleClose}
            >
              <CloseIcon size="small" color="#92929d" />
            </SuiBox>
          </SuiBox>
          {error && <Error error={error} handleClose={handleClose} t={t} />}
          {!error && step === 1 && (
            <Step1
              auth={auth}
              nextStep={nextStep}
              setError={setError}
              handleClose={handleClose}
              t={t}
            />
          )}

          {!error && step === 2 && <Step2 handleClose={handleClose} t={t} />}
        </SuiBox>
      </>
    </Modal>
  );
}

DisableAuthenticatorDialog.propTypes = {
  auth: PropTypes.shape({
    type: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(DisableAuthenticatorDialog);
