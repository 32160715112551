import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

// Soft UI Dashboard PRO React components
import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";
import SuiButton from "components/sui/SuiButton";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

function Row({ row, index, handleEditApp, handleDeleteBook, t }) {
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>{index + 1}</TableCell>
        <TableCell component="th" scope="row">
          {row.name} <SuiIconCopyToClipboard text={row.name} name={t("name")} />
        </TableCell>
        <TableCell align="left">
          {row.apikey} <SuiIconCopyToClipboard text={row.apikey} name={t("developers:apiKey")} />
        </TableCell>
        <TableCell>
          <>
            {row.status === 0 && (
              <SuiButton variant="gradient" color="warning" disabled>
                <Trans>inactive</Trans>
              </SuiButton>
            )}
            {row.status === 1 && (
              <SuiButton variant="gradient" color="info" disabled>
                <Trans>active</Trans>
              </SuiButton>
            )}
          </>
        </TableCell>

        <TableCell align="center">
          <ToggleButtonGroup orientation="horizontal">
            <ToggleButton
              component={NavLink}
              to={`/developers/applications/${row.uid}`}
              value="edit"
              onClick={() => {
                handleEditApp(index, row);
              }}
            >
              <EditIcon />
            </ToggleButton>
            <ToggleButton
              value="delete"
              onClick={() => {
                handleDeleteBook(index, row);
              }}
            >
              <DeleteIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    apikey: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
  handleEditApp: PropTypes.func.isRequired,
  handleDeleteBook: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Row);
