/* eslint-disable react/destructuring-assignment */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// react-router-dom components
import { Redirect, useParams } from "react-router-dom";

import { PropTypes } from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";

// Soft UI Dashboard PRO React components
import DefaultLayout from "components/layout/LayoutContainers/DefaultLayout";

// Cryptos
import EVM from "layouts/asset/evm";
import ERC20 from "layouts/asset/erc20";
import IBAN from "layouts/asset/iban";

function Asset() {
  const [error, setError] = useState(false);
  const [name, setName] = useState(undefined);
  const [asset, setAsset] = useState({ type: -1 });

  const params = useParams();
  const assets = useSelector((state) => (state.appStore.assets ? state.appStore.assets : {}));
  useEffect(() => {
    if (name !== params.name) {
      setName(params.name);
      const item = assets[params.name];
      if (!item) {
        setError({ code: 404 });
        return;
      }
      setAsset(item);
    }
  });

  if (error) return <Redirect to={`/error/${error.code}`} />;

  return (
    <DefaultLayout>
      <SuiBox py={1} minHeight="calc(100vh - 114px - 23px)">
        <Grid item xs={12} lg={7}>
          <Grid>
            <Grid item xs={12}>
              {
                {
                  EVM: <EVM name={name} asset={asset} />,
                  ERC20: <ERC20 name={name} asset={asset} />,
                  IBAN: <IBAN name={name} asset={asset} />,
                }[asset.type]
              }
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </DefaultLayout>
  );
}

Asset.propTypes = {
  // eslint-disable-next-line react/require-default-props
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }),
  appStore: PropTypes.shape({
    assets: PropTypes.shape({
      type: PropTypes.string,
    }),
    settings: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
};

export default withTranslation()(Asset);
