import React from "react";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";

import SuiAlert from "components/sui/SuiAlert";

const Info = ({ info, t, ...attrs }) => {
  if (info && info.code) {
    return (
      <SuiAlert {...attrs}>
        <Trans>{t(`infos:${info.code}`, { data: info.data })}</Trans>
      </SuiAlert>
    );
  }

  return null;
};

// Setting default values for the props of Alert
Info.defaultProps = {
  info: undefined,
};

// Typechecking props for the Alert
Info.propTypes = {
  info: PropTypes.shape({
    code: PropTypes.number,
    data: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Info);
