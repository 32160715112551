/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// react-router-dom components

import { Redirect } from "react-router-dom";
// PropTypes
import PropTypes from "prop-types";

// i18n
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

// Authentication layout components
import Error from "components/layout/Error";
import Info from "components/layout/Info";
// Actions
import * as app from "actions/app";
import * as authentication from "actions/authentication";
// Authentication layout components
import BasicLayout from "layouts/components/BasicLayout";
import Spinner from "components/layout/Spinner";
// Images
import curved9 from "assets/images/curved-images/curved9.jpg";

// Icons
import PhonelinkOffIcon from "@mui/icons-material/PhonelinkOff";
import { isTablet, isDesktop, isMobile, isConsole, browserName, osName } from "react-device-detect";

// Constants
import Constants from "constants/Constants";

const { REFLINK_TYPES } = Constants;

function Stage1({ refId, nextStage, setOneTime, t }) {
  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.appStore);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    dispatch(
      app.refLink({ refId, type: REFLINK_TYPES.SIGN_IN }, (error) => {
        if (error) {
          setRedirect(`/error/${error.code}`);
        } else {
          setLoading(false);
        }
      })
    );
  }, []);

  const onSubmited = (error, result) => {
    if (error) {
      setRedirect(`/error/${error.code}`);
    } else {
      nextStage(result.refId, result.authenticator);
    }
  };

  const onSubmit = (oneTime) => {
    setOneTime(oneTime);
    dispatch(authentication.signIn2({ refId }, onSubmited));
  };
  const deviceName = () => {
    if (isTablet) {
      return `Tablet ${osName} ${browserName}`;
    }
    if (isDesktop) {
      return `Computer ${osName} ${browserName}`;
    }
    if (isMobile) {
      return `Phone ${osName} ${browserName}`;
    }
    if (isConsole) {
      return `Console ${osName} ${browserName}`;
    }
    return "";
  };
  if (redirect) return <Redirect to={redirect} />;
  if (loading) return <Spinner />;

  return (
    <BasicLayout
      title={t("authentication:welcome")}
      description={t("authentication:signin.title")}
      image={curved9}
    >
      <Card>
        <SuiBox p={3} textAlign="center">
          <SuiTypography variant="h5" fontWeight="medium">
            <Trans>authentication:signin.device-not-resolved.subtitle</Trans>!
          </SuiTypography>
        </SuiBox>
        <SuiBox p={3}>
          <Info
            info={appStore.info}
            color="info"
            onClick={() => {
              dispatch(app.infoClose());
            }}
          />
          <Error
            error={appStore.error}
            color="primary"
            onClick={() => {
              dispatch(app.errorClose());
            }}
          />
          <SuiBox textAlign="center">
            <PhonelinkOffIcon fontSize="large" />
          </SuiBox>
          <SuiBox mb={2}>
            <SuiTypography component="p" variant="h6" align="center" width="100%" color="info">
              <Trans values={{ name: deviceName() }}>
                authentication:signin.device-not-resolved.text
              </Trans>
            </SuiTypography>
          </SuiBox>

          <SuiBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={6} mt={1} mb={1}>
                <SuiButton
                  variant="gradient"
                  color="dark"
                  fullWidth
                  onClick={() => {
                    onSubmit(false);
                  }}
                >
                  <Trans>authentication:signin.add-to-trusted</Trans>
                </SuiButton>
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={6} mt={1} mb={1}>
                <SuiButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => {
                    onSubmit(true);
                  }}
                >
                  <Trans>authentication:signin.one-time-access</Trans>
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

Stage1.propTypes = {
  refId: PropTypes.string.isRequired,
  nextStage: PropTypes.func.isRequired,
  setOneTime: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Stage1);
