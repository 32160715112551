// Images
import bitcoin from "assets/images/currency/bitcoin.png";

// Sui UI Dashboard React components

const listItem = [
  {
    id: 1,
    image: bitcoin,
    imageTag: "",
    name: "Bitcoin",
    tag: "BTC",
    description: "",
    item: "0",
    price: "0",
    currency: "$",
    subicon: "",
    rate: 5,
    visible: true,
    selected: true,
  },
];
export default listItem;
