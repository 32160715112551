import { forwardRef } from "react";
import { PropTypes } from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";
import SuiTypography from "components/sui/SuiTypography";
import SuiTokenIcon from "components/sui/SuiTokenIcon";

import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

import * as CryptoUtil from "utils/CryptoUtil";

// Soft UI Dashboard PRO React helper functions
const { inputColors } = colors;
const { borderWidth } = borders;
const { inputBoxShadow } = boxShadows;

const FromField = forwardRef(
  ({ fromOpen, asset, balance, handleCloseFromMenu, handleOpenFromMenu, t }, ref) => (
    <SuiBox
      border={`${borderWidth[1]} solid ${inputColors.borderColor.focus}`}
      boxShadow={`${inputBoxShadow.focus}!important`}
      borderRadius="lg"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
      onClick={(e) => {
        // eslint-disable-next-line no-unused-expressions
        fromOpen ? handleCloseFromMenu(asset) : handleOpenFromMenu(e);
      }}
      ref={ref}
      sx={{
        "&:hover": {
          background: "#eceeef",
        },
        cursor: "pointer",
      }}
    >
      {asset.icon ? (
        <div
          className="d-inline-block item-icon-wrapper"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: asset.icon }}
        />
      ) : (
        <SuiTokenIcon address={asset.address} />
      )}

      <SuiBox width="100%" display="flex" flexDirection="column" lineHeight={1} ml={1}>
        <SuiTypography variant="button" fontWeight="medium">
          {asset.symbol}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="medium">
          {CryptoUtil.toAmountAsString(balance, asset.decimals)} {asset.symbol}
        </SuiTypography>
      </SuiBox>
      <SuiBox ml="auto" lineHeight={0}>
        <Tooltip title={t("crypto:from-tooltip")} placement="bottom">
          <SuiButton variant="outlined" color="secondary" size="small" iconOnly circular>
            {fromOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </SuiButton>
        </Tooltip>
      </SuiBox>
    </SuiBox>
  )
);

FromField.propTypes = {
  fromOpen: PropTypes.bool.isRequired,
  asset: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    decimals: PropTypes.number.isRequired,
  }).isRequired,
  balance: PropTypes.number.isRequired,
  handleCloseFromMenu: PropTypes.func.isRequired,
  handleOpenFromMenu: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FromField);
