import React from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

// Soft UI Dashboard PRO React components
import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

function Row({ row, index, uid, handleEditContract, handleDeleteContract, t }) {
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>{index + 1}</TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">
          {row.address} <SuiIconCopyToClipboard text={row.address} name={t("address")} />
        </TableCell>
        <TableCell>
          <Trans>{`network-${row.chain}`}</Trans>
        </TableCell>

        <TableCell align="center">
          <ToggleButtonGroup orientation="horizontal">
            <ToggleButton
              component={NavLink}
              to={`/developers/applications/${uid}/${row.address}`}
              value="edit"
              onClick={() => {
                handleEditContract(index, row);
              }}
            >
              <EditIcon />
            </ToggleButton>
            <ToggleButton
              value="delete"
              onClick={() => {
                handleDeleteContract(index, row);
              }}
            >
              <DeleteIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    chain: PropTypes.number.isRequired,
  }).isRequired,
  uid: PropTypes.string.isRequired,
  handleEditContract: PropTypes.func.isRequired,
  handleDeleteContract: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Row);
