import React, { useEffect, useState } from "react";

// @mui material components
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { OutlinedInput, FormControl, Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";

// redux
import { connect, useSelector } from "react-redux";

// Actions
import * as app from "actions/app";

// import Footer from "components/layout/Footer";
// import DefaultLayout from "components/layout/LayoutContainers/DefaultLayout";
// import DefaultNavbar from "components/layout/Navbars/DefaultNavbar";
// import pageRoutes from "page.routes";

import SearchIcon from "assets/images/icons/search.png";
import CsvIcon from "assets/images/icons/csv-icon.png";

// Soft UI Dashboard React components
import SuiBox from "components/sui/SuiBox";
import SuiAvatar from "components/sui/SuiAvatar";
import SuiTypography from "components/sui/SuiTypography";
import BasicMenu from "components/layout/Sidenav/FilterDropdown";
import SuiBadge from "components/sui/SuiBadge";
import _ from "lodash";

import MuiSwitch from "components/layout/WalletManagement/MuiSwitch";
import WalletManagementRoot from "components/layout/WalletManagement/WalletManagementRoot";
import WalletManagmentCryptoList from "components/layout/WalletManagement/WalletManagmentCryptoList";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// eslint-disable-next-line react/prop-types
const WalletManagement = ({ changeSetting, setSettingsToggle }) => {
  // select data from store
  const assets = useSelector((state) =>
    state.appStore.assets ? state.appStore.assets : {}
  );

  const [sortingType, setSortingType] = useState(
    useSelector((state) => state.appStore.settings.sortingType)
  );
  const [order, setOrder] = useState(() => {
    if (sortingType === "CURRENCY") {
      return Object.keys(assets).sort((key1, key2) =>
        assets[key1].symbol.localeCompare(assets[key2].symbol)
      );
    }
    if (sortingType === "ESTIMATES") {
      return Object.keys(assets).sort((key1, key2) =>
        assets[key1].name.localeCompare(assets[key2].name)
      );
    }
    return Object.keys(assets).sort((key1, key2) =>
      assets[key1].symbol.localeCompare(assets[key2].symbol)
    );
  });

  const [hiddenItems, setHiddenItems] = useState(
    useSelector((state) => state.appStore.settings.hiddenItems)
  );

  const [showHiddenWallets, setShowHiddenWallets] = useState(
    useSelector((state) => state.appStore.settings.showHiddenWallets)
  );
  const [showZeroBalance, setShowZeroBalance] = useState(
    useSelector((state) => state.appStore.settings.showZeroBalance)
  );
  const [showTokens, setShowTokens] = useState(
    useSelector((state) => state.appStore.settings.showTokens)
  );

  const [search, setSearch] = useState("");

  // filter
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShow = (id) => {
    const newHidenItems = hiddenItems.filter((value) => value !== id);
    setHiddenItems(newHidenItems);
    changeSetting("hiddenItems", newHidenItems);
  };

  const handleHide = (id) => {
    const newHidenItems = [...hiddenItems, id];
    setHiddenItems(newHidenItems);
    changeSetting("hiddenItems", newHidenItems);
  };

  useEffect(() => {
    if (sortingType) {
      changeSetting("sortingType", sortingType);
      if (sortingType === "CURRENCY") {
        // eslint-disable-next-line no-unused-vars
        setOrder(
          Object.keys(assets).sort((key1, key2) =>
            assets[key1].symbol.localeCompare(assets[key2].symbol)
          )
        );
      } else if (sortingType === "ESTIMATES") {
        setOrder(
          Object.keys(assets).sort((key1, key2) =>
            assets[key1].name.localeCompare(assets[key2].name)
          )
        );
      } else if (sortingType === "RATING") {
        setOrder(Object.keys(assets));
      }
    }
  }, [sortingType]);

  useEffect(() => {
    changeSetting("showHiddenWallets", showHiddenWallets);
  }, [showHiddenWallets]);

  useEffect(() => {
    changeSetting("showZeroBalance", showZeroBalance);
  }, [showZeroBalance]);

  useEffect(() => {
    changeSetting("showTokens", showTokens);
  }, [showTokens]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    backgroundColor: "background.paper",
    boxShadow: 24,
    maxHeight: "90%",
    padding: 4,
  };

  return (
    <Modal open aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <WalletManagementRoot>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <SuiTypography variant="h3">Wallet management</SuiTypography>
            <Box
              sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={() => setSettingsToggle(false)}
            >
              <CloseIcon size="small" color="#92929d" />
            </Box>
          </Box>

          <Grid
            container
            sx={{ marginLeft: "0", marginRight: "auto", minHeight: "75vh", marginTop: "2rem" }}
          >
            <Grid item xs={12}>
              <SuiBox py={3} opacity={1} minHeight="100%">
                <SuiBox className="block-wrapper" align="center" bgColor="white" component="div">
                  <SuiBox>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      position="relative"
                      spacing={2}
                    >
                      <Grid item xs={12} xl={7}>
                        <SuiBox className="crypto-switch-wrapper" display="flex">
                          <Stack
                            direction="row"
                            className="show-hidden-wallet-block"
                            spacing={1}
                            alignItems="center"
                            mr={2}
                          >
                            <Typography fontSize={14} color="#000" fontWeight={500}>
                              Show hidden wallet
                            </Typography>
                            <SuiBadge
                              badgeContent={hiddenItems.length.toString()}
                              size="xs"
                              variant="contained"
                              container
                              className="badge__light"
                            />
                            <MuiSwitch
                              defaultChecked={showHiddenWallets}
                              variant="success"
                              onChange={(event) => setShowHiddenWallets(event.target.checked)}
                            />
                          </Stack>
                          <Stack direction="row" spacing={1} alignItems="center" mr={2}>
                            <Typography fontSize={14} color="#44444f" fontWeight={500}>
                              Show zero balances
                            </Typography>
                            <MuiSwitch
                              defaultChecked={showZeroBalance}
                              variant="success"
                              onChange={(event) => setShowZeroBalance(event.target.checked)}
                            />
                          </Stack>
                          <Stack direction="row" spacing={1} alignItems="center" mr={2}>
                            <Typography fontSize={14} color="#44444f" fontWeight={500}>
                              Show tokens
                            </Typography>
                            <MuiSwitch
                              defaultChecked={showTokens}
                              variant="success"
                              onChange={(event) => setShowTokens(event.target.checked)}
                            />
                          </Stack>
                        </SuiBox>
                      </Grid>
                      <Grid item md={8} lg={10}>
                        <FormControl sx={{ width: "100%" }} className="input__control">
                          <OutlinedInput
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <SuiAvatar
                                  src={SearchIcon}
                                  alt="something here"
                                  variant="sm"
                                  className="searchinput"
                                />
                              </InputAdornment>
                            }
                            flex={1}
                            fontWeight={600}
                            className="input__control_inn"
                            placeholder="Search by name, currency, ticker, address"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h6"
                          display="flex"
                          alignItems="center"
                          sx={{ cursor: "pointer" }}
                          fontSize={14}
                          color="#696974"
                          onClick={handleClick}
                          className="sort-by-dropdown"
                        >
                          Sort by: <span>{_.startCase(_.camelCase(sortingType))}</span>
                          <ArrowDropDownIcon fontSize="small" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </SuiBox>
                </SuiBox>

                <SuiBox className="block-wrapper" component="div">
                  <SuiBox
                    display="flex"
                    justifyContent="space-between"
                    className="list-title-block"
                    alignItems="center"
                    p={2}
                  >
                    <SuiTypography className="list-title" variant="h5">
                      YOUR WALLETS
                    </SuiTypography>
                    <Link
                      className="export-link"
                      to="/dashboard/wallet-management"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <span>Export balances</span>
                      <SuiAvatar src={CsvIcon} alt="csv icon" variant="sm" className="csv-icon" />
                    </Link>
                  </SuiBox>
                  {!showZeroBalance ? (
                    <SuiBox component="div" className="crypto_wrapper">
                      <Typography
                        fontSize={16}
                        color="#a1a1a1"
                        textAlign="center"
                        sx={{ padding: "30px", lineHeight: "24px", letterSpacing: 0 }}
                        fontWeight={600}
                      >
                        All wallets are hidden by the filter. Check the filter settings
                      </Typography>
                    </SuiBox>
                  ) : (
                    <SuiBox component="ul" className="crypto_wrapper">
                      <WalletManagmentCryptoList
                        listItem={assets}
                        order={order}
                        filter={search}
                        hiddenItems={hiddenItems}
                        handleShow={handleShow}
                        handleHide={handleHide}
                        showHiddenWallets={showHiddenWallets}
                        showZeroBalance={showZeroBalance}
                        showTokens={showTokens}
                      />
                    </SuiBox>
                  )}
                </SuiBox>
              </SuiBox>
              <BasicMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setSortingType={setSortingType}
                sortingType={sortingType}
              />
            </Grid>
          </Grid>
        </WalletManagementRoot>
        {/* <Footer /> */}
      </Box>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  changeSetting: (name, value, callback) => dispatch(app.changeSetting(name, value, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletManagement);
