/* eslint-disable no-unused-vars */
import { forwardRef, useState, useRef } from "react";
import { PropTypes } from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiCountryIcon from "components/sui/SuiCountryIcon";
import SuiBox from "components/sui/SuiBox";
import SuiNavbarLink from "components/sui/SuiNavbarLink";
import Paper from "@mui/material/Paper";
// @mui material components
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import CurrencyMenu from "components/layout/CurrencyMenu";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

import NumberFormat from "react-number-format";

// Utils
import * as CryptoUtil from "utils/CryptoUtil";

// Constants
import Constants from "constants/Constants";

// Soft UI Dashboard PRO React helper functions
const { inputColors } = colors;
const { borderWidth } = borders;
const { inputBoxShadow } = boxShadows;

const countries = Constants.CURRENCIES_COUNTRIES_CODES;

// eslint-disable-next-line no-unused-vars
const FiatAmountField = forwardRef(
  (
    { value, error, touched, changeValue, onBlur, asset, currency, setCurrency, exchangeRates, t },
    ref
  ) => {
    const [highlight, setHighlight] = useState(false);
    const [currencyOpen, setCurrencyOpen] = useState(false);
    const amount = useRef(null);

    const handleOpenCurrencyMenu = ({ currentTarget }) => {
      setCurrencyOpen(currentTarget);
    };
    const handleCloseCurrencyMenu = (_currency) => {
      setCurrencyOpen(false);
      if (_currency) {
        setCurrency(_currency);
      }
      amount.current.focus();
    };

    let elevation;
    if (touched && error) {
      elevation = highlight || currencyOpen ? 13 : 12;
    } else {
      elevation = highlight || currencyOpen ? 11 : 10;
    }

    return (
      <>
        <Paper
          elevation={elevation}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={1}
          sx={{
            display: "flex",
            borderRadius: borders.borderRadius.lg,
            boxShadow: 0,
            position: "relative",
            "&:hover": {
              background: "#eceeef",
            },
            cursor: "pointer",
          }}
          ref={ref}
        >
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            width="100%"
            re
          >
            <FormControl fullWidth sx={{ padding: 1 }}>
              <NumberFormat
                className="send_amount_1_input"
                name="fiatValue"
                id="fiatValue"
                getInputRef={amount}
                placeholder={currency}
                required
                onValueChange={(values, sourceInfo) => {
                  if (sourceInfo.source === "event" && sourceInfo.event.type === "change")
                    changeValue(values.value);
                }}
                value={value}
                suffix={` ${currency}`}
                onBlur={(e) => {
                  setHighlight(false);
                  onBlur(e);
                }}
                decimalScale={2}
                onFocus={() => {
                  setHighlight(true);
                }}
              />
            </FormControl>
            <SuiBox ml="auto" lineHeight={0} onClick={handleOpenCurrencyMenu}>
              <Tooltip title={t("crypto:from-tooltip")} placement="bottom">
                <SuiCountryIcon code={countries[currency]} />
              </Tooltip>
            </SuiBox>
            <SuiBox ml="auto" lineHeight={0} onClick={handleOpenCurrencyMenu}>
              {currency}
            </SuiBox>
            <SuiBox ml="auto" lineHeight={0} onClick={handleOpenCurrencyMenu}>
              {currencyOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </SuiBox>
            <CurrencyMenu
              anchorEl={currencyOpen}
              currencies={exchangeRates}
              close={handleCloseCurrencyMenu}
            />
          </SuiBox>
        </Paper>
        <FormHelperText error id="error">
          {touched ? error : ""}
        </FormHelperText>
      </>
    );
  }
);
FiatAmountField.defaultProps = {
  touched: false,
  error: false,
};

FiatAmountField.propTypes = {
  value: PropTypes.number.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  changeValue: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
  }).isRequired,
  currency: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
  exchangeRates: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FiatAmountField);
