/* eslint-disable no-unused-vars */
import { forwardRef, useState } from "react";
import { PropTypes } from "prop-types";

// Soft UI Dashboard PRO React components

// @mui material components
import Paper from "@mui/material/Paper";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import NumberFormat from "react-number-format";

// Utils
import * as CryptoUtil from "utils/CryptoUtil";

// eslint-disable-next-line no-unused-vars
const AmountField = forwardRef(
  (
    {
      value,
      error,
      touched,
      changeValue1,
      changeValue2,
      onBlur,
      currency1,
      currency2,
      exchangeRate,
      decimals,
    },
    ref
  ) => {
    const [highlight, setHighlight] = useState(false);

    let elevation;
    if (touched && error) {
      elevation = highlight ? 13 : 12;
    } else {
      elevation = highlight ? 11 : 10;
    }
    const amount1 = CryptoUtil.toAmountAsString(value, decimals);
    let amount2 = CryptoUtil.toAmountAsString(value, decimals, 2, exchangeRate);
    let prefix = "";
    if (amount1 > 0 && amount2 < 0.01) {
      prefix = "< ";
      amount2 = 0.01;
    }

    return (
      <>
        <Paper
          elevation={elevation}
          sx={{
            display: "flex-direction",
            borderRadius: borders.borderRadius.lg,
            boxShadow: 0,
            position: "relative",
            "&:hover": { outline: 0 },
          }}
        >
          <FormControl fullWidth sx={{ padding: 1 }}>
            <NumberFormat
              className="send_amount_1_input"
              name="value"
              id="value"
              placeholder={currency1}
              required
              onValueChange={(values, sourceInfo) => {
                if (sourceInfo.source === "event") changeValue1(values.floatValue);
              }}
              value={amount1}
              suffix={` ${currency1}`}
              onBlur={(e) => {
                setHighlight(false);
                onBlur(e);
              }}
              decimalScale={decimals}
              onFocus={() => {
                setHighlight(true);
              }}
            />
            <NumberFormat
              className="send_amount_2_input"
              name="value"
              id="value"
              placeholder={currency2}
              required
              onValueChange={(values, sourceInfo) => {
                if (sourceInfo.source === "event") changeValue2(values.floatValue);
              }}
              value={amount2}
              prefix={prefix}
              suffix={` ${currency2}`}
              onBlur={(e) => {
                setHighlight(false);
                onBlur(e);
              }}
              decimalScale={2}
              onFocus={() => {
                setHighlight(true);
              }}
            />
          </FormControl>
        </Paper>
        <FormHelperText error id="error">
          {touched ? error : ""}
        </FormHelperText>
      </>
    );
  }
);
AmountField.defaultProps = {
  touched: false,
  error: false,
};

AmountField.propTypes = {
  value: PropTypes.number.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  changeValue1: PropTypes.func.isRequired,
  changeValue2: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  currency1: PropTypes.string.isRequired,
  currency2: PropTypes.string.isRequired,
  exchangeRate: PropTypes.number.isRequired,
  decimals: PropTypes.number.isRequired,
};

export default AmountField;
