/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { NavLink } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// Soft UI Dashboard PRO React components

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

// Frame layout components
import CoverLayout from "layouts/pages/components/CoverLayout";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Privacy() {
  return (
    <>
      <CoverLayout
        title="Privacy policy"
        description="PRIVACY POLICY OF THE MYDAPPWALLET APP"
        header={
          <SuiTypography variant="inherit" fontWeight="medium">
            <NavLink to="/legal" sx={{ coursor: "pointer" }} textAlign="left">
              &gt; Back to legal homepage
            </NavLink>
          </SuiTypography>
        }
      >
        <Card>
          <SuiBox p={3} mb={1} textAlign="left">
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                This Privacy Policy (hereinafter: <b>&quot;Policy&quot;</b>) contains information on
                the processing of your personal data in connection with the use of the MyDappWallet
                App, operating at the internet address http://wallet.mydappwallet.com/ (hereinafter:
                <b>&quot;App&quot;</b>).
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium">
                All capitalized terms that are not otherwise defined in the Policy shall have the
                meaning given to them in the Terms and Conditions, available at:{" "}
                <NavLink to="/legal/terms">
                  {`${window.location.protocol}//${window.location.hostname}${
                    window.location.port ? `:${window.location.port}` : ""
                  }`}
                  /legal/terms
                </NavLink>
                .
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={5}>
              <SuiTypography variant="h5" fontWeight="bold">
                Personal data Controller
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium">
                The Controller of your personal data is DBRIDGE sp. z o.o. company with its
                registered office in Warsaw (registered office address: ul. Echa Leśne 21B, 03-286
                Warsaw), entered in the Register of Entrepreneurs of the National Court Register
                kept by the District Court of Warsaw, XIV Commercial Division of the National Court
                Register under the KRS number: 0000981309, holder of NIP: 5242945385, REGON number:
                522538698, with the share capital of PLN 6.000 (six thousands PLN) paid in full
                (hereinafter:
                <b>&quot;Controller&quot;</b>).
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={5}>
              <SuiTypography variant="h5" fontWeight="bold">
                Contact with the Controller
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium">
                In all matters related to the processing of personal data, you can contact the
                Controller using:
              </SuiTypography>
              <SuiTypography variant="inherit" fontWeight="medium">
                1) e-mail - at: contact@dbridge.network;
              </SuiTypography>
              <SuiTypography variant="inherit" fontWeight="medium">
                2) traditional mail - at: ul. Echa Leśne 21B, 03-286 Warsaw;
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={5}>
              <SuiTypography variant="h5" fontWeight="bold">
                Measures to protect personal data
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium">
                The Controller uses modern organizational and technical safeguards to ensure the
                best possible protection of your personal data and guarantees that it processes them
                in accordance with the provisions of Regulation (EU) 2016/679 of the European
                Parliament and of the Council of 27 April 2016 on the protection of individuals with
                regard to the processing of personal data and on the free movement of such data, and
                repealing Directive 95/46/EC (General Data Protection Regulation) (hereinafter:{" "}
                <b>&quot;GDPR&quot;</b>), the Act of 10 May 2018 on the protection of personal data
                and other provisions on the protection of personal data.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={5}>
              <SuiTypography variant="h5" fontWeight="bold">
                Information about the processed personal data
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium">
                Using the App requires the processing of your personal data. Below you will find
                detailed information about the purposes and legal bases of processing, as well as
                the period of processing and the obligation or voluntariness to provide them.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="column" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Conclusion and performance of the Agreement for the provision of the
                          Account Service
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) name and surname
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          2) e-mail address
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          3)telephone number
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          4) address of residence/business activity (street, house number, apartment
                          number, city, postal code, country)
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          5)optionally - company and NIP (if the Buyer is an Entrepreneur or
                          Entrepreneur with consumer rights)
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(b) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary for the performance of the MyDappWallet Service
                          with the data subject or to take steps to conclude it)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is a condition for the
                          conclusion and performance of the contract for the provision of the
                          Account Service (their provision is voluntary, but the consequence of not
                          providing them will be the inability to conclude and perform the
                          above-mentioned contract, including the creation of an Account).
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data until the
                          claims arising from the Agreement for the provision of the Account Service
                          expire.
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Conclusion and performance of the MyDappWallet Service
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) e-mail address
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          2) telephone number
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(b) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary for the performance of the Account Service
                          Agreement concluded with the data subject or to take steps to conclude it)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is a condition for the
                          conclusion and performance of the MyDappWallet Service (their provision is
                          voluntary, but the consequence of not providing them will be the inability
                          to conclude and perform the MyDappWallet Service).
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data until the
                          claims arising from the Agreement for the provision of the Account Service
                          expire.
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Conclusion and performance of the Newsletter Agreement
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) e-mail address
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(b) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary for the performance of the Newsletter
                          Subscription Agreement concluded with the data subject or to take action
                          to conclude it)
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          and
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(f) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary to implement the legitimate interest of the
                          Controller, in this case informing about new services and promotions
                          available in the App)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is voluntary, but necessary to
                          receive the Newsletter (the consequence of not providing them will be the
                          inability to receive the Newsletter.
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data, the time of
                          effective objection or achievement of the purpose of processing or until
                          the claims arising from the Newsletter Subscription Agreement are
                          time-barred (whichever occurs first).
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Conducting a complaint procedure
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) name and surname
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          2) e-mail address
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(c) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary to fulfill a legal obligation incumbent on the
                          Controller, in this case the obligations of:
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          - responding to a complaint – Article 7a of the Act on consumer rights;
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          - exercise the Client&apos;s rights resulting from the provisions on the
                          Controller&apos;s liability in the event of non-compliance of the the
                          Subject of Digital Services with the Agreement concerning it)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is a condition for receiving a
                          response to the complaint or exercising the Client&apos;s rights under the
                          provisions on the Controller&apos;s liability in the event of
                          non-compliance of the Subject of Digital Services with the Agreement
                          concerning it (their application is voluntary, but the consequence of not
                          providing them will be the inability to receive a response to the
                          complaint and the implementation of the above-mentioned rights).
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data for the
                          duration of the complaint procedure, and in the case of the implementation
                          of the above-mentioned rights of the Client – until they expire.
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Sending email notifications
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) e-mail address
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(f) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary in order to implement the legitimate interest of
                          the Controller, in this case informing Clients about the actions taken
                          related to the performance of agreements concluded with Clients)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data voluntary, but necessary to
                          receive information about activities related to the performance of
                          agreements concluded with Clients (the consequence of not providing them
                          will be the inability to receive the above-mentioned information).
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data until the
                          objection is effectively raised or the purpose of processing is achieved
                          (whichever occurs first).
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Handling inquiries submitted by Clients
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) name
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          2) e-mail address
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          3) other data contained in the message to the Controller
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(f) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary to implement the legitimate interest of the
                          Controller, in this case to respond to the received inquiry)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is voluntary, but necessary to
                          receive an answer to the inquiry (the consequence of not providing them
                          will be the inability to receive an answer).
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data until the
                          objection is effectively raised or the purpose of processing is achieved
                          (whichever occurs first).
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Sharing Reviews on Services
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) name
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          2) optionally – other data contained in the Review
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(f) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary to implement the legitimate interest of the
                          Controller, in this case providing the Review for information and
                          promotional purposes)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is voluntary, but necessary to
                          add a Review (the consequence of not providing them will be the inability
                          to add a Review).
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data until the
                          objection is effectively raised or the purpose of processing is achieved
                          (whichever occurs first).
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Compliance with tax obligations (m.in issuing a VAT invoice, storing
                          accounting documentation)
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) Name and surname/company
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          2) address of residence/registered office
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          3) tax identification number
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(c) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary to fulfill the legal obligation incumbent on the
                          Controller, in this case obligations arising from tax law)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is voluntary, but necessary
                          for the Controller to meet its tax obligations (the consequence of not
                          providing them will be the Controller&apos;s inability to meet the
                          above-mentioned obligations).
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data for a period
                          of 5 years from the end of the year in which the tax payment deadline for
                          the previous year expired.
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Fulfillment of obligations related to the protection of personal data
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) Name and surname
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          2) contact details provided by you (e-mail address; correspondence
                          address; telephone number)
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(c) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary to fulfill the legal obligation incumbent on the
                          Controller, in this case the obligations arising from the provisions on
                          the protection of personal data)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is voluntary, but necessary
                          for the Controller to properly perform the obligations arising from the
                          provisions on the protection of personal data, m.in. exercise the rights
                          granted to you by the GDPR (the consequence of not providing the
                          above-mentioned data will be the inability to properly implement the
                          above-mentioned rights).
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data until the
                          expiry of the limitation periods for claims for violation of the
                          provisions on the protection of personal data.
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Establishing, investigating, or defending against claims
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) Name and surname/company
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          2) e-mail address
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          3) address of residence/registered office
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          4) PESEL number
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          5) Tax identification number
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(f) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary to implement the legitimate interest of the
                          Controller, in this case to establish, investigate or defend against
                          claims that may arise in connection with the performance of Contracts
                          concluded with the Controller)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is voluntary, but necessary to
                          establish, investigate or defend against claims that may arise in
                          connection with the performance of the agreements concluded with the
                          Controller (the consequence of not providing the above-mentioned data will
                          be the inability of the Controller to take the above-mentioned actions)
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data until the
                          expiry of the limitation periods for claims that may arise in connection
                          with the performance of the agreements concluded with the Controller.
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Analysis of your activity in the App
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) date and time of visit
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          2) IP number of the device
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          3) device operating system type
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          4) approximate location
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          5) type of web browser
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          6) time spent in the App
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          7) visited subpages and other activities undertaken as part of the App
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(f) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary to implement the legitimate interest of the
                          Controller, in this case obtaining information about your activity in the
                          App)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is voluntary, but necessary
                          for the Controller to obtain information about your activity in the App
                          (the consequence of not providing them will be the Controller&apos;s
                          inability to obtain the above-mentioned information).
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data until the
                          effective objection is raised or the purpose of processing is achieved.
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        Purpose of processing
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        Personal data processed
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        Legal basis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          App administration
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          1) IP address
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          2) server date and time
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          3) web browser information
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          4) operating system information
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The above data is saved automatically in the so-called server logs, each
                          time you use the App (administering it without using server logs and
                          automatic saving would not be possible).
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Article 6(1)(f) of the GDPR
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          (processing is necessary to implement the legitimate interest of the
                          Controller, in this case to ensure the proper operation of the App)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell scope="row" align="left" colSpan={3}>
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Providing the above-mentioned personal data is voluntary, but necessary to
                          ensure the proper operation of the App (the consequence of not providing
                          them will be the inability to ensure the operation of the App in a proper
                          manner).
                        </SuiTypography>
                        <SuiTypography variant="inherit" fontWeight="medium" mt={2}>
                          The Controller will process the above-mentioned personal data until the
                          effective objection is raised or the purpose of processing is achieved.
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox mt={5}>
              <SuiTypography variant="h5" fontWeight="bold">
                Profiling
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium">
                To create your profile for marketing purposes and direct marketing tailored to your
                preferences, the Controller will process your personal data in an automated manner,
                including profiling them – however, this will not cause any legal effects for you or
                similarly significantly affect your situation.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2}>
              <SuiTypography variant="inherit" fontWeight="medium">
                The scope of profiled personal data corresponds to the scope indicated above in
                relation to the analysis of your activity in the App and the data you save on the
                Account.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2}>
              <SuiTypography variant="inherit" fontWeight="medium">
                The legal basis for the processing of personal data for the above purpose is art. 6
                par. 1 lit. f GDPR, according to which the Controller may process personal data to
                implement his legitimate interest, in this case to conduct marketing activities
                tailored to the preferences of recipients. Providing the above-mentioned personal
                data is voluntary, but necessary to achieve the above-mentioned purpose (the
                consequence of not providing them will be the inability of the Controller to conduct
                marketing activities tailored to the preferences of recipients).
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2}>
              <SuiTypography variant="inherit" fontWeight="medium">
                The Controller will process personal data for the purpose of profiling until an
                objection is effectively raised or the purpose of processing is achieved.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={5}>
              <SuiTypography variant="h5" fontWeight="bold">
                Recipients of personal data
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium">
                The recipients of personal data will be the following external entities cooperating
                with the Controller:
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                1) hosting company;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                2) providers of online payment systems;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                3) newsletter service provider;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                4) companies providing tools for analyzing activity in the App and directing direct
                marketing to people using it (m.in. Google Analytics);
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                4) companies providing tools for analyzing activity in the App and directing direct
                marketing to people using it (m.in. Google Analytics);
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                5) a company providing accounting services;
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2}>
              <SuiTypography variant="inherit" fontWeight="medium">
                In addition, personal data may also be transferred to public or private entities, if
                such an obligation results from generally applicable law, a final court judgment or
                a final administrative decision.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={5}>
              <SuiTypography variant="h5" fontWeight="bold">
                Transfer of personal data to a third country
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium" mb={2}>
                In connection with the Controller&apos;s use of services provided by Google LLC,
                your personal data may be transferred to the following third countries: Great
                Britain, Canada, USA, Chile, Brazil, Israel, Saudi Arabia, Qatar, India, China,
                South Korea, Japan, Singapore, Taiwan (Republic of China), Indonesia and Australia.
                The basis for the transfer of data to the above-mentioned third countries are:
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                1) in the case of the United Kingdom, Canada, Israel and Japan - decisions of the
                European Commission stating an adequate level of protection of personal data in each
                of the above-mentioned third countries;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                2) for the USA, Chile, Brazil, Saudi Arabia, Qatar, India, China, South Korea,
                Singapore, Taiwan (Republic of China), Indonesia and Australia, contractual clauses
                ensuring an adequate level of protection, in accordance with the standard
                contractual clauses set out in Commission Implementing Decision (EU) 2021/914 of 4
                June 2021 on standard contractual clauses for the transfer of personal data to third
                countries pursuant to a Regulation of the European Parliament and of the Council
                (EU) 2016/679.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2}>
              <SuiTypography variant="inherit" fontWeight="medium">
                You can obtain from the Controller a copy of the data transferred to a third
                country.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={5}>
              <SuiTypography variant="h5" fontWeight="bold">
                Your rights
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium" mb={2}>
                In connection with the processing of personal data, you have the following rights:
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                1) the right to information about what personal data concerning you is processed by
                the Controller and to receive a copy of this data (the so-called right of access).
                Issuing the first copy of the data is free, for the next one the Controller may
                charge a fee;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                2) if the processed data becomes outdated or incomplete (or otherwise incorrect),
                you have the right to request its rectification;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                3) in certain situations, you can ask the Controller to delete your personal data,
                e.g. when:
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium" pl={3}>
                a) the data will no longer be needed by the Controller for the purposes of which he
                informed;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium" pl={3}>
                b) you have effectively withdrawn your consent to the processing of data - unless
                the Controller has the right to process data on another legal basis;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium" pl={3}>
                c) the processing is unlawful;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium" pl={3}>
                d) the need to delete data results from the Controller&apos;s legal obligation.
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                4) if personal data are processed by the Controller on the basis of consent to
                processing or for the purpose of performing the Agreement concluded with him, you
                have the right to transfer your data to another Controller;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                5) if personal data is processed by the Controller on the basis of your consent to
                processing, you have the right to withdraw this consent at any time (withdrawal of
                consent does not affect the lawfulness of processing that was made on the basis of
                consent before its withdrawal);
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                6) if you consider that the processed personal data is incorrect, their processing
                is unlawful, or the Controller no longer needs certain data, you can request that
                for a specific, necessary time (e.g. checking the correctness of data or pursuing
                claims) the Controller does not perform any operations on the data, but only Apps
                them;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                7) you have the right to object to the processing of personal data whose basis for
                processing is the legitimate interest of the Controller. In the event of an
                effective objection, the Controller will cease to process personal data for the
                above-mentioned purpose;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                8) you have the right to lodge a complaint with the President of the Office for
                Personal Data Protection if you believe that the processing of personal data
                violates the provisions of the GDPR.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={5}>
              <SuiTypography variant="h5" fontWeight="bold">
                Cookies
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium">
                1) The Controller informs that the App uses &quot;cookies&quot; installed on your
                end device. These are small text files that can be read by the Controller&quot;s
                system, as well as by systems belonging to other entities whose services are used by
                the Controller (e.g. Facebook, Google).
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                2) The Controller uses cookies for the following purposes:
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium" pl={3}>
                a) ensuring the proper operation of the App – thanks to cookies, it is possible to
                operate efficiently the App, use its functions and conveniently move between
                individual subpages;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium" pl={3}>
                b) increasing the comfort of browsing the App – thanks to cookies, it is possible to
                detect errors on some subpages and their constant improvement;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium" pl={3}>
                c) creating statistics – cookies are used to analyze how users use the App. Thanks
                to this, it is possible to constantly improve the App and adapt its operation to the
                preferences of users;
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium" pl={3}>
                d) conducting marketing activities – thanks to cookies, the Controller may direct
                advertisements tailored to users&apos; preferences.
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                3) Information about cookies used by the Controller is displayed in the panel
                located at the bottom of the App&apos;s website. Depending on your decision, you can
                enable or disable cookies of individual categories (except for necessary cookies)
                and change these settings at any time.
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                4) Data collected using cookies do not allow the Controller to identify you.
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium">
                5) The Controller uses the following cookies or tools using them:
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead color="info">
                    <TableRow>
                      <StyledTableCell size="small" align="center" variant="head">
                        TOOL
                      </StyledTableCell>
                      <StyledTableCell align="center" variant="head">
                        SUPPLIER
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        FUNCTIONS AND SCOPE OF DOWNLOADED DATA
                      </StyledTableCell>
                      <StyledTableCell size="small" align="center" variant="head">
                        PERIOD OF OPERATION+
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Necessary cookies
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Controller
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          The operation of these files is necessary for the proper functioning of
                          the App, so you can not disable them. Thanks to these files (collecting,
                          m.in. the IP number of your device), it is possible, m.in. to inform you
                          about cookies operating on the App
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          most of the necessary cookies are session cookies, but some remain on your
                          device for a period of 12 months or until they are deleted
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Google Analytics
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Google
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          This tool allows you to collect statistical data on how users use the App,
                          m.in. the number of visits, the duration of visits, the search engine
                          used, location. The collected data helps to improve the App and make it
                          more friendly to customers.
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          up to 2 years or until they are removed (whichever occurs first)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: "top",
                      }}
                    >
                      <TableCell scope="row" align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Facebook Pixel
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          Facebook
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          This tool also allows you to determine that you have visited the App, to
                          direct ads displayed on Facebook and Instagram social networks to you and
                          measure their effectiveness.
                        </SuiTypography>
                      </TableCell>
                      <TableCell align="left">
                        <SuiTypography variant="inherit" fontWeight="medium">
                          up to 3 months or until they are removed (whichever occurs first)
                        </SuiTypography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="inherit" fontWeight="medium" mt={3}>
                6) Through most used browsers, you can check whether cookies have been installed on
                your end device, as well as delete installed cookies and block them from being
                installed by the App in the future. Disabling or limiting the use of cookies may,
                however, cause quite serious difficulties in using the App, e.g. in the form of the
                need to log in to each subpage, a longer loading period of the App’s restrictions on
                the use of certain functionalities.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={5}>
              <SuiTypography variant="h5" fontWeight="bold">
                Final provisions
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography variant="inherit" fontWeight="medium">
                To the extent not regulated by the Policy, generally applicable provisions on the
                protection of personal data shall apply.
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2}>
              <SuiTypography variant="inherit" fontWeight="medium">
                This policy is effective from 2023-10-01.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Card>
      </CoverLayout>
    </>
  );
}

Privacy.propTypes = {};

export default Privacy;
