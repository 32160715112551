/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/style-prop-object */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiTokenIconRoot from "components/sui/SuiTokenIcon/SuiTokenIconRoot";
// Custom styles for the SuiAlert

import Jazzicon from "react-jazzicon";

function jsNumberForAddress(address) {
  const addr = address.slice(2, 10);
  const seed = parseInt(addr, 16);
  return seed;
}

const SuiTokenIcon = forwardRef(({ address, ...rest }, ref) => {
  const seed = jsNumberForAddress(address);
  return (
    <SuiTokenIconRoot {...rest} ref={ref}>
      <Jazzicon diameter={46} seed={seed} paperStyles={{ width: 36, height: 36 }} />
    </SuiTokenIconRoot>
  );
});

// Typechecking props for the SuiTokenIcon
SuiTokenIcon.propTypes = {
  address: PropTypes.string.isRequired,
};

export default SuiTokenIcon;
