import { combineReducers } from "redux";
import appReducer from "reducers/appReducer";
import web3Reducer from "reducers/web3Reducer";

const rootReducer = combineReducers({
  appStore: appReducer,
  web3Store: web3Reducer,
});

export default rootReducer;
