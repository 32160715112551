/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

function SuiNavbarLink({
  name,
  openHandler,
  closeHandler,
  open,
  children,
  collapseStatus,
  light,
  click,
  ...rest
}) {
  return (
    <>
      <SuiBox
        {...rest}
        pl={1}
        // eslint-disable-next-line no-nested-ternary
        onMouseEnter={children ? undefined : click ? undefined : openHandler}
        // eslint-disable-next-line no-nested-ternary
        onMouseMove={children ? undefined : click ? undefined : openHandler}
        // eslint-disable-next-line no-nested-ternary
        onClick={children ? undefined : !click ? undefined : openHandler}
        display="inline-block"
        alignItems="baseline"
        color={light ? "white" : "dark"}
        sx={{ cursor: "pointer", userSelect: "none", fontWeight: 400 }}
      >
        {name}
        <SuiTypography variant="body2" color={light ? "white" : "dark"} display="inline-block">
          <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>
            {open ? "keyboard_arrow_down" : "keyboard_arrow_right"}
          </Icon>
        </SuiTypography>
      </SuiBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of DefaultNavbarLink
SuiNavbarLink.defaultProps = {
  openHandler: false,
  closeHandler: false,
  open: false,
  children: false,
  collapseStatus: false,
  light: false,
  click: false,
};

// Typechecking props for the DefaultNavbarLink
SuiNavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  openHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  closeHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  open: PropTypes.bool,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  click: PropTypes.bool,
};

export default SuiNavbarLink;
