/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-multi-assign */
import ActionTypes from "constants/ActionTypes";

import listItem from "components/layout/Sidenav/listItem";

const initialState = {
  loading: false,
  initialization: false,
  error: undefined,
  info: undefined,
  chains: {},
  assets: {},
  settings: {},
  exchangeRates: {},
  balance: {},
  gasPrice: {},
  balanceInFiat: {},
  balanceFormatted: {},
  tokens: [],
  addressBook: [],
  searchData: listItem,
  showHiddenWallet: true,
  selected: true,
  hiddenItem: "0",
  buySellTransaction: false,
  errors: {},
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_INIT_START:
      return {
        ...state,
        loading: true,
        initialization: true,
        error: undefined,
      };

    case ActionTypes.APP_INIT_SUCCESS:
      return {
        ...state,
        chains: action.payload.chains,
        assets: action.payload.assets,
        exchangeRates: action.payload.exchangeRates,
        settings: action.payload.settings,
        loading: false,
        initialization: false,
      };

    case ActionTypes.APP_INIT_FAILED:
      return {
        ...state,
        loading: false,
        initialization: false,
        error: action.payload.error,
      };

    case ActionTypes.AUTH_SIGN_OUT:
      return {
        ...state,
        initialazed: false,
      };
    case ActionTypes.APP_LOADING_START:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.APP_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.APP_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    case ActionTypes.APP_ERROR_CLOSE:
      return {
        ...state,
        error: undefined,
      };

    case ActionTypes.APP_INFO:
      return {
        ...state,
        info: action.payload.info,
      };

    case ActionTypes.APP_INFO_CLOSE:
      return {
        ...state,
        info: undefined,
      };
    case ActionTypes.APP_CHANGE_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };

    case ActionTypes.APP_BALANCE_SUCCESS:
      return {
        ...state,
        balance: {
          ...state.balance,
          [action.payload.name]: action.payload.balance,
        },
        balanceFormatted: {
          ...state.balanceFormatted,
          [action.payload.name]: action.payload.balanceFormatted,
        },
        balanceInFiat: {
          ...state.balanceInFiat,
          [action.payload.name]: action.payload.balanceInFiat,
        },
      };

    case ActionTypes.APP_EXCHANGE_RATES_SUCCESS:
      return {
        ...state,
        exchangeRates: action.payload.exchangeRates,
      };

    case ActionTypes.APP_GAS_PRICE:
      return {
        ...state,
        gasPrice: {
          ...state.gasPrice,
          [action.payload.name]: action.payload.gasPrice,
        },
      };

    case ActionTypes.APP_TOKENS:
      return {
        ...state,
        tokens: action.payload.tokens,
      };

    case ActionTypes.APP_TOKEN_ADD:
      return {
        ...state,
        tokens: state.tokens.concat([action.payload.token]),
      };

    case ActionTypes.APP_TOKEN_DELETE:
      return {
        ...state,
        tokens: state.tokens.filter((item) => item.address !== action.payload.address),
      };

    case ActionTypes.APP_ADDRESSBOOK_SUCCESS:
      return {
        ...state,
        addressBook: action.payload.addressBook,
      };
    case ActionTypes.APP_ADDRESSBOOK_ADD:
      return {
        ...state,
        addressBook: state.addressBook.concat([
          { name: action.payload.name, address: action.payload.address },
        ]),
      };

    case ActionTypes.APP_ADDRESSBOOK_EDIT:
      return {
        ...state,
        addressBook: state.addressBook.map((a) =>
          a.address !== action.payload.address
            ? a
            : { name: action.payload.name, address: action.payload.address }
        ),
      };
    case ActionTypes.APP_ADDRESSBOOK_DELETE:
      return {
        ...state,
        addressBook: state.addressBook.filter((item) => item.address !== action.payload.address),
      };
    case ActionTypes.LIST:
      return {
        ...state,
        searchData: action.payload,
      };
    case ActionTypes.HIDDENLIST:
      return {
        ...state,
        showHiddenWallet: action.payload,
      };
    case ActionTypes.HIDDENITEM:
      return {
        ...state,
        hiddenItem: action.payload,
      };
    case ActionTypes.SELECTED:
      return {
        ...state,
        selected: action.payload,
      };

    case ActionTypes.APP_BUYSELL_TRANSATION:
      return {
        ...state,
        buySellTransaction: action.payload.transaction,
      };

    case ActionTypes.APP_BUYSELL_TRANSATION_CLEAR:
      return {
        ...state,
        buySellTransaction: false,
      };

    default:
      return state;
  }
}
