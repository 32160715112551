/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
// prettier-ignore
const Jsonrpc = require('./lib/jsonrpc.js');
const { errors } = require("web3-core-helpers");
const XHR2 = require("xhr2-cookies").XMLHttpRequest; // jshint ignore: line
const http = require("http");
const https = require("https");
const Web3 = require("web3");
const MyDappWalletWeb3Provider = require("mydappwallet/web3/provider");
const PSD2Provider = require("mydappwallet/psd2/provider");
const formatter = require("./lib/formatters");
// const MyDappWalletWeb3 = require("mydappwallet/web3/web3");
// const Method = require("web3-core-method");
// var abi = require('web3-eth-abi');

function setCrossSubdomainCookie(name, value, days) {
  const assign = `${name}=${escape(value)};`;
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()};`;
  const path = "path=/;";
  const domain = `domain=${document.domain.replace(/^[^.]+\./g, "")};`;
  document.cookie = assign + expires + path + domain;
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const MyDappWalletManager = function MyDappWalletManager(host, apiKey, options, callback) {
  this.options = options;
  this.withCredentials = options.withCredentials || false;
  this.timeout = options.timeout || 0;
  this.headers = options.headers;
  this.apiKey = apiKey;
  this.sessionId = 0;
  this.user = undefined;
  this.settings = {};
  this.agent = options.agent;
  this.redirect = options.redirect;
  this.connected = false;
  this.nextId = 42;
  // keepAlive is true unless explicitly set to false
  const keepAlive = options.keepAlive !== false;
  this.host = host || "http://localhost:8545";
  if (!this.agent) {
    if (this.host.substring(0, 5) === "https") {
      this.httpsAgent = new https.Agent({ keepAlive });
    } else {
      this.httpAgent = new http.Agent({ keepAlive });
    }
  }
  this.init(callback);
  this.method = this.method.bind(this);

  this.method("sign_in2");
  this.method("sign_up");
  this.method("sign_up2");
  this.method("forgot_password");
  this.method("reset_password");
  this.method("reset_password2");
  this.method("change_password");
  this.method("reflink");
  this.method("chains");
  this.method("assets");
  this.method("app_list");
  this.method("app");
  this.method("app_add");
  this.method("app_edit");
  this.method("contract");
  this.method("contract_add");
  this.method("contract_edit");
  this.method("contract_delete");
  this.method("transaction");
  this.method("transaction_list");
  this.method("broker_transacion");
  this.method("pay");
  this.method("buy");
  this.method("payment");
  this.method("confirm");
  this.method("reject");
  this.method("user_machine_list");
  this.method("user_machine_delete");
  this.method("authenticator_list");
  this.method("authenticator_edit");
  this.method("authenticator_edit2");
  this.method("authenticator_disable");
  this.method("authenticator_enable");
  this.method("authenticator_enable2");
  this.method("authenticator_enable3");

  this.method("authenticator_confirm");

  this.method("profile");
  this.method("profile_edit");
  this.method("book_add");
  this.method("book_delete");
  this.method("book_edit");
  this.method("book_list");
  this.method("token_list");
  this.method("token_add");
  this.method("token_delete");
  this.method("exchange_rates");
};

MyDappWalletManager.prototype.init = function (callback) {
  const _this = this;
  // this.sessionId = window.localStorage.getItem("SessionId");
  // this.machineId = window.localStorage.getItem("MachineId");
  this.sessionId = getCookie("SessionId");
  this.machineId = getCookie("MachineId");
  if (this.sessionId) {
    const payload = { jsonrpc: "2.0", id: _this.nextId++, method: "init" };
    this.send(
      payload,
      _this._jsonrpcResultCallback((error, result) => {
        if (result) {
          _this.sessionId = result.sessionId;
          _this.wallet = result.wallet;
          _this.user = result.user;
          _this.settings = result.settings;
        } else {
          _this.sessionId = undefined;
          _this.wallet = undefined;
          _this.user = undefined;
          _this.settings = {};
          setCrossSubdomainCookie("SessionId", undefined, 0);
        }
        window.setTimeout(() => {
          callback(error, result);
        }, 0);
      }, payload)
    );
  } else {
    window.setTimeout(() => {
      callback("session not found");
    }, 0);
  }
};

MyDappWalletManager.prototype.sign_in = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter({ ...params, machineId: _this.machineId });
  const payload = { jsonrpc: "2.0", id: _this.nextId++, method: "sign_in", params: [params] };
  this.send(
    payload,
    _this._jsonrpcResultCallback((error, result) => {
      if (!error) {
        if (result.status === 1) {
          _this.sessionId = result.sessionId;
          _this.wallet = result.wallet;
          _this.user = result.user;
          _this.settings = result.settings;
          //window.localStorage.setItem("SessionId", result.sessionId);
          setCrossSubdomainCookie("SessionId", result.sessionId, 1);
        }
        setCrossSubdomainCookie("MachineId", result.machineId, 365);
        // window.localStorage.setItem("MachineId", result.machineId);
      }
      callback(error, result);
    }, payload)
  );
  return true;
};

MyDappWalletManager.prototype.sign_in3 = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  const payload = { jsonrpc: "2.0", id: _this.nextId++, method: "sign_in3", params: [params] };
  this.send(
    { jsonrpc: "2.0", method: "sign_in3", params: [params] },
    _this._jsonrpcResultCallback((error, result) => {
      if (!error) {
        if (result.status === 1) {
          _this.sessionId = result.sessionId;
          _this.machineId = result.machineId;
          _this.wallet = result.wallet;
          _this.user = result.user;
          _this.settings = result.settings;
          // window.localStorage.setItem("SessionId", result.sessionId);
          // window.localStorage.setItem("MachineId", result.machineId);
          setCrossSubdomainCookie("SessionId", result.sessionId, 1);
          setCrossSubdomainCookie("MachineId", result.machineId, 365);
        }
      }
      callback(error, result);
    })
  );
  return true;
};

MyDappWalletManager.prototype.sign_out = function (callback) {
  const _this = this;
  const payload = { jsonrpc: "2.0", id: _this.nextId++, method: "logout" };
  this.send(
    payload,
    _this._jsonrpcResultCallback((error, result) => {
      _this.sessionId = undefined;
      _this.user = undefined;
      window.localStorage.removeItem("SessionId");
      if (callback) callback(error, result);
    }, payload)
  );
  return true;
};

MyDappWalletManager.prototype.sign_up3 = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  const payload = { jsonrpc: "2.0", id: _this.nextId++, method: "sign_up3", params: [params] };
  this.send(
    payload,
    _this._jsonrpcResultCallback((error, result, info) => {
      if (!error) {
        _this.sessionId = result.sessionId;
        _this.wallet = result.wallet;
        _this.user = result.user;
        _this.settings = result.settings;
        // window.localStorage.setItem("SessionId", result.sessionId);
        setCrossSubdomainCookie("SessionId", result.sessionId, 1);
      }
      callback(error, result, info);
    }, payload)
  );
  return true;
};

MyDappWalletManager.prototype.change_settings = function (params, callback) {
  const _this = this;
  const payload = {
    jsonrpc: "2.0",
    id: _this.nextId++,
    method: "change_settings",
    params: [params],
  };
  this.send(
    payload,
    _this._jsonrpcResultCallback((error, result) => {
      _this.settings = params;
      if (callback) callback(error, result);
    }, payload)
  );
  return true;
};

MyDappWalletManager.prototype.authenticator = function (callback) {
  const _this = this;
  const payload = { jsonrpc: "2.0", id: _this.nextId++, method: "authenticator" };
  this.send(
    payload,
    _this._jsonrpcResultCallback((error, result) => {
      if (error) {
        this.user.newAuthenticator = false;
      }
      callback(error, result);
    }, payload)
  );
  return true;
};

MyDappWalletManager.prototype.authenticator_confirm = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);

  const payload = {
    jsonrpc: "2.0",
    id: _this.nextId++,
    method: "authenticator_confirm",
    params: [params],
  };
  this.send(
    payload,
    _this._jsonrpcResultCallback((error, result) => {
      if (callback) callback(error, result);
    }, payload)
  );

  return true;
};

MyDappWalletManager.prototype.method = function (name) {
  this[name] = function (params, callback) {
    const _this = this;
    params = formatter.hexParamsFormatter(params);

    const payload = { jsonrpc: "2.0", id: _this.nextId++, method: name, params: [params] };
    this.send(
      payload,
      _this._jsonrpcResultCallback((error, result) => {
        if (callback) callback(error, result);
      }, payload)
    );
    return true;
  };
};

MyDappWalletManager.prototype.event = function (event, err, result) {
  if (result && result.info) {
    event.info = result.info;
  }
  if (err) {
    event.error = err;
  } else if (result && result.error) {
    event.error = result.error;
  } else if (!Jsonrpc.isValidResponse(result)) {
    return (event.error = errors.InvalidResponse(result));
  } else {
    event.result = result.result;
  }

  switch (event.target) {
    case "mdw-inpage":
      if (window.opener) {
        window.opener.postMessage(event, "*");
      }

      break;
  }
};

MyDappWalletManager.prototype.web3 = function (host) {
  const provider = new MyDappWalletWeb3Provider(host, this);
  const web3 = new Web3(provider);
  return web3;
};

MyDappWalletManager.prototype.psd2 = function (host) {
  const provider = new PSD2Provider(host, this, this.options);
  return provider;
};

MyDappWalletManager.prototype._prepareRequest = function () {
  let request;
  // the current runtime is a browser
  if (typeof XMLHttpRequest !== "undefined") {
    request = new XMLHttpRequest();
  } else {
    request = new XHR2();
    const agents = {
      httpsAgent: this.httpsAgent,
      httpAgent: this.httpAgent,
      baseUrl: this.baseUrl,
    };
    if (this.agent) {
      agents.httpsAgent = this.agent.https;
      agents.httpAgent = this.agent.http;
      agents.baseUrl = this.agent.baseUrl;
    }
    request.nodejsSet(agents);
  }

  request.open("POST", this.host, true);
  request.setRequestHeader("ApiKey", this.apiKey);
  if (this.sessionId) request.setRequestHeader("SessionId", this.sessionId);
  request.setRequestHeader("Content-Type", "application/json");
  //
  request.timeout = this.timeout;
  request.withCredentials = this.withCredentials;
  if (this.headers) {
    this.headers.forEach((header) => {
      request.setRequestHeader(header.name, header.value);
    });
  }
  return request;
};

MyDappWalletManager.prototype.send = function (payload, callback) {
  const _this = this;
  const request = this._prepareRequest();
  request.onreadystatechange = function () {
    if (request.readyState === 4 && request.timeout !== 1) {
      let result = request.responseText;
      let error = null;
      try {
        result = JSON.parse(result);
      } catch (e) {
        error = _this.InvalidResponse(request.responseText);
      }
      _this.connected = true;
      if (result.event) {
        _this.event(result.event, error, result);
      }
      callback(error, result);
    }
  };
  request.ontimeout = function () {
    _this.connected = false;
    callback(errors.ConnectionTimeout(this.timeout));
  };
  try {
    request.send(JSON.stringify(payload));
  } catch (error) {
    this.connected = false;
    callback(errors.InvalidConnection(this.host));
  }
};

// eslint-disable-next-line no-underscore-dangle
MyDappWalletManager.prototype._jsonrpcResultCallback = function (callback, payload) {
  const _this = this;
  return function (err, result) {
    if (result && result.id && payload.id !== result.id) {
      return callback(
        new Error(
          `Wrong response id ${result.id} (expected: ${payload.id}) in ${JSON.stringify(payload)}`
        )
      );
    }
    if (err) {
      return callback(err);
    }
    if (result && result.error) {
      return callback(_this.ErrorResponse(result));
    }
    if (!Jsonrpc.isValidResponse(result)) {
      return callback(errors.InvalidResponse(result));
    }
    callback(null, result.result, result.info);
  };
};

MyDappWalletManager.prototype.InvalidResponse = function (result) {
  const message =
    !!result && !!result.error && !!result.error.message
      ? result.error.message
      : `Invalid JSON RPC response: ${JSON.stringify(result)}`;
  const err = new Error(message);
  const code = !!result.error && !!result.error.code ? result.error.code : 500;
  err.code = code;
  return err;
};

MyDappWalletManager.prototype.ErrorResponse = function (result) {
  const message =
    !!result && !!result.error && !!result.error.message
      ? result.error.message
      : JSON.stringify(result);
  const data = !!result.error && !!result.error.data ? result.error.data : null;
  const err = new Error(message);
  err.data = data;
  const code = !!result.error && !!result.error.code ? result.error.code : null;
  err.code = code;
  return err;
};

module.exports = MyDappWalletManager;
