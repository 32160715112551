/* eslint-disable no-shadow */
import { useContext } from "react";
import { ReactReduxContext } from "react-redux";
import ActionTypes from "constants/ActionTypes";
import Constants from "constants/Constants";

const { TRANSACTION_STATUSES } = Constants;
const ABIERC20 = require("erc-20-abi");

export function loadingOn() {
  return {
    type: ActionTypes.APP_LOADING_START,
  };
}

export function loadingOff() {
  return {
    type: ActionTypes.APP_LOADING_STOP,
  };
}

export function error(_error) {
  return {
    type: ActionTypes.APP_ERROR,
    payload: {
      error: _error,
    },
  };
}

export function errorClose() {
  return {
    type: ActionTypes.APP_ERROR_CLOSE,
  };
}

export function info(_info) {
  return {
    type: ActionTypes.APP_INFO,
    payload: {
      info: _info,
    },
  };
}

export function infoClose() {
  return {
    type: ActionTypes.APP_INFO_CLOSE,
  };
}

export function refLink(values, callback) {
  const { mydappwallet } = window;
  mydappwallet.reflink(values, callback);
  return {
    type: ActionTypes.APP_REFLINK,
  };
}

export function changeSettings(settings, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.change_settings(settings, (error) => {
      dispatch({
        type: ActionTypes.APP_CHANGE_SETTINGS,
        payload: {
          settings,
        },
      });
      callback(error, settings);
    });
  };
}

export function changeSetting(name, value, callback) {
  return (dispatch, getState) => {
    const settings = { ...getState().appStore.settings };
    settings[name] = value;
    const { mydappwallet } = window;
    mydappwallet.change_settings(settings, (error) => {
      dispatch({
        type: ActionTypes.APP_CHANGE_SETTINGS,
        payload: {
          settings,
        },
      });
      if (callback) callback(error, settings);
    });
  };
}

export function balance(name) {
  return (dispatch, getState) => {
    try {
      const { mydappwallet } = window;
      // eslint-disable-next-line no-unused-vars
      const { assets } = getState().appStore;
      const crypto = getState().appStore.assets[name];
      const { defaultCurrency } = getState().appStore.settings;
      switch (crypto.type) {
        case "EVM":
          crypto.web3.eth.getBalance(mydappwallet.wallet.EVM, (_error, balance) => {
            if (balance) {
              let balanceInFiat = 0;
              const balanceFormatted = (balance / Constants.ETHER)
                .toFixed(10)
                .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");

              // eslint-disable-next-line no-unused-vars
              const exchangeRates = getState().appStore.exchangeRates.exchanges[name].price;
              if (exchangeRates) {
                balanceInFiat = (
                  (balance / Constants.ETHER) *
                  exchangeRates[defaultCurrency]
                ).toFixed(2);
              }

              dispatch({
                type: ActionTypes.APP_BALANCE_SUCCESS,
                payload: {
                  name,
                  balance,
                  balanceFormatted,
                  balanceInFiat,
                },
              });
            }
          });
          break;
        case "ERC20":
          crypto.erc20.methods.balanceOf(mydappwallet.wallet.EVM).call({}, (_error, balance) => {
            if (balance) {
              let balanceInFiat = 0;
              const balanceFormatted = (balance / crypto.decimals)
                .toFixed(10)
                .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");

              // eslint-disable-next-line no-unused-vars
              const exchangeRates = getState().appStore.exchangeRates.exchanges[name].price;
              if (exchangeRates) {
                balanceInFiat = (
                  (balance / crypto.decimals) *
                  exchangeRates[defaultCurrency]
                ).toFixed(2);
              }

              dispatch({
                type: ActionTypes.APP_BALANCE_SUCCESS,
                payload: {
                  name,
                  balance,
                  balanceFormatted,
                  balanceInFiat,
                },
              });
            }
          });
          break;
        case "IBAN":
          crypto.psd2.getBalance(crypto.iban, (_error, balance) => {
            const exchangeRates = getState().appStore.exchangeRates.exchanges[name].price;
            let balanceInFiat = balance;
            if (exchangeRates && defaultCurrency !== crypto.symbol) {
              balanceInFiat = (
                (balance / crypto.decimals) *
                exchangeRates[defaultCurrency]
              ).toFixed(2);
            }
            dispatch({
              type: ActionTypes.APP_BALANCE_SUCCESS,
              payload: {
                name,
                balance,
                balanceFormatted: balance,
                balanceInFiat,
              },
            });
          });
          break;
        default:
          break;
      }
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(JSON.stringify(err));
    }
  };
}

export function gasPrice(name, callback) {
  return (dispatch) => {
    switch (name) {
      case "ETH-ETH":
        fetch("https://api.owlracle.info/v4/eth/gas?apikey=b67abd93a044486d8d91892aaef122f4")
          .then((response) => response.json())
          .then((data) => {
            const gasPrice = data.speeds[0].maxFeePerGas;
            dispatch({
              type: ActionTypes.APP_GAS_PRICE,
              payload: {
                name,
                gasPrice,
              },
            });
            callback(null, gasPrice);
          })
          .catch(() => {
            dispatch({
              type: ActionTypes.APP_GAS_PRICE,
              payload: {
                name,
                gasPrice: 0,
              },
            });
            callback(null, 0);
          });

        break;
      case "U888-U888":
        dispatch({
          type: ActionTypes.APP_GAS_PRICE,
          payload: {
            name,
            gasPrice: 76,
          },
        });
        callback(undefined, 76);
        break;
      default:
        dispatch({
          type: ActionTypes.APP_GAS_PRICE,
          payload: {
            name,
            gasPrice: 76,
          },
        });
        callback(undefined, 76);
    }
  };
}

export function initStart() {
  return {
    type: ActionTypes.APP_INIT_START,
  };
}

export function initSuccess(assets, settings, exchangeRates) {
  return {
    type: ActionTypes.APP_INIT_SUCCESS,
    payload: {
      assets,
      settings,
      exchangeRates,
    },
  };
}

export function initFailed(error) {
  return {
    type: ActionTypes.APP_INIT_FAILED,
    payload: {
      error,
    },
  };
}

export function init(callback) {
  return (dispatch) => {
    try {
      dispatch(initStart());
      const { mydappwallet } = window;
      mydappwallet.chains({}, (error, chains) => {
        if (chains) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [, value] of Object.entries(chains)) {
            switch (value.type) {
              case "EVM":
                value.web3 = mydappwallet.web3(value.host);
                break;
              case "PSD2":
                value.psd2 = mydappwallet.psd2(value.host);
                break;
              default:
                break;
            }
          }
          mydappwallet.assets({}, (error, assets) => {
            if (assets) {
              // eslint-disable-next-line no-restricted-syntax
              for (const [, value] of Object.entries(assets)) {
                switch (value.type) {
                  case "EVM":
                    value.web3 = chains[value.chain].web3;
                    break;
                  case "ERC20":
                    value.erc20 = new chains[value.chain].web3.eth.Contract(
                      ABIERC20,
                      value.address
                    );
                    break;
                  case "IBAN":
                    value.psd2 = chains[value.chain].psd2;
                    break;
                  default:
                    break;
                }
              }
              mydappwallet.exchange_rates(
                { currency: mydappwallet.settings.defaultCurrency },
                (error, exchangeRates) => {
                  if (exchangeRates) {
                    const settings = { ...window.mydappwallet.settings };
                    if (!settings.sortingType) settings.sortingType = "RATING";
                    if (!settings.hiddenItems) settings.hiddenItems = [];
                    if (!settings.showZeroBalance) settings.showZeroBalance = true;
                    if (!settings.showTokens) settings.showTokens = true;
                    if (!settings.defaultCurrency) settings.defaultCurrency = "USD";
                    dispatch({
                      type: ActionTypes.APP_INIT_SUCCESS,
                      payload: {
                        chains,
                        assets,
                        exchangeRates,
                        settings,
                      },
                    });
                    if (mydappwallet.user) {
                      // eslint-disable-next-line no-restricted-syntax
                      for (const [key] of Object.entries(assets)) {
                        dispatch(balance(key));
                      }
                      callback({ chains, assets, exchangeRates });
                      return;
                    }
                  }
                  dispatch(initFailed(error));
                  callback({ chains, assets, exchangeRates }, error);
                }
              );
            }
          });
        }
        dispatch(initFailed(error));
      });
    } catch (err) {
      dispatch(initFailed(err));
      callback(err);
    }
  };
}

export function exchangeRatesSuccess(exchangeRates) {
  return {
    type: ActionTypes.APP_EXCHANGE_RATES_SUCCESS,
    payload: {
      exchangeRates,
    },
  };
}

export function exchangeRates(callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.exchange_rates({}, (error, result) => {
      if (result) {
        dispatch(exchangeRatesSuccess(result));
        callback(error, result);
      }
    });
  };
}

export function exchangeRate(crypto, currency) {
  // eslint-disable-next-line consistent-return
  const { store } = useContext(ReactReduxContext);
  const exchangeRates = store.appStore.exchangeRate[crypto];
  if (exchangeRates && exchangeRates[currency]) {
    return { symbol: currency, exchangeRate: exchangeRates[currency], type: "exchangeRate" };
  }
  return { symbol: currency, exchangeRate: 0, type: "exchangeRate" };
}

export function history(asset, adddres, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.transaction_list(
      { asset, adddres, minStatus: TRANSACTION_STATUSES.PENDING },
      (error, history) => {
        if (history) {
          dispatch({
            type: ActionTypes.APP_HISTORY_SUCCESS,
            payload: {
              asset,
              history,
            },
          });
        }
        callback(error, history);
      }
    );
  };
}

export function transactionSuccess(transaction) {
  return {
    type: ActionTypes.APP_TRANSACTION_SUCCESS,
    payload: {
      transaction,
    },
  };
}

export function transaction(id, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    let intervalId;
    mydappwallet.transaction({ id }, (error, result) => {
      if (result) {
        if (result.status === 1) {
          const interval = () => {
            mydappwallet.transaction({ id }, (error, result) => {
              if (result) {
                if (result.status !== 1) {
                  dispatch(transactionSuccess(result));

                  if (result.status === 3) dispatch(error(result.error));
                  if (result.status === 4) dispatch(info({ code: 3000, data: result }));
                  clearInterval(intervalId);
                }
              }
            });
          };
          intervalId = setInterval(interval, 1000);
        }
        dispatch(transactionSuccess(result));
      }
      callback(error, result);
    });
  };
}

export function tokens(network, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.token_list({ network }, (error, tokens) => {
      if (tokens) {
        dispatch({
          type: ActionTypes.APP_TOKENS,
          payload: {
            tokens,
          },
        });
      }
      if (callback) callback(error, tokens);
    });
  };
}

export function tokenAdd(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.token_add(values, (error, token) => {
      if (token) {
        dispatch({
          type: ActionTypes.APP_TOKEN_ADD,
          payload: {
            token,
          },
        });
      }
      callback(error, token);
    });
  };
}

export function tokenDelete(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.token_delete(values, (error, result) => {
      if (result) {
        dispatch({
          type: ActionTypes.APP_TOKEN_DELETE,
          payload: {
            address: values.address,
          },
        });
      }
      callback(error, result);
    });
  };
}

export function addressBookSuccess(type, addressBook) {
  return {
    type: ActionTypes.APP_ADDRESSBOOK_SUCCESS,
    payload: {
      type,
      addressBook,
    },
  };
}

export function addressBook(type, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.book_list({ type }, (error, result) => {
      if (result) {
        dispatch(addressBookSuccess(type, result));
      }
      callback(error, result);
    });
  };
}

export function addressBookAddSuccess(name, address) {
  return {
    type: ActionTypes.APP_ADDRESSBOOK_ADD,
    payload: {
      name,
      address,
    },
  };
}

export function addressBookAdd(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.book_add(values, (error, result) => {
      if (result) {
        dispatch(addressBookAddSuccess(values.name, values.address));
      }
      callback(error, result);
    });
  };
}

export function addressBookEditSuccess(name, address) {
  return {
    type: ActionTypes.APP_ADDRESSBOOK_EDIT,
    payload: {
      name,
      address,
    },
  };
}

export function addressBookEdit(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.book_edit(values, (error, result) => {
      if (result) {
        dispatch(addressBookEditSuccess(values.name, values.address));
      }
      callback(error, result);
    });
  };
}

export function addressBookDeleteSuccess(address) {
  return {
    type: ActionTypes.APP_ADDRESSBOOK_DELETE,
    payload: {
      address,
    },
  };
}

export function addressBookDelete(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.book_delete(values, (error, result) => {
      if (result) {
        dispatch(addressBookDeleteSuccess(values.address));
      }
      callback(error, result);
    });
  };
}

export function applications(type, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.app_list({ type }, (error, result) => {
      dispatch({
        type: ActionTypes.APP_APPLICATIONS,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function connect(uid, id, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.connect({ uid, id }, (error, result) => {
      dispatch({
        type: ActionTypes.APP_CONNECT,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function application(uid, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.app({ uid }, (error, result) => {
      dispatch({
        type: ActionTypes.APP_APPLICATION,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function applicationAdd(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.app_add(values, (error, result) => {
      dispatch({
        type: ActionTypes.APP_APPLICATION_ADD,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function applicationEdit(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.app_edit(values, (error, result) => {
      dispatch({
        type: ActionTypes.APP_APPLICATION_EDIT,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function contract(uid, address, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.contract({ uid, address }, (error, result) => {
      dispatch({
        type: ActionTypes.APP_CONTRACT,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function contractAdd(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.contract_add(values, (error, result) => {
      dispatch({
        type: ActionTypes.APP_CONTRACT_ADD,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function contractEdit(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.contract_edit(values, (error, result) => {
      dispatch({
        type: ActionTypes.APP_CONTRACT_ADD,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function contractDelete(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.contract_delete(values, (error, result) => {
      dispatch({
        type: ActionTypes.APP_CONTRACT_DELETE,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function buy(asset, cryptoAmount, fiatValue, fiatCurrency, callback) {
  return (dispatch) => {
    // eslint-disable-next-line no-unused-vars

    const { mydappwallet } = window;
    try {
      mydappwallet.buy({ asset, cryptoAmount, fiatValue, fiatCurrency }, (error, brokerId) => {
        dispatch({
          type: ActionTypes.APP_BUY,
          payload: {
            brokerId,
            error,
          },
        });
        callback(error, brokerId);
      });
    } catch (error) {
      error.code = 500;
      dispatch({
        type: ActionTypes.APP_BUY,
        payload: {
          error,
        },
      });
      callback(error);
    }
  };
}

export function buyClear(network) {
  return {
    type: ActionTypes.APP_BUY_CLEAR,
    payload: {
      network,
    },
  };
}

export function buySellTransaction(broker, brokerId, purchaseViewToken, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    try {
      mydappwallet.broker_transacion(
        { broker, brokerId, purchaseViewToken },
        (error, transaction) => {
          if (transaction) {
            dispatch({
              type: ActionTypes.APP_BUYSELL_TRANSATION,
              payload: {
                transaction,
              },
            });

            callback(error, transaction);
          }
        }
      );
    } catch (error) {
      error.code = 500;
      callback(error);
    }
  };
}
