/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
// react-router components

import { withTranslation } from "react-i18next";

// i18n
import i18n from "i18next";
import authenticationEN from "locales/en/authentication.json";

// Soft UI Dashboard PRO React components

// Soft UI Dashboard PRO React example components
// Soft UI Dashboard PRO React page layout routes

import SignIn from "layouts/pages/auth/sign-in";
import SignUp from "layouts/pages/auth/sign-up";
import SignUp2 from "layouts/pages/auth/sign-up2";
import SignUp3 from "layouts/pages/auth/sign-up3";
import Reset from "layouts/pages/auth/reset-password";

function Auth() {
  const [redirect, setRedirect] = useState(false);
  const [view, changeView] = useState("sign-in");
  const [refId, setRefId] = useState(false);

  useEffect(() => {
    i18n.addResources("en", "authentication", authenticationEN);
  }, []);

  const { mydappwallet } = window;
  const { user } = mydappwallet;
  const { pathname } = useLocation();

  if (user && pathname === "/auth" && !redirect) {
    setRedirect("/");
  }
  if (redirect) return <Redirect to={redirect} />;
  switch (view) {
    case "sign-up":
      return <SignUp changeView={changeView} setRefId={setRefId} />;
    case "sign-up2":
      return <SignUp2 refId={refId} changeView={changeView} setRefId={setRefId} />;
    case "sign-up3":
      return <SignUp3 refId={refId} changeView={changeView} />;
    case "reset-password":
      return <Reset changeView={changeView} />;
    default:
      return <SignIn changeView={changeView} />;
  }
}

export default withTranslation()(Auth);
