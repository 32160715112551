/* eslint-disable no-unused-vars */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import * as CryptoUtil from "utils/CryptoUtil";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
// Soft UI Dashboard PRO React components

import SuiMenu from "components/sui/SuiMenu";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiTokenIcon from "components/sui/SuiTokenIcon";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Soft UI Dashboard PRO React helper functions
const { inputColors } = colors;
const { borderWidth, borderRadius, borderColor } = borders;
const { inputBoxShadow } = boxShadows;

function FromMenu({ anchorEl, close, placement, style }) {
  const tokens = useSelector((state) => state.appStore.tokens);
  return (
    <SuiMenu
      open={anchorEl}
      close={close}
      placement={placement}
      style={{
        margin: "5px!important",
        ...style,
        width: anchorEl ? anchorEl.offsetWidth : 0,
      }}
    >
      {anchorEl && (
        <Grid container spacing={0}>
          {tokens.map((row, index) => (
            <Grid item xs={12}>
              <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
                border={`${borderWidth[1]} solid ${inputColors.borderColor.focus}`}
                boxShadow={`${inputBoxShadow.focus}!important`}
                onClick={() => {
                  close(row);
                }}
                sx={{
                  "&:hover": { background: "#eceeef", borderColor },
                  cursor: "pointer",
                  borderRadius: borderRadius.lg,
                }}
              >
                {row.icon ? (
                  <div
                    className="d-inline-block item-icon-wrapper"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: row.icon }}
                  />
                ) : (
                  <SuiTokenIcon address={row.address} />
                )}
                <SuiBox width="100%" display="flex" flexDirection="column" lineHeight={1} ml={1}>
                  <SuiTypography variant="button" fontWeight="medium">
                    {row.symbol}
                  </SuiTypography>
                  <SuiTypography variant="caption" fontWeight="medium">
                    {CryptoUtil.toAmount(row.balance, row.decimals)} {row.symbol}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              {index < tokens.length - 1 && <Divider sx={{ margin: 0, opacity: 1 }} />}
            </Grid>
          ))}
        </Grid>
      )}
    </SuiMenu>
  );
}

// Setting default values for the props of DefaultNavbarMenu
FromMenu.defaultProps = {
  placement: "bottom-start",
  style: {},
};

// Typechecking props for the DefaultNavbarMenu
FromMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
  placement: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default FromMenu;
