/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// Soft UI Dashboard PRO helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { grey } = colors;
const { size } = typography;

export default {
  styleOverrides: {
    root: {
      font: "inherit",
      fontSize: `${size.sm} !important`,
      color: `${grey[700]} !important`,
      body1: {
        fontSize: "20px",
        width: "100% !important",
        height: `${pxToRem(22)}`,
        padding: "0 !important",
      },
    },

    "MuiTypography-body1": {
      fontSize: "20px",
      width: "100% !important",
      height: `${pxToRem(22)}`,
      padding: "0 !important",
    },
  },
};
