import { useState } from "react";
import PropTypes from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard PRO React components

import Row from "layouts/developers/contract/components/Row";

function MethodList({ tableData, handlePaymentChange }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Card name="applications" sx={{ width: "100%" }}>
      <TableContainer component={Box}>
        <Table aria-label="collapsible table" component="div">
          <TableHead component="div">
            <TableRow>
              <TableCell align="center">
                <Trans>developers:app-contract-methods-col1</Trans>
              </TableCell>
              <TableCell>
                <Trans>developers:app-contract-methods-col2</Trans>
              </TableCell>
              <TableCell align="left">
                <Trans>developers:app-contract-methods-col3</Trans>
              </TableCell>
              <TableCell align="center">
                <Trans>developers:app-contract-methods-col4</Trans>
              </TableCell>
              <TableCell align="center">
                <Trans>developers:app-contract-methods-col5</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody component="div">
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.address}
                  row={row}
                  index={index}
                  handlePaymentChange={handlePaymentChange}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            select: {
              width: "50px",
            },
          }}
        />
      </TableContainer>
    </Card>
  );
}

MethodList.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handlePaymentChange: PropTypes.func.isRequired,
};

export default withTranslation()(MethodList);
