import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiInput from "components/sui/SuiInput";
import SuiButton from "components/sui/SuiButton";
import SuiCodeInput from "components/sui/SuiCodeInput";

// Layout Components
import ErrorMsg from "components/layout/Error";
import Step from "components/layout/Step";

// Formix
import * as Yup from "yup";
import { Formik, Form } from "formik";

// Actions
import * as app from "actions/app";
import * as profile from "actions/profile";
import Constants from "constants/Constants";

const { AUTHENTICATORS } = Constants;
const { REFLINK_TYPES } = Constants;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "background.paper",
  boxShadow: 24,
  maxHeight: "90%",
  padding: 4,
};

function Step1({ auth, setRefId, nextStep, setError, t }) {
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const formik = useRef(null);

  const onSubmited = (error, result) => {
    if (error) {
      switch (error.code) {
        case 4600:
          formik.current.errors.oldPassword = t("errors:4600");
          break;
        case 8100:
          setError({ code: 8102 });
          break;
        default:
          setError({ error });
      }
      setSubmit(false);
    } else {
      setRefId(result.refId);
      nextStep();
    }
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(profile.authenticatorEdit({ ...values, type: AUTHENTICATORS.GOOGLE }, onSubmited));

    return true;
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .required(t("yup:required", { name: t("email") }))
        .email(t("yup:email")),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };
  return (
    <Formik
      initialValues={auth}
      validate={validate(validationSchema)}
      onSubmit={onSubmit}
      innerRef={formik}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit} noValidate name="changeForm">
          <SuiBox mb={2} mt={2}>
            <SuiInput
              name="email"
              type="email"
              placeholder="Email"
              error={!!touched.email && !!errors.email}
              helperText={errors.email}
              onChange={handleChange}
              value={values.email}
              disabled={submit}
              onBlur={handleBlur}
            />
          </SuiBox>
          <Divider variant="middle" />

          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Step x={1} from={3} />
            <Grid item xs={5}>
              <SuiButton
                name="submit"
                disabled={submit}
                component={SuiButton}
                onClick={() => {
                  formik.current.submitForm();
                }}
                variant="gradient"
                color="info"
                fullWidth
              >
                <Trans>next</Trans>
              </SuiButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

Step1.propTypes = {
  auth: PropTypes.shape({
    email: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
  setRefId: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

function Step2({ refId, nextStep, handleClose, t }) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const formik = useRef(null);
  const codeInput = useRef(null);

  useEffect(() => {
    dispatch(
      app.refLink({ refId, type: REFLINK_TYPES.CHANGE_GOOGLE_AUTHENTICATOR }, (_error) => {
        if (_error) {
          setRedirect(`/error/${_error.code}`);
        } else {
          setLoading(false);
        }
      })
    );
  }, []);

  const handleChangeCode = (e) => {
    formik.current.setFieldValue("code", e, true);
    formik.current.setFieldTouched("code", true);
  };
  const onSubmited = (_error) => {
    if (_error) {
      formik.current.setFieldValue("code", "", false);
      codeInput.current.reset();
      switch (_error.code) {
        case 4600:
          formik.current.errors.code = t("errors:4600");
          break;
        case 8200:
          formik.current.errors.code = t("errors:8200");
          break;
        case 8300:
          setError(_error);
          break;
        default:
          setRedirect(`/error/${_error.code}`);
      }
      setSubmit(false);
    } else {
      nextStep();
    }
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(
      profile.authenticatorEdit2({ ...values, type: AUTHENTICATORS.GOOGLE, refId }, onSubmited)
    );

    return true;
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      code: Yup.string()
        .required(t("yup:required", { name: t("code") }))
        .min(6, t("yup:min", { name: t("code"), char: t("6") })),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };
  if (redirect) return <Redirect to={redirect} />;
  return (
    <Formik
      initialValues={{ code: "" }}
      validate={validate(validationSchema)}
      onSubmit={onSubmit}
      innerRef={formik}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <Form onSubmit={handleSubmit} noValidate name="enableForm">
          <Grid container spacing={2} wrap="nowrap" mb={2} mt={1}>
            <Grid item>
              <PhonelinkLockIcon fontSize="large" />
            </Grid>
            <Grid item>
              <SuiTypography variant="caption" component="div">
                <Trans>profile:authenticator-edit-step2-0</Trans>
              </SuiTypography>
            </Grid>
          </Grid>
          <SuiBox mb={2}>
            <SuiCodeInput
              name="code"
              type="number"
              ref={codeInput}
              fields={6}
              value={values.code}
              onChange={handleChangeCode}
              isValid={!(touched.code && errors.code)}
              filterChars={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
              disabled={loading || submit || error}
              filterCharsIsWhitelist
              pattern="[0-9]"
              helperText={errors.code}
            />
          </SuiBox>
          <Divider variant="middle" />

          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Step x={2} from={3} />
            <Grid item xs={5}>
              <SuiButton
                name="submit"
                component={SuiButton}
                disabled={loading || submit}
                onClick={() => {
                  if (error) handleClose();
                  else formik.current.submitForm();
                }}
                variant="gradient"
                color="info"
                fullWidth
              >
                <Trans>{error ? "cancel" : "next"}</Trans>
              </SuiButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

Step2.propTypes = {
  nextStep: PropTypes.func.isRequired,
  refId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

function Step3({ handleClose }) {
  return (
    <>
      <Grid container spacing={2} wrap="nowrap" mb={2} mt={1}>
        <Grid item>
          <PhonelinkLockIcon fontSize="large" />
        </Grid>
        <Grid item>
          <SuiTypography variant="caption" component="div">
            <Trans>profile:authenticator-edit-step3-0</Trans>
          </SuiTypography>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <SuiTypography component="label" variant="caption" textTransform="capitalize">
          <Trans values={{ one: 3, two: 3 }}>step-of</Trans>
        </SuiTypography>
        <Grid item xs={5}>
          <SuiButton
            name="submit"
            component={SuiButton}
            onClick={() => {
              handleClose();
            }}
            variant="gradient"
            color="info"
            fullWidth
          >
            <Trans>finish</Trans>
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
}

Step3.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

function Error({ handleClose, error }) {
  return (
    <>
      <SuiBox mt={2}>
        <ErrorMsg error={error} color="primary" />
      </SuiBox>
      <Divider variant="middle" />
      <Grid container direction="row" justifyContent="flex-end" alignItems="right">
        <Grid item xs={5}>
          <SuiButton
            name="submit"
            component={SuiButton}
            onClick={() => {
              handleClose();
            }}
            variant="gradient"
            color="info"
            fullWidth
          >
            <Trans>cancel</Trans>
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
}

Error.propTypes = {
  handleClose: PropTypes.func.isRequired,
  error: PropTypes.shape({
    code: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
};

function GoogleAuthenticatorEditDialog({ auth, handleClose, t }) {
  const [step, setStep] = useState(1);
  const [refId, setRefId] = useState(1);
  const [error, setError] = useState(false);
  const nextStep = () => {
    setStep(step + 1);
  };
  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <SuiBox sx={style}>
          <SuiBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <SuiTypography variant="h4">Google Authenticator</SuiTypography>
            <SuiBox
              sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={handleClose}
            >
              <CloseIcon size="small" color="#92929d" />
            </SuiBox>
          </SuiBox>
          {error && <Error error={error} handleClose={handleClose} t={t} />}
          {!error && step === 1 && (
            <Step1 auth={auth} nextStep={nextStep} setError={setError} setRefId={setRefId} t={t} />
          )}
          {!error && step === 2 && (
            <Step2 nextStep={nextStep} refId={refId} handleClose={handleClose} t={t} />
          )}
          {!error && step === 3 && <Step3 handleClose={handleClose} t={t} />}
        </SuiBox>
      </>
    </Modal>
  );
}

GoogleAuthenticatorEditDialog.propTypes = {
  auth: PropTypes.shape({
    email: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(GoogleAuthenticatorEditDialog);
