/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import colors from "assets/theme/base/colors";

const { light } = colors;

export default styled(Drawer)(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, miniSidenav } = ownerState;

  const sidebarWidth = 320;
  const { white, transparent } = palette;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
    [breakpoints.up("xl")]: {
      backgroundColor: transparentSidenav ? transparent.main : white.main,
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: sidebarWidth,
      position: "static",
      float: "left",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
    [breakpoints.up("xl")]: {
      backgroundColor: transparentSidenav ? transparent.main : white.main,
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: pxToRem(96),
      position: "static",
      float: "left",
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });
  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",
      margin: 0,
      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
    "& .badge_top": {
      marginTop: "-16px",
      "& span": {
        color: "rgb(103, 116, 142)",
        padding: "0 5px",
        background: "transparent",
        fontWeight: "900",
      },
    },
    "& .badge_icons": {
      bottom: "-5px",
      position: "absolute",
      left: 0,
      right: 0,
      "& span": {
        width: "100%",
        padding: "2px 6px",
        fontSize: "8px",
      },
    },
    "& .crypto__link": {
      color: "rgb(103, 116, 142)",
    },
    "& .crypto__list-items": {
      cursor: "pointer",
      transition: "all 0.5s ease",
      borderRadius: "10px",
      padding: "15px 10px",
      minHeight: "76px",
      "&:hover": {
        backgroundColor: light.focus,
      },
      ".crypto-price-qty": {
        ".crypto-qty": {
          fontWeight: "900",
          fontSize: pxToRem(14),
        },
        ".crypto-price": {
          fontSize: pxToRem(12),
          color: "rgb(103, 116, 142)",
          fontWeight: "900",
        },
      },
      "&.hide-crypto-item": {
        ".crypto-avatar-img": {
          filter: "grayscale(100%)",
        },
        ".crypto-item-title": {
          color: "rgb(103, 116, 142)",
        },
        ".crypto-sub-title-block": {
          ".MuiAvatar-img": {
            filter: "grayscale(100%)",
          },
          ".crypto-sub-title": {
            fontSize: pxToRem(12),
            color: "rgb(103, 116, 142)",
          },
        },
      },
    },
    "& .active-crypto-item": {
      backgroundColor: light.focus,
      transition: "none",
    },
    "& .add_wallet_button": {
      background: "#fff",
      cursor: "pointer",
      borderRadius: "10px",
      boxShadow: xxl,
    },
    "& .icon_avtar img": {
      width: "18px",
      height: "unset",
      cursor: "pointer",
    },
    "& .searchinput": {
      width: "18px",
      height: "18px",
      overflow: "visible",
    },
    "& .topbox_header": {
      minHeight: "80px",
    },
  };
});
