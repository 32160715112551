/* eslint-disable class-methods-use-this */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef } from "react";
import { connect, useSelector } from "react-redux";

import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
// Soft UI Dashboard PRO React components
import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";
import SuiFormField from "components/sui/SuiFormField";
import SuiButton from "components/sui/SuiButton";
import SuiInput from "components/sui/SuiInput";
import SuiAlert from "components/sui/SuiAlert";
import * as Yup from "yup";
import { Formik } from "formik";

// Actions
import * as app from "actions/app";

// eslint-disable-next-line import/no-named-as-default
import Row from "layouts/asset/common/tabs/Books/Row";
import EditBookDialog from "layouts/asset/common/tabs/Books/EditBookDialog";
import DeleteBookDialog from "layouts/asset/common/tabs/Books/DeleteBookDialog";

const utils = require("web3-utils");

const initialValues = {
  name: "",
  address: "",
};

function Books({ addressBookAdd, type, t }) {
  const addressBook = useSelector((state) => state.appStore.addressBook);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [editBook, setEditBook] = useState(false);
  const [deleteBook, setDeleteBook] = useState(false);
  const formik = useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditBook = (index, row) => {
    setEditBook(row);
  };

  const handleEditBookClose = () => {
    setEditBook(false);
  };

  const handleDeleteBook = (index, row) => {
    setDeleteBook(row);
  };

  const handleDeleteBookClose = () => {
    setDeleteBook(false);
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validateAddress = (value) => {
    if (!value) return true;
    switch (type) {
      case "EVM":
        return utils.isAddress(value);
      default:
        return false;
    }
  };
  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, t("yup:min", { name: t("name"), char: 3 }))
        .required(t("yup:required", { name: t("name") })),
      address: Yup.string()
        .required(t("yup:required", { name: t("address") }))
        .test({
          name: "address",
          exclusive: true,
          message: t("yup:format", { name: t("address") }),
          test: validateAddress,
        }),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  const onSubmited = (_error) => {
    if (_error) {
      switch (_error.code) {
        case 6000:
          formik.current.errors.address = t("errors:6000");
          break;
        default:
          setError(_error);
      }
    } else {
      formik.current.resetForm();
    }
    setSubmit(false);
  };

  const onSubmit = (values) => {
    setSubmit(true);
    setError(undefined);
    addressBookAdd({ name: values.name, address: values.address, type }, onSubmited);
  };

  return (
    <>
      <EditBookDialog
        open={editBook}
        row={editBook}
        type={type}
        handleClose={handleEditBookClose}
      />
      <DeleteBookDialog
        open={deleteBook}
        row={deleteBook}
        type={type}
        handleClose={handleDeleteBookClose}
      />
      <Grid spacing={2} item xs={12} sm="auto">
        <Grid spacing={2} item xs={12}>
          <TableContainer component={Box}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Trans>crypto:books-col1</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>crypto:books-col2</Trans>
                  </TableCell>
                  <TableCell align="left">
                    <Trans>crypto:books-col3</Trans>
                  </TableCell>
                  <TableCell align="center">
                    <Trans>crypto:books-col4</Trans>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addressBook
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Row
                      key={row.name}
                      row={row}
                      index={index}
                      handleEditBook={handleEditBook}
                      handleDeleteBook={handleDeleteBook}
                    />
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={addressBook.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              styleOverrides={{
                select: {
                  width: "50px",
                },
              }}
            />
          </TableContainer>
        </Grid>
        <Divider />
        <SuiBox p={1}>
          <SuiTypography variant="h5">
            <Trans>crypto:books-add</Trans>
          </SuiTypography>
        </SuiBox>
        <Formik
          initialValues={initialValues}
          validate={validate(validationSchema)}
          onSubmit={onSubmit}
          innerRef={formik}
        >
          {({ values, errors, handleChange, handleSubmit }) => (
            <SuiBox component="form" onSubmit={handleSubmit} noValidate name="addForm">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <SuiFormField
                    label={t("name")}
                    name="name"
                    placeholder={t("name")}
                    error={!!errors.name}
                    helperText={errors.name}
                    onChange={handleChange}
                    value={values.name}
                    disabled={submit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SuiFormField
                    label={t("address")}
                    name="address"
                    placeholder={t("address")}
                    error={!!errors.address}
                    helperText={errors.address}
                    onChange={handleChange}
                    value={values.address}
                    disabled={submit}
                    component={SuiInput}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    height="100%"
                  >
                    <SuiFormField
                      label="&nbsp;"
                      name="address"
                      placeholder={t("address")}
                      error={!!errors.address}
                      helperText={errors.address}
                      onChange={handleChange}
                      value={values.address}
                      disabled={submit}
                      component={SuiButton}
                      onClick={() => {
                        formik.current.submitForm();
                      }}
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      <Trans>add</Trans>
                    </SuiFormField>
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
          )}
        </Formik>
        {error && (
          <Grid mt={3}>
            <SuiBox>
              <SuiAlert alert={error} color="primary">
                <Trans>{`error-${error.code}`}</Trans>
              </SuiAlert>
            </SuiBox>
          </Grid>
        )}
      </Grid>
    </>
  );
}

Books.propTypes = {
  t: PropTypes.func.isRequired,
  addressBookAdd: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({
  addressBookAdd: (values, callback) => dispatch(app.addressBookAdd(values, callback)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Books));
