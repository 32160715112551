/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable class-methods-use-this */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";

// @mui material components
// Soft UI Dashboard PRO React components
// Send components

import Stage1 from "layouts/asset/erc20/tabs/Send/Stage1";
import Stage2 from "layouts/asset/erc20/tabs/Send/Stage2";
import Stage3 from "layouts/asset/erc20/tabs/Send/Stage3";

import Constants from "constants/Constants";

const { TRANSACTION_STATUSES } = Constants;

function Send({ name, asset }) {
  const [currency, setCurrency] = useState(
    useSelector((state) => state.appStore.settings.defaultCurrency)
  );
  const transaction = useSelector((state) => state.web3Store.transactions[name]);
  if (transaction) {
    if (transaction.status === TRANSACTION_STATUSES.NEW)
      return <Stage2 name={name} currency={currency} />;
    return <Stage3 name={name} currency={currency} />;
  }

  return <Stage1 name={name} asset={asset} currency={currency} setCurrency={setCurrency} />;
}

Send.propTypes = {
  name: PropTypes.string.isRequired,
  asset: PropTypes.shape({}).isRequired,
};

export default Send;
