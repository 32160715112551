import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";

// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

// Soft UI Dashboard PRO React example components

import SuiBox from "components/sui/SuiBox";
import SuiFormField from "components/sui/SuiFormField";
import SuiButton from "components/sui/SuiButton";
import SuiAlert from "components/sui/SuiAlert";
import SuiTypography from "components/sui/SuiTypography";

// Soft UI Dashboard PRO React base styles

import * as Yup from "yup";
import { Formik } from "formik";

// i18m
import { Trans, withTranslation } from "react-i18next";

import * as app from "actions/app";

const ApplicationForm = ({ application, t }) => {
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const formik = useRef(null);
  const onSubmited = (_error) => {
    if (_error) {
      switch (_error.code) {
        case 9100:
          formik.current.errors.name = t("errors:9100");
          break;
        default:
          setError(_error);
      }
    }
    setSubmit(false);
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(app.applicationEdit({ ...values, status: parseInt(values.status, 10) }, onSubmited));
    return true;
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, t("yup:min", { name: t("name"), char: 3 }))
        .required(t("yup:required", { name: t("name") })),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  return (
    <Card name="applications" sx={{ width: "100%" }}>
      <Formik
        initialValues={application}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <SuiBox component="form" onSubmit={handleSubmit} noValidate name="applicationForm">
            <Grid container spacing={3} ml={1} mt={1} mb={3}>
              <Grid item xs={12} sm={6}>
                <SuiFormField
                  label={t("name")}
                  name="name"
                  placeholder={t("name")}
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                  value={values.name}
                  disabled={submit}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Status
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <RadioGroup
                      row
                      id="status"
                      name="status"
                      defaultValue={application.status}
                      onChange={handleChange}
                    >
                      <FormControlLabel value={0} control={<Radio />} label={t("inactive")} />
                      <FormControlLabel value={1} control={<Radio />} label={t("active")} />
                    </RadioGroup>
                  </SuiBox>
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={2}>
                <SuiFormField
                  label="&nbsp;"
                  name="submit"
                  disabled={submit}
                  component={SuiButton}
                  onClick={() => {
                    formik.current.submitForm();
                  }}
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  <Trans>save</Trans>
                </SuiFormField>
              </Grid>
            </Grid>
            {error && (
              <Grid mt={3}>
                <SuiBox>
                  <SuiAlert alert={error} color="primary">
                    <Trans>{`errors:${error.code}`}</Trans>
                  </SuiAlert>
                </SuiBox>
              </Grid>
            )}
          </SuiBox>
        )}
      </Formik>
    </Card>
  );
};

ApplicationForm.propTypes = {
  application: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ApplicationForm);
