/* eslint-disable no-param-reassign */
import ActionTypes from "constants/ActionTypes";
import * as app from "actions/app";

export function signIn(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.sign_in(values, (error, result) => {
      dispatch({
        type: ActionTypes.AUTH_SIGN_IN,
      });
      if (result && result.status === 1) {
        dispatch(
          app.init((_error) => {
            callback(_error, result);
          })
        );
      } else {
        callback(error, result);
      }
    });
  };
}

export function signIn2(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.sign_in2(values, (error, result) => {
      dispatch({
        type: ActionTypes.AUTH_SIGN_IN2,
      });
      callback(error, result);
    });
  };
}

export function signIn3(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    if (!values.remember) values.remember = false;
    mydappwallet.sign_in3(values, (error, result) => {
      dispatch({
        type: ActionTypes.AUTH_SIGN_IN3,
      });
      if (result && result.status === 1) {
        dispatch(
          app.init((_error, _result) => {
            callback(_error, _result);
          })
        );
      } else {
        callback(error, result);
      }
    });
  };
}

export function signOut(callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.sign_out((error, result) => {
      dispatch({
        type: ActionTypes.AUTH_SIGN_OUT,
      });
      callback(error, result);
    });
  };
}

export function signUp(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.sign_up(values, (error, result, info) => {
      dispatch({
        type: ActionTypes.AUTH_SIGN_UP,
        payload: {
          result,
        },
      });
      if (info)
        dispatch({
          type: ActionTypes.APP_INFO,
          payload: {
            info,
          },
        });
      callback(error, result);
    });
  };
}

export function signUp2(values, callback) {
  const { mydappwallet } = window;
  mydappwallet.sign_up2(values, callback);
  return {
    type: ActionTypes.AUTH_SIGN_UP2,
  };
}

export function signUp3(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.sign_up3(values, (error, result, info) => {
      dispatch({
        type: ActionTypes.AUTH_SIGN_UP3,
        payload: {
          error,
          result,
          info,
        },
      });
      if (info)
        dispatch({
          type: ActionTypes.APP_INFO,
          payload: {
            info,
          },
        });
      if (result) {
        dispatch(
          app.init((_error, _result) => {
            callback(_error, _result);
          })
        );
      } else {
        callback(error, result);
      }
    });
  };
}

export function resetPassword(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.reset_password(values, (error, result, info) => {
      dispatch({
        type: ActionTypes.AUTH_RESET_PASSWORD,
        payload: {
          error,
          result,
          info,
        },
      });
      if (info)
        dispatch({
          type: ActionTypes.APP_INFO,
          payload: {
            info,
          },
        });
      callback(error, result, info);
    });
  };
}

export function resetPassword2(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.reset_password2(values, (error, result, info) => {
      dispatch({
        type: ActionTypes.AUTH_RESET_PASSWORD,
        payload: {
          error,
          result,
          info,
        },
      });
      if (info)
        dispatch({
          type: ActionTypes.APP_INFO,
          payload: {
            info,
          },
        });
      callback(error, result, info);
    });
  };
}

export function refLink(values, callback) {
  const { mydappwallet } = window;
  mydappwallet.reflink(values, callback);
  return {
    type: ActionTypes.APP_REFLINK,
  };
}
