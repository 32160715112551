import React, { useState } from "react";

import PropTypes from "prop-types";

import { Trans, withTranslation } from "react-i18next";

// @mui material components
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function Row({ row, index, handlePaymentChange, t }) {
  const [payment] = useState([
    { value: 0, label: t("developers:app-contract-method-payment-crypto") },
    { value: 1, label: t("developers:app-contract-method-payment-fiat") },
  ]);

  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell>{index + 1}</TableCell>
      <TableCell component="th" scope="row">
        {row.signature}
      </TableCell>
      <TableCell align="left">{row.ids}</TableCell>
      <TableCell>
        <Select
          options={payment}
          label={t("payment")}
          name="payment"
          value={row.payment}
          onChange={(event: SelectChangeEvent) => {
            handlePaymentChange(index, event.target.value);
          }}
        >
          <MenuItem value={0}>
            <Trans>developers:app-contract-method-payment-crypto</Trans>
          </MenuItem>
          <MenuItem value={1}>
            <Trans>developers:app-contract-method-payment-fiat</Trans>
          </MenuItem>
        </Select>
      </TableCell>
      <TableCell>{row.stateMutability}</TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.shape({
    signature: PropTypes.string.isRequired,
    ids: PropTypes.string.isRequired,
    stateMutability: PropTypes.string.isRequired,
    payment: PropTypes.number.isRequired,
  }).isRequired,
  handlePaymentChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Row);
