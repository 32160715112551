/* eslint-disable no-unused-vars */
import { forwardRef, useState } from "react";
import { PropTypes } from "prop-types";

// Soft UI Dashboard PRO React components

// @mui material components
import Paper from "@mui/material/Paper";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import NumberFormat from "react-number-format";

// eslint-disable-next-line no-unused-vars
const TokenAmountField = forwardRef(
  ({ value, error, touched, changeValue, onBlur, symbol, decimals }, ref) => {
    const [highlight, setHighlight] = useState(false);

    let elevation;
    if (touched && error) {
      elevation = highlight ? 13 : 12;
    } else {
      elevation = highlight ? 11 : 10;
    }
    return (
      <>
        <Paper
          elevation={elevation}
          sx={{
            display: "flex-direction",
            borderRadius: borders.borderRadius.lg,
            boxShadow: 0,
            position: "relative",
            "&:hover": { outline: 0 },
          }}
        >
          <FormControl fullWidth sx={{ padding: 2 }}>
            <NumberFormat
              className="send_token_input"
              name="value"
              id="value"
              placeholder={symbol}
              required
              onValueChange={(values, sourceInfo) => {
                if (sourceInfo.source === "event") changeValue(values.floatValue);
              }}
              value={value / 10 ** decimals}
              suffix={` ${symbol}`}
              decimalScale={decimals}
              onBlur={(e) => {
                setHighlight(false);
                onBlur(e);
              }}
              onFocus={() => {
                setHighlight(true);
              }}
            />
          </FormControl>
        </Paper>
        <FormHelperText error id="error">
          {touched ? error : ""}
        </FormHelperText>
      </>
    );
  }
);
TokenAmountField.defaultProps = {
  touched: false,
  error: false,
};

TokenAmountField.propTypes = {
  value: PropTypes.number.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  changeValue: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  symbol: PropTypes.string.isRequired,
  decimals: PropTypes.number.isRequired,
};

export default TokenAmountField;
