import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// i18m
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";
import developersEN from "locales/en/developers.json";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiSpinner from "components/sui/SuiSpinner";
import SuiAlert from "components/sui/SuiAlert";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

// Soft UI Dashboard PRO React example components
import DefaultLayout from "components/layout/LayoutContainers/DefaultLayout";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import errorIcon from "assets/images/icons/error.svg";

import * as app from "actions/app";

import ApplicationList from "layouts/developers/applications/components/ApplicationList";
import ApplicationAddForm from "layouts/developers/applications/components/ApplicationAddForm";

const { values } = breakpoints;

const Applications = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    i18n.addResources("en", "developers", developersEN);
    dispatch(
      app.applications(10, (_error, result) => {
        if (error) {
          setError(_error);
        } else {
          setTableData(result);
        }
        setLoading(false);
      })
    );
  }, []);

  const handleAdd = (application) => {
    setTableData([...tableData, application]);
  };

  if (loading)
    return (
      <DefaultLayout>
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <SuiBox mb={3} p={1}>
                <SuiTypography
                  variant={window.innerWidth < values.sm ? "h4" : "h3"}
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  <Trans>developers:apps-title</Trans>
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Card sx={{ width: "100%" }}>
              <SuiSpinner />
            </Card>
          </Grid>
        </SuiBox>
      </DefaultLayout>
    );

  if (error) {
    return (
      <DefaultLayout>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <SuiBox component="img" src={errorIcon} alt="Rocketship" mb={3} width="200px" />
          <SuiBox>
            <SuiAlert alert={error} color="primary">
              <Trans>{`error:${error.code}`}</Trans>
            </SuiAlert>
          </SuiBox>
        </Grid>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <SuiBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <SuiBox mb={3} p={1}>
              <SuiTypography
                variant={window.innerWidth < values.sm ? "h4" : "h3"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                <Trans>developers:apps-title</Trans>
              </SuiTypography>
            </SuiBox>
            <Grid container>
              <Grid item xs={12}>
                <ApplicationList tableData={tableData} />
              </Grid>
              <SuiBox p={1} mt={3}>
                <SuiTypography variant="h5">
                  <Trans>developers:apps-add-title</Trans>
                </SuiTypography>
              </SuiBox>
              <Grid item xs={12}>
                <ApplicationAddForm handleAdd={handleAdd} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </DefaultLayout>
  );
};
Applications.propTypes = {};

export default withTranslation()(Applications);
