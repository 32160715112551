import ActionTypes from "constants/ActionTypes";

const initialState = {
  transactions: {},
  errors: {},
  warnings: {},
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.WEB3_SEND_TRANSACTION:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [action.payload.asset]: action.payload.transaction,
        },
      };

    case ActionTypes.WEB3_CONFIRM_TRANSACTION:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [action.payload.asset]: action.payload.transaction,
        },
      };

    case ActionTypes.WEB3_UPDATE_TRANSACTION:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [action.payload.asset]: action.payload.transaction,
        },
      };

    case ActionTypes.WEB3_ERROR_TRANSACTION:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.asset]: action.payload.error,
        },
      };

    case ActionTypes.WEB3_CLEAR_TRANSACTION:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [action.payload.asset]: undefined,
        },
        errors: {
          ...state.errors,
          [action.payload.asset]: undefined,
        },
        warnings: {
          ...state.errors,
          [action.payload.aset]: undefined,
        },
      };

    default:
      return state;
  }
}
