import React from "react";
import PropTypes from "prop-types";
import QrReader from "react-qr-reader";

const bindEvent = function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent(`on${eventName}`, eventHandler);
  }
};

const unbindEvent = function unbindEvent(element, eventName, eventHandler) {
  if (element.removeEventListener) {
    element.removeEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.detachEvent(`on${eventName}`, eventHandler);
  }
};

class SuiQRScanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    bindEvent(window, "mousedown", this.mouseDown.bind(this));
  }

  componentWillUnmount() {
    unbindEvent(window, "mousedown", this.mouseDown.bind(this));
  }

  handleScan(data) {
    const { handleScan } = this.props;
    if (data) {
      handleScan(data);
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleError(_err) {
    this.setState({ error: true });
  }

  mouseDown() {
    const { handleScanClose } = this.props;
    handleScanClose();
  }

  render() {
    const { error } = this.state;
    if (error) return <div className="qrscan_window_error">Camera is not supported</div>;
    return (
      <div className="qrscan_window">
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: 200 }}
        />
      </div>
    );
  }
}

SuiQRScanner.propTypes = {
  /**
   * The page title.
   */
  handleScan: PropTypes.func.isRequired,
  handleScanClose: PropTypes.func.isRequired,
};

export default SuiQRScanner;
