"use statics";

export default {
  ETHER: "1000000000000000000",
  GWEI: "1000000000",
  DATE_AND_TIME_FORMAT: "DD MMM YYYY hh:mm A",
  DATE_FORMAT: "DD MMM YYYY",
  TIME_FORMAT: "hh:mm A",
  CURRENCIES_COUNTRIES_CODES: {
    SGD: "sg",
    BGN: "bg",
    MDL: "md",
    NZD: "nz",
    INR: "in",
    ISK: "is",
    CRC: "cr",
    KES: "ke",
    BRL: "br",
    BMD: "bm",
    COP: "co",
    BWP: "bw",
    MXN: "mx",
    CZK: "cz",
    ZAR: "za",
    EUR: "european_union",
    GBP: "gb",
    USD: "us",
    LKR: "lk",
    PYG: "py",
    UYU: "uy",
    HNL: "hn",
    KWD: "kw",
    ILS: "il",
    MKD: "mk",
    PEN: "pe",
    MZN: "mz",
    RON: "ro",
    DKK: "dk",
    GEL: "ge",
    DOP: "do",
    PLN: "pl",
    BAM: "ba",
    MYR: "my",
    GTQ: "gt",
    HUF: "hu",
    THB: "th",
    KZT: "kz",
    LAK: "la",
    CHF: "ch",
    RSD: "rs",
    SEK: "se",
  },
  TRANSACTION_TYPES: {
    SEND: 50,
    CONTRACT_INTERACTION: 60,
    CONTRACT_DEPLOYMENT: 70,
    TRANSFER_TOKEN: 80,
  },

  TRANSACTION_STATUSES: {
    NEW: 0,
    REJECTED: 1,
    PENDING: 2,
    FAILED: 3,
    FINISHED: 4,
  },

  BYSELL_TRANSACTION_TYPES: {
    BUY: 10,
  },

  BYSELL_TRANSACTION_STATUSES: {
    INITIALIZED: 0,
    PAYMENT_STARTED: 1,
    PAYMENT_IN_PROGRESS: 2,
    PAYMENT_FAILED: 3,
    PAYMENT_EXECUTE: 4,
    FIAT_SENT: 5,
    FIAT_RECEIVED: 6,
    RELEASING: 7,
    RELEASED: 8,
    EXPIRED: 9,
    CANCELLED: 10,
  },

  BROKERS: {
    SANDBOX: "SANDBOX",
    RAMP: "RAMP",
  },

  DEFAULT_GAS_LIMIT: {
    WEB3: {
      SEND: 21000,
      TRANSFER_TOKEN: 63000,
    },
  },
  AUTHENTICATORS: {
    GOOGLE: 0,
    SMS: 1,
  },
  REFLINK_TYPES: {
    SIGN_IN: 10,
    SIGN_UP: 20,
    CREATE_PROFILE: 30,
    RESET_PASSWORD: 70,
    CHANGE_GOOGLE_AUTHENTICATOR: 100,
    ENABLE_GOOGLE_AUTHENTICATOR: 200,
    CHANGE_SMS_AUTHENTICATOR: 101,
    EMABLE_SMS_AUTHENTICATOR: 201,
  },
};
