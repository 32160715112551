/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { NavLink } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import AnchorLink from "react-anchor-link-smooth-scroll";
// Soft UI Dashboard PRO React components

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

// Frame layout components
import CoverLayout from "layouts/pages/components/CoverLayout";
import pxToRem from "assets/theme/functions/pxToRem";

function Privacy() {
  return (
    <CoverLayout
      title="Terms & Conditions"
      description="MYDAPPWALLET TERMS AND CONDITIONS OF APP"
      header={
        <SuiTypography variant="inherit" fontWeight="medium">
          <NavLink to="/legal" sx={{ coursor: "pointer" }} textAlign="left">
            &gt; Back to legal homepage
          </NavLink>
        </SuiTypography>
      }
    >
      <Card>
        <SuiBox p={3} mb={1} textAlign="left">
          <SuiBox mt={1}>
            <SuiTypography variant="h5" fontWeight="bold">
              Table of content
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1}>
            <SuiTypography variant="inherit" fontWeight="medium">
              <List
                sx={{
                  listStyleType: "decimal",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  <AnchorLink href="#general">General provisions</AnchorLink>
                </ListItem>
                <ListItem>
                  <AnchorLink href="#definitions">Definitions</AnchorLink>
                </ListItem>
                <ListItem>
                  <AnchorLink href="#technical">Technical requirements</AnchorLink>
                </ListItem>
              </List>
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1}>
            <SuiTypography
              id="general"
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              § 1.
            </SuiTypography>
            <SuiTypography
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              General provisions
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1}>
            <SuiTypography variant="inherit" fontWeight="medium">
              <List
                sx={{
                  listStyleType: "decimal",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  These Terms and Conditions (hereinafter: the{" "}
                  <b>&quot;Terms and Conditions&quot;</b> or <b>&quot;Regulations&quot;</b> ) set
                  out the rules and conditions for the use of the myDAPPwallet application
                  (hereinafter: the <b>&quot;App&quot;</b> or <b>&quot;Application&quot;</b> ) and
                  the services provided by the Service Provider.).
                </ListItem>
                <ListItem>
                  The Terms and Conditions are the rules and regulations referred to in Article 8 of
                  the Act of 18 July 2002 on the provision of services by electronic means
                  (hereinafter: the <b>&quot;Electronic Services Act&quot;</b>).
                </ListItem>
                <ListItem>
                  The Service Provider is BRIDGE sp. z o.o. company with its registered office in
                  Warsaw (registered office address: ul. Echa Leśne 21B, 03-286 Warsaw), entered in
                  the Register of Entrepreneurs of the National Court Register kept by the District
                  Court of Warsaw, XIV Commercial Division of the National Court Register under the
                  KRS number: 0000981309, holder of NIP: 5242945385, REGON number: 522538698, with
                  the share capital of PLN 6.000 (six thousands PLN) paid in full (hereinafter:{" "}
                  <b>&quot;Service Provider&quot;</b>).
                </ListItem>
                <ListItem>
                  Contact with the Service Provider is possible using:
                  <List
                    sx={{
                      listStyleType: "none",
                      pl: 2,
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem>1) e-mail - at: contact@dbridge.network;</ListItem>
                    <ListItem>
                      2) traditional mail - at: ul. Echa Leśne 21B, 03-286 Warsaw;
                    </ListItem>
                    <ListItem>3) e-mail - at: contact@dbridge.network;</ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  Information about services available in the App, in particular their descriptions,
                  technical and functional parameters, and prices, constitute an invitation to
                  conclude a contract for the delivery of services within the meaning of art. 71 of
                  the Act of 23 April 1964 Civil Code (hereinafter: <b>&quot;Civil Code&quot;</b>).
                </ListItem>
                <ListItem>
                  The Service Recipient and the User are obliged to read the Terms and Conditions
                  before using the App.
                </ListItem>
                <ListItem>
                  <b>
                    For the avoidance of doubt, the Service Provider declares that it does not
                    provide payment services.
                  </b>
                </ListItem>
              </List>
            </SuiTypography>
          </SuiBox>

          <SuiBox mt={5}>
            <SuiTypography
              id="definitions"
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              § 2.
            </SuiTypography>
            <SuiTypography
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              Definitions
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1}>
            <SuiTypography variant="inherit" fontWeight="medium">
              Capitalized words used in the Regulations have the following meaning:
              <List
                sx={{
                  listStyleType: "decimal",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                    marginLeft: pxToRem(10),
                  },
                }}
              >
                <ListItem>
                  <b>Account</b> - a panel created in the IT system of the Application, enabling the
                  User to use its functionalities;
                </ListItem>
                <ListItem>
                  <b>Account Service</b> – a digital service within the meaning of the provisions of
                  the Act on consumer rights, consisting in the creation and maintenance of an
                  Account by the Service Provider for the User;
                </ListItem>
                <ListItem>
                  <b>Act on the provision of electronic services</b> - a term defined in § 1 section
                  2 of the Regulations;
                </ListItem>
                <ListItem>
                  <b>Agreement</b> – Agreement for the provision of the Account Service and the
                  Agreement for the provision of the myDAPPwallet Service;
                </ListItem>
                <ListItem>
                  <b>Agreement for the provision of the Account Service</b> – an agreement for the
                  provision of a digital service within the meaning of the Act on consumer rights,
                  under which the Service Provider undertakes to provide the Account Service to the
                  User for an indefinite period of time, and the User undertakes to provide the
                  Service Provider with his data, including personal data;
                </ListItem>
                <ListItem>
                  <b>Agreement for the provision of the myDAPPwallet Service</b> – an agreement for
                  the provision of a digital service within the meaning of the Act on consumer
                  rights, under which the Service Provider undertakes to provide the Service
                  Recipient with the myDAPPwallet Service for a fee (commission); the condition for
                  concluding the Agreement for the provision of the Service for the use of the
                  Application is the prior conclusion of the Agreement for the provision of the
                  Account Service;
                </ListItem>
                <ListItem>
                  <b>myDAPPwallet Service</b> – a digital service within the meaning of the Act on
                  consumer rights, consisting in enabling the Client to use the functionality of the
                  Application consisting in: providing custody wallet services, order to purchase of
                  products and services in the WEB3 ecosystem;
                </ListItem>
                <ListItem>
                  <b>Civil Code</b> - a term defined in § 1 section 6 of the Terms and Conditions;
                </ListItem>
                <ListItem>
                  <b>Consumer</b> - a natural person performing a legal transaction with the Service
                  Provider not directly related to its business or professional activity;
                </ListItem>
                <ListItem>
                  <b>Consumer Rights Act</b> - the Act of 30 May 2014 on consumer rights;
                </ListItem>
                <ListItem>
                  <b>Digital Service</b> – Account Service and myDAPPwallet Service;
                </ListItem>
                <ListItem>
                  <b>Entrepreneur</b> - a natural person, a legal person or an organizational unit
                  without legal personality, to which special provisions grant legal capacity,
                  conducting business or professional activity on its own behalf;
                </ListItem>
                <ListItem>
                  <b>Entrepreneur with Consumer Rights</b> - a natural person conducting business or
                  professional activity on his own behalf, who has concluded an Agreement with the
                  Service Provider directly related to his business activity, but not having a
                  professional character for that person, resulting in particular from the subject
                  of his business activity;
                </ListItem>
                <ListItem>
                  <b>License</b> - term defined in § 9 section 7 of the Regulations;
                </ListItem>
                <ListItem>
                  <b>Non-compliance</b> – it is understood as non-compliance of the Subject of the
                  Digital Service with the Agreement regarding its provision (the criteria for
                  assessing the compliance of the Subject of the Digital Service with the agreement
                  regarding its delivery are set out in Article 43k paragraphs 1-2 of the Act on
                  consumer rights);
                </ListItem>
                <ListItem>
                  ) <b>Price List</b> – a document or information specifying the current commission
                  of the myDAPPwallet Service;
                </ListItem>
                <ListItem>
                  <b>Privacy Policy</b> - a document containing information on the processing of
                  personal data of Service Recipients by the Service Provider;
                </ListItem>
                <ListItem>
                  <b>Review</b> – the Client&apos;s opinion on the Service of using the Application,
                  including a description of the Client&apos;s experience related to the use of the
                  above-mentioned Services;
                </ListItem>
                <ListItem>
                  <b>Service Provider</b> – a term defined in § 1 section 3 of the Regulations;
                </ListItem>
                <ListItem>
                  <b>Service Recipient or Client</b> – a client using the Application, being an
                  Entrepreneur, Entrepreneur with Consumer rights or Consumer;
                </ListItem>
                <ListItem>
                  <b>Subject of the Digital Service</b> – Account Service or myDAPPwallet Service;
                </ListItem>
                <ListItem>
                  <b>Terms and Conditions or Regulations</b> - a term defined in § 1 section 1 of
                  the Regulations;
                </ListItem>
                <ListItem>
                  <b>User</b> – a person who has an Account in the Application who is a Consumer or
                  a person authorized to represent the Service Recipient and use the Application on
                  his behalf.
                </ListItem>
                <ListItem>
                  <b>User Content</b> – all data (including personal data), electronic files,
                  information and materials recorded by the User on the Account.
                </ListItem>
              </List>
            </SuiTypography>
          </SuiBox>

          <SuiBox mt={5} id="technical">
            <SuiTypography
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              § 3.
            </SuiTypography>
            <SuiTypography
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              Technical requirements
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1}>
            <SuiTypography variant="inherit" fontWeight="medium">
              <List
                sx={{
                  listStyleType: "decimal",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  To properly use the services provided by the Service Provider via the App, all of
                  the following requirements must be satisfied jointly:
                  <List
                    sx={{
                      listStyleType: "none",
                      pl: 2,
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem>1) connection to the Internet;</ListItem>
                    <ListItem>2) equipment allowing the use of Internet resources;</ListItem>
                    <ListItem>
                      3) the current version of the web browser allowing hypertext documents to be
                      displayed on the screen of the device, linked to the Internet via the World
                      Wide Web service and supporting the JavaScript programming language, as well
                      as accepting cookies;
                    </ListItem>
                    <ListItem>4) an active e-mail account and telephone number.</ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  The use of viruses, bots, worms or other computer codes, files or programmes (in
                  particular process
                </ListItem>
                <ListItem>
                  The Service Provider informs that it uses cryptographic protection of electronic
                  transfer and digital content by means of appropriate logical, organisational and
                  technical measures, in particular to third-party access to the data, including by
                  SSL encryption, use of passwords and anti-virus or anti-malware programmes.
                </ListItem>
                <ListItem>
                  The Service Provider advises that despite the safeguards referred to in Section 3
                  above, the use of the Internet and services provided by electronic means may be at
                  risk of malicious software entering the Client&apos;s computer system and device
                  or of third parties gaining access to the data on that device. In order to
                  minimise the aforementioned risk, the Service Provider recommends the use of
                  anti-virus programmes or means of protecting identification on the Internet.
                </ListItem>
              </List>
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={5}>
            <SuiTypography
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              § 4.
            </SuiTypography>
            <SuiTypography
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              General principles of Service provision
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1}>
            <SuiTypography variant="inherit" fontWeight="medium">
              <List
                sx={{
                  listStyleType: "decimal",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  The Service Recipient is obliged to use the services provided by the Service
                  Provider in a manner consistent with the provisions of generally applicable law,
                  the provisions of the Regulations, as well as with good customs.
                </ListItem>
                <ListItem>Providing illegal content by the User is prohibited.</ListItem>
                <ListItem>
                  The Service Recipient using the services provided by the Service Provider is
                  obliged to provide only data (including personal data) consistent with the actual
                  state. The Service Provider shall not be liable for the consequences of providing
                  false or incomplete data by the Client.
                </ListItem>
                <ListItem>
                  If the activities in the Application (in particular the conclusion of the
                  Agreement) are performed by a natural person acting on behalf of the Client who is
                  not a natural person, performing such an action is tantamount to submitting by the
                  natural person performing such an action a statement that it is entitled to
                  represent the Service Recipient. The Service Provider is entitled to require such
                  a natural person to provide proof of its authorization to represent the Client, in
                  particular a power of attorney document or an extract from the relevant register.
                  In the case of performing an action on behalf of the Client despite the lack of
                  authorization to represent him, the natural person performing this action bears
                  the responsibility provided for in the provisions of the Civil Code.{" "}
                </ListItem>
                <ListItem>
                  The conclusion of the Agreement for the provision of the Service for the use of
                  the Application requires the prior conclusion of the Agreement for the provision
                  of the Account Service.
                </ListItem>
                <ListItem>
                  The following shall be considered a violation of the Regulations:
                  <List
                    sx={{
                      listStyleType: "none",
                      pl: 2,
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem>1) providing illegal content by the User;</ListItem>
                    <ListItem>2) misuse of the Digital Services;</ListItem>
                    <ListItem>
                      3) providing false or incomplete data by the Service Recipient or User;
                    </ListItem>
                    <ListItem>
                      4) a natural person performs an action on behalf of the Client despite the
                      lack of authorization to represent him;
                    </ListItem>
                    <ListItem>
                      5) attempt of unauthorized interference in the KYC process carried out by the
                      Service Provider.
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  In the event of a violation of the Regulations, the Service Provider may call the
                  Client to remove it and set a deadline of not less than 7 (seven) days for this
                  purpose.{" "}
                </ListItem>
                <ListItem>
                  All statements, calls, notifications, and information referred to in the
                  Regulations may be submitted by e-mail, unless a specific provision of the
                  Regulations provides otherwise.
                </ListItem>
              </List>
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={5}>
            <SuiTypography
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              § 5.
            </SuiTypography>
            <SuiTypography
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              Account Service Agreement
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1}>
            <SuiTypography variant="inherit" fontWeight="medium">
              <List
                sx={{
                  listStyleType: "decimal",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  In order to conclude the Agreement for the provision of the Account Service, the
                  User should perform the following actions:
                  <List
                    sx={{
                      listStyleType: "none",
                      pl: 2,
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem>
                      1) enter the website of the Application and then click on the &quot;Sign
                      Up&quot; tab;
                    </ListItem>
                    <ListItem>2) in the form that appears, enter the telephone number;</ListItem>
                    <ListItem>
                      3) it is obligatory to mark the checkbox next to the statement of reading the
                      Regulations and Privacy Policy and accepting their provisions;
                    </ListItem>
                    <ListItem>4) click &quot;Sign Up&quot;.</ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  The User may also sign up for the App through your account for Google, Apple, or
                  Facebook services.
                </ListItem>
                <ListItem>
                  After clicking the &quot;Register&quot; option, a code to activate the Account is
                  sent to the to telephone number provided by the User. After clicking on the link,
                  the User gains access to the Account. Clicking on the sent link by the User is
                  tantamount to the conclusion by the User of the Agreement for the provision of the
                  Account Service.
                </ListItem>
                <ListItem>
                  After creating an Account, the User may complete the data stored on it by:
                  <List
                    sx={{
                      listStyleType: "none",
                      pl: 2,
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem>1) e-mail address;</ListItem>
                    <ListItem>2) name;</ListItem>
                    <ListItem>3) surname;</ListItem>
                    <ListItem>4) login;</ListItem>
                    <ListItem>5) billing address;</ListItem>
                    <ListItem>6) addresses of owned cryptocurrency wallets;</ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  With the help of the Account, the User may in particular:
                  <List
                    sx={{
                      listStyleType: "none",
                      pl: 2,
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem>
                      1) store their data and the data of the Client represented by him;{" "}
                    </ListItem>
                    <ListItem>2) buy products or serivces on WEB3 ecosystem.</ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  The Service Provider informs, and the Client acknowledges that maintaining
                  compliance of the Account Service with the Agreement for the provision of the
                  Account Service does not require the Client to install its updates.{" "}
                </ListItem>
                <ListItem>
                  If the User is not granted access to the Account immediately after the conclusion
                  of the Agreement for the provision of the Account Service, the User calls the
                  Service Provider to immediately grant access to the Account. The summons referred
                  to in the preceding sentence may be sent by e-mail to the address indicated in § 1
                  section 4 point 1 of the Regulations. In the event that the Service Provider does
                  not grant the User access to the Account immediately after receiving the request
                  referred to in the preceding sentence, the User may withdraw from the Agreement
                  for the provision of the Account Service.
                </ListItem>
                <ListItem>
                  Notwithstanding the provisions of paragraph 6 above, in the absence of access to
                  the Account to the User, the User may withdraw from the Agreement for the
                  provision of the Account Service without calling the Service Provider to grant
                  access to the Account, if at least one of the cases indicated in Article 43j
                  paragraph 5 of the Act on consumer rights occurs.
                </ListItem>
                <ListItem>
                  The provisions of sections 6-7 above apply only to Service Recipients who are
                  Consumers or Entrepreneurs with Consumer rights.
                </ListItem>
                <ListItem>
                  Notwithstanding the provisions of sections 7-8 above, the User may at any time and
                  without giving a reason terminate the Agreement for the provision of the Account
                  Service with a notice period of 14 (fourteen) days.
                </ListItem>
                <ListItem>
                  Withdrawal by the User from the Agreement for the provision of the Account Service
                  or its termination, regardless of the basis for this action, takes place by
                  submitting to the Service Provider a statement of withdrawal from the Agreement
                  for the provision of the Account Service or its termination. The statement
                  referred to in the preceding sentence may be sent by e-mail to the address
                  indicated in § 1 section 4 point 1 of the Regulations. The Service Provider
                  deletes the Account immediately after receiving the statement referred to in the
                  preceding sentence, or after the expiry of the notice period indicated in the
                  Regulations.
                </ListItem>
                <ListItem>
                  In the event of a breach by the User of the provisions of the Regulations and
                  failure to remove this violation despite receiving the request referred to in § 4
                  section 9 of the Regulations, the Service Provider may terminate the Agreement for
                  the provision of the Account Service with a notice period of 7 (seven) days, by
                  submitting a statement of termination to the Client by e-mail. After the expiry of
                  the notice period indicated in the preceding sentence, the Account is permanently
                  deleted. During the notice period, the Service Provider may block the User&apos;s
                  access to the Account if it is necessary to prevent further violations by the
                  User.
                </ListItem>
                <ListItem>
                  13. If, before withdrawing from the Agreement for the provision of the Account
                  Service or its termination, regardless of the basis for this action, the Service
                  Recipient concluded an Agreement for the provision of the Service for the use of
                  the Application, withdrawal from the Agreement for the provision of the Account
                  Service or its termination by either party is tantamount to simultaneous
                  withdrawal from the Agreement for the provision of the Application Use Service and
                  its termination.
                </ListItem>
                <ListItem>
                  14. If the Service Provider terminates the Agreement for the provision of the
                  Account Service to the User who is a Consumer or Entrepreneur with the rights of a
                  Consumer, the User who is a Consumer is entitled to a refund of part of the
                  remuneration in the amount proportional to the period remaining from the end of
                  the notice period specified in the Agreement for the provision of the Account
                  Service.
                </ListItem>
              </List>
            </SuiTypography>
          </SuiBox>

          <SuiBox mt={5}>
            <SuiTypography
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              § X.
            </SuiTypography>
            <SuiTypography
              variant="h5"
              fontWeight="bold"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              XX
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1}>
            <SuiTypography variant="inherit" fontWeight="medium">
              <List
                sx={{
                  listStyleType: "decimal",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>xxxxx</ListItem>
                <ListItem>
                  XXX
                  <List
                    sx={{
                      listStyleType: "none",
                      pl: 2,
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem>1) XXX</ListItem>
                  </List>
                </ListItem>
              </List>
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </Card>
    </CoverLayout>
  );
}

Privacy.propTypes = {};

export default Privacy;
