import React from "react";

import PropTypes from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// @mui material components
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";

function Row({ row, index, handleEditBook, handleDeleteBook, t }) {
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>{index + 1}</TableCell>
        <TableCell component="th" scope="row">
          {row.name} <SuiIconCopyToClipboard text={row.name} name={t("name")} />
        </TableCell>
        <TableCell align="left">
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            {row.address.slice(0, 10)} ... {row.address.slice(-10)}{" "}
            <SuiIconCopyToClipboard text={row.address} name={t("address")} />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {row.address} <SuiIconCopyToClipboard text={row.address} name={t("address")} />
          </Box>
        </TableCell>
        <TableCell align="center">
          <ToggleButtonGroup orientation="horizontal">
            <ToggleButton
              value="edit"
              onClick={() => {
                handleEditBook(index, row);
              }}
            >
              <EditIcon />
            </ToggleButton>
            <ToggleButton
              value="delete"
              onClick={() => {
                handleDeleteBook(index, row);
              }}
            >
              <DeleteIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,
  handleEditBook: PropTypes.func.isRequired,
  handleDeleteBook: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Row);
