/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { Trans } from "react-i18next";
// react-router-dom components
// import { Link, Redirect, useParams } from "react-router-dom";

// PropTypes
// import PropTypes from "prop-types";

// i18n
import i18n, { t } from "i18next";
// import { Trans, withTranslation } from "react-i18next";
import profileEN from "locales/en/profile.json";

import * as Yup from "yup";
import { Formik, Form } from "formik";

// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";
import SuiTypography from "components/sui/SuiTypography";
import SuiFormField from "components/sui/SuiFormField";
import SuiAlert from "components/sui/SuiAlert";

import * as profile from "actions/profile";
import DefaultLayout from "components/layout/LayoutContainers/DefaultLayout";

// eslint-disable-next-line react/prop-types
const ChangePasswordForm = ({ changePassword }) => {
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(false);
  const [submit, setSubmit] = useState(false);
  const formik = useRef(null);

  useEffect(() => {
    i18n.addResources("en", "profile", profileEN);
  }, []);

  const onSubmited = (_error, result, _info) => {
    if (_error) {
      switch (_error.code) {
        case 4600:
          formik.current.errors.oldPassword = t("errors:4600");
          break;
        default:
          setError(_error);
      }
    } else {
      setInfo(_info);
      formik.current.resetForm();
    }
    setSubmit(false);
  };

  const onSubmit = ({ oldPassword, newPassword, confirmPassword }) => {
    setError(false);
    setInfo(false);
    setSubmit(true);
    changePassword({ oldPassword, newPassword, confirmPassword }, onSubmited);
  };

  return (
    <DefaultLayout>
      <SuiBox py={3} minHeight="calc(100vh - 114px - 23px)">
        <SuiTypography variant="h3" sx={{ marginBottom: "1rem" }}>
          Change password
        </SuiTypography>
        <Card id="password" sx={{ width: "100%" }}>
          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={Yup.object().shape({
              oldPassword: Yup.string().required("Old password is required"),
              newPassword: Yup.string().required("New password is required"),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                .required("Confirm password is required"),
            })}
            onSubmit={onSubmit}
            innerRef={formik}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit} autocomplete="off">
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }} p={2}>
                  <Grid container spacing={3} xs={12} sm={11} md={8} lg={6}>
                    <Grid item xs={12} sm={12}>
                      <Grid item>
                        <SuiFormField
                          type="password"
                          label={t("profile:old-password")}
                          placeholder="Old password"
                          name="oldPassword"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.oldPassword}
                          error={errors.oldPassword && touched.oldPassword}
                          helperText={
                            errors.oldPassword && touched.oldPassword && errors.oldPassword
                          }
                          disabled={submit}
                        />
                      </Grid>
                      <Grid item mt={2}>
                        <SuiFormField
                          type="password"
                          label={t("profile:new-password")}
                          placeholder="New password"
                          name="newPassword"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newPassword}
                          error={errors.newPassword && touched.newPassword}
                          helperText={
                            errors.newPassword && touched.newPassword && errors.newPassword
                          }
                          disabled={submit}
                        />
                      </Grid>
                      <Grid item mt={2}>
                        <SuiFormField
                          type="password"
                          label={t("profile:confirm-password")}
                          placeholder="Confirm password"
                          name="confirmPassword"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          error={errors.confirmPassword && touched.confirmPassword}
                          helperText={
                            errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword
                          }
                          disabled={submit}
                        />
                      </Grid>
                      {error && (
                        <Grid item mt={3}>
                          <SuiBox>
                            <SuiAlert alert={error} color="primary">
                              <Trans>{`error-${error.code}`}</Trans>
                            </SuiAlert>
                          </SuiBox>
                        </Grid>
                      )}
                      {info && (
                        <Grid item mt={3}>
                          <SuiBox>
                            <SuiAlert alert={info} color="info">
                              <Trans>{`infos:${info.code}`}</Trans>
                            </SuiAlert>
                          </SuiBox>
                        </Grid>
                      )}
                      <Grid item mt={2}>
                        <SuiFormField
                          type="submit"
                          variant="contained"
                          color="info"
                          style={{ width: "100%" }}
                          component={SuiButton}
                          disabled={submit}
                        >
                          <Trans>profile:change-password</Trans>
                        </SuiFormField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Card>
      </SuiBox>
    </DefaultLayout>
  );
};
const mapDispatchToProps = (dispatch) => ({
  changePassword: (values, callback) => dispatch(profile.changePassword(values, callback)),
});

export default connect(null, mapDispatchToProps)(ChangePasswordForm);
