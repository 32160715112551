/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";

import PropTypes from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Soft UI Dashboard PRO React components
import SuiButton from "components/sui/SuiButton";

// Moment
import Moment from "react-moment";

import DetailsRow from "layouts/asset/evm/tabs/History/DetailsRow";
// Utils
import * as CryptoUtil from "utils/CryptoUtil";

// Constants
import Constants from "constants/Constants";

const { TRANSACTION_STATUSES } = Constants;

function Row({ row, asset, open, handleOpen }) {
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => handleOpen(row.id)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Moment format={Constants.DATE_FORMAT}>{row.createDate * 1000}</Moment>
          <br />
          <Moment format={Constants.TIME_FORMAT}>{row.createDate * 1000}</Moment>
        </TableCell>
        <TableCell align="left">
          <Trans values={row}>{`crypto:transaction-type-${row.type}`}</Trans>
        </TableCell>
        <TableCell align="right">
          <h4>
            {CryptoUtil.toAmountAsString(row.value, row.decimals || asset.decimals)} {row.symbol}
          </h4>
        </TableCell>
        <TableCell align="center">
          <>
            {row.status === TRANSACTION_STATUSES.NEW && (
              <SuiButton variant="gradient" color="secondary" disabled>
                <Trans>crypto:transaction-status-0</Trans>
              </SuiButton>
            )}
            {row.status === TRANSACTION_STATUSES.REJECTED && (
              <SuiButton variant="gradient" color="info" disabled>
                <Trans>crypto:transaction-status-1</Trans>
              </SuiButton>
            )}
            {row.status === TRANSACTION_STATUSES.PENDING && (
              <SuiButton variant="gradient" color="warning" disabled>
                <Trans>crypto:transaction-status-2</Trans>
              </SuiButton>
            )}
            {row.status === TRANSACTION_STATUSES.FAILED && (
              <SuiButton variant="gradient" color="error" disabled>
                <Trans>crypto:transaction-status-3</Trans>
              </SuiButton>
            )}
            {row.status === TRANSACTION_STATUSES.FINISHED && (
              <SuiButton variant="gradient" color="success" disabled>
                <Trans>crypto:transaction-status-4</Trans>
              </SuiButton>
            )}
          </>
        </TableCell>
      </TableRow>
      <DetailsRow id={row.id} open={open} asset={asset} />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    decimals: PropTypes.number,
    type: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    createDate: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
  asset: PropTypes.shape({ decimals: PropTypes.number.isRequired }).isRequired,
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default withTranslation()(Row);
