/* eslint-disable jsx-a11y/anchor-is-valid */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSoftUIController } from "context";
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiAlert from "components/sui/SuiAlert";
import SuiTypography from "components/sui/SuiTypography";
import SuiNavbarLink from "components/sui/SuiNavbarLink";
import SuiButton from "components/sui/SuiButton";
import SuiPrice from "components/sui/SuiPrice";
// Send components
import FromField from "layouts/asset/evm/tabs/Send/components/FromField";
import FromMenu from "layouts/asset/evm/tabs/Send/components/FromMenu";
import ToField from "layouts/asset/evm/tabs/Send/components/ToField";
import AmountField from "layouts/asset/evm/tabs/Send/components/AmountField";
import TokenAmountField from "layouts/asset/evm/tabs/Send/components/TokenAmountField";

import GasPriceField from "layouts/asset/evm/tabs/Send/components/GasPriceField";
import GasLimitField from "layouts/asset/evm/tabs/Send/components/GasLimitField";
import CurrencyMenu from "components/layout/CurrencyMenu";
import * as Yup from "yup";
import { Formik } from "formik";

// Actions
import * as _web3 from "actions/web3";

// Images
import sendMoneyIcon from "assets/images/icons/send-money.svg";

// Constants
import Constants from "constants/Constants";

// Utils
import * as CryptoUtil from "utils/CryptoUtil";

const utils = require("web3-utils");

const { TRANSACTION_TYPES } = Constants;
const { TRANSACTION_STATUSES } = Constants;
const { DEFAULT_GAS_LIMIT } = Constants;

function Stage1({ name, asset, currency, setCurrency, t }) {
  const dispatch = useDispatch();
  const addressBook = useSelector((state) => state.appStore.addressBook);
  const exchangeRates = useSelector((state) => state.appStore.exchangeRates.exchanges[name].price);
  const exchangeRate = exchangeRates[currency];
  // eslint-disable-next-line no-unused-vars
  const [balance, setBalance] = useState(useSelector((state) => state.appStore.balance[name]));
  const balanceEVM = useSelector((state) => state.appStore.balance[name]);
  const gasPrice = useSelector((state) => state.appStore.gasPrice[name]);

  const initialValues = {
    to: "",
    value: 0,
    gasPrice,
    gasLimit: DEFAULT_GAS_LIMIT.WEB3.SEND,
  };

  const [_asset, setAsset] = useState(asset);
  const [error, setError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [fromOpen, setFromOpen] = useState(false);
  const [currencyOpen, setCurrencyOpen] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const formik = useRef(null);
  const fromRef = useRef();

  const handleOpenFromMenu = ({ currentTarget }) => {
    setFromOpen(currentTarget);
    setAsset(asset);
    setBalance(balanceEVM);
  };

  const handleCloseFromMenu = (__asset) => {
    if (fromOpen) {
      setFromOpen(false);
      setAsset(__asset);
      formik.current.setFieldValue("value", 0, true);
      formik.current.setFieldTouched("value", false);
      if (__asset.type === "EVM") {
        formik.current.setFieldValue("gasLimit", DEFAULT_GAS_LIMIT.WEB3.SEND, true);
        setBalance(balanceEVM);
      } else {
        formik.current.setFieldValue("gasLimit", DEFAULT_GAS_LIMIT.WEB3.TRANSFER_TOKEN, true);
        setBalance(__asset.balance);
      }
    }
  };

  const handleOpenCurrencyMenu = ({ currentTarget }) => {
    setCurrencyOpen(currentTarget);
  };

  const handleCloseCurrencyMenu = (_currency) => {
    setCurrencyOpen(false);
    if (_currency) {
      setCurrency(_currency);
    }
  };

  const handleAmountChange = (amount) => {
    const value = CryptoUtil.toValueAsString(amount, _asset.decimals); // parseInt(amount * 10 ** asset.decimals, 10);
    formik.current.setFieldValue("value", value);
  };

  const handleCurrencyAmountChange = (value) => {
    const amount = ((value / exchangeRate) * 10 ** asset.decimals).toFixed(0);
    formik.current.setFieldValue("value", amount);
  };

  const handleChangeTo = (address) => {
    formik.current.setFieldValue("to", address, true);
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const filterOptions = (options, state) => {
    // eslint-disable-next-line no-undef
    const filteredOptions = options.filter(
      (item) =>
        item.name.indexOf(state.inputValue) >= 0 || item.address.indexOf(state.inputValue) >= 0
    );
    return filteredOptions;
  };

  const validateAddress = (value) => {
    if (!value) return true;
    switch (asset.type) {
      case "EVM":
        return asset.web3.utils.isAddress(value);
      default:
        return false;
    }
  };
  const validationSchema = () =>
    Yup.object().shape({
      to: Yup.string()
        .required(t("yup:required", { name: t("crypto:to") }))
        .test({
          name: "to",
          exclusive: true,
          message: t("yup:format", { name: t("crypto:to") }),
          test: validateAddress,
        }),
      value: Yup.number()
        .typeError(t("yup:moreThen", { name: t("crypto:amount"), value: 0 }))
        .moreThan(
          0,
          t("yup:moreThen", {
            name: t("crypto:amount"),
            value: 0,
          })
        )
        .max(
          balance,
          t("yup:maxNumber", {
            name: t("crypto:amount"),
            value: (balance / 10 ** _asset.decimals).toFixed(10).replace(/(\.0+|0+)$/, ""),
          })
        ),
      gasPrice: Yup.number()
        .typeError(t("yup:minNumber", { name: t("crypto:gasPrice"), value: 0 }))
        .min(0, t("yup:minNumber", { name: t("crypto:gasPrice"), value: 0 })),
      gasLimit: Yup.number()
        .typeError(t("yup:moreThen", { name: t("crypto:gasLimit"), value: 0 }))
        .moreThan(0, t("yup:moreThen", { name: t("crypto:gasLimit"), value: 0 })),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  const onSubmited = (_error) => {
    setError(_error);
    setSubmit(false);
  };
  const onSubmit = (values) => {
    const from = window.mydappwallet.wallet.EVM;
    setSubmit(true);
    setError(false);
    if (_asset.type === "EVM") {
      dispatch(
        _web3.sendTransaction(
          name,
          {
            ...values,
            from,
            gasPrice: utils.toWei(utils.toBN(values.gasPrice), "Gwei"),
            type: TRANSACTION_TYPES.SEND,
            status: TRANSACTION_STATUSES.NEW,
            symbol: _asset.symbol,
            decimals: _asset.decimals,
          },
          onSubmited
        )
      );
    } else {
      dispatch(
        _web3.sendTransaction(
          name,
          {
            ...values,
            from,
            gasPrice,
            token: _asset.address,
            type: TRANSACTION_TYPES.TRANSFER_TOKEN,
            status: TRANSACTION_STATUSES.NEW,
            symbol: _asset.symbol,
            decimals: _asset.decimals,
          },
          onSubmited
        )
      );
    }
  };

  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;

  return (
    <Grid container spacing={2}>
      <Grid spacing={2} item xs={12} sm={11} md={8} lg={6} xl={miniSidenav ? 6 : 8}>
        <Formik
          initialValues={initialValues}
          validate={validate(validationSchema)}
          onSubmit={onSubmit}
          innerRef={formik}
        >
          {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
            <SuiBox component="form" onSubmit={handleSubmit} noValidate name="sendForm">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Grid item>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        color="info"
                      >
                        <Trans>crypto:from</Trans>
                      </SuiTypography>
                    </SuiBox>
                    <FromField
                      fromOpen={fromOpen}
                      asset={_asset}
                      balance={balance}
                      handleOpenFromMenu={handleOpenFromMenu}
                      handleCloseFromMenu={handleCloseFromMenu}
                      ref={fromRef}
                    />

                    <FromMenu
                      anchorEl={fromOpen}
                      close={handleCloseFromMenu}
                      style={{
                        width: fromRef.current ? fromRef.current.offsetWidth : 300,
                      }}
                    />
                  </Grid>
                  <Grid item mt={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        color="info"
                      >
                        <Trans>crypto:to</Trans>
                      </SuiTypography>
                    </SuiBox>
                    <ToField
                      addressBook={addressBook}
                      filterOptions={filterOptions}
                      value={values.to}
                      touched={touched.to}
                      error={errors.to}
                      onChange={handleChangeTo}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item mt={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        color="info"
                      >
                        <Trans>crypto:amount</Trans>
                        {_asset.type !== "erc20" && !_asset.test ? (
                          <SuiNavbarLink
                            name={currency}
                            underline="hover"
                            openHandler={handleOpenCurrencyMenu}
                            closeHandler={handleCloseCurrencyMenu}
                            open={currencyOpen}
                            sx={{ cursor: "pointer", userSelect: "none", fontWeight: 400 }}
                            click
                          />
                        ) : null}
                      </SuiTypography>
                      {_asset.type !== "erc20" && !_asset.test ? (
                        <CurrencyMenu
                          close={handleCloseCurrencyMenu}
                          anchorEl={currencyOpen}
                          currencies={exchangeRates}
                        />
                      ) : null}
                    </SuiBox>
                    {_asset.type !== "erc20" && !_asset.test ? (
                      <AmountField
                        value={values.value}
                        error={errors.value}
                        touched={touched.value}
                        changeValue1={handleAmountChange}
                        changeValue2={handleCurrencyAmountChange}
                        onBlur={handleBlur}
                        currency1={asset.symbol}
                        currency2={currency}
                        exchangeRate={exchangeRate}
                        decimals={asset.decimals}
                      />
                    ) : (
                      <TokenAmountField
                        value={values.value}
                        error={errors.value}
                        touched={touched.value}
                        changeValue={handleAmountChange}
                        onBlur={handleBlur}
                        symbol={_asset.symbol}
                        decimals={_asset.decimals}
                      />
                    )}
                  </Grid>
                  <Grid item mt={3}>
                    <SuiTypography variant="inherit" textTransform="capitalize">
                      <Trans>crypto:available</Trans>{" "}
                      <Link
                        underline="hover"
                        onClick={() => {
                          formik.current.setFieldValue("value", balance);

                          return false;
                        }}
                      >
                        {_asset.type === "ERC20" || _asset.test ? (
                          <>
                            {CryptoUtil.toAmountAsString(balance, _asset.decimals)} {_asset.symbol}
                          </>
                        ) : (
                          <>
                            <SuiPrice name={name} currency={currency} /> {currency}
                          </>
                        )}
                      </Link>
                    </SuiTypography>
                  </Grid>
                  <Grid item mt={3}>
                    <Link
                      underline="hover"
                      component="div"
                      onClick={() => {
                        setAdvanced(!advanced);
                        return false;
                      }}
                    >
                      {!advanced ? (
                        <Trans>advanced-options</Trans>
                      ) : (
                        <Trans>hide-advanced-options</Trans>
                      )}
                    </Link>
                  </Grid>
                  <Collapse in={advanced}>
                    {advanced && (
                      <>
                        <Grid item>
                          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SuiTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                              color="info"
                            >
                              <Trans>crypto:gasPrice</Trans>
                            </SuiTypography>
                          </SuiBox>
                          <GasPriceField
                            value={values.gasPrice}
                            error={errors.gasPrice}
                            touched={touched.gasPrice}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item mt={3}>
                          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SuiTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                              color="info"
                            >
                              <Trans>crypto:gasLimit</Trans>
                            </SuiTypography>
                          </SuiBox>
                          <GasLimitField
                            value={values.gasLimit}
                            error={errors.gasLimit}
                            touched={touched.gasLimit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </>
                    )}
                  </Collapse>
                  <Divider variant="middle" />
                  {error && (
                    <Grid mt={3}>
                      <SuiBox>
                        <SuiAlert alert={error} color="primary">
                          <Trans>{`errors:${error.code}`}</Trans>
                        </SuiAlert>
                      </SuiBox>
                    </Grid>
                  )}
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SuiTypography component="label" variant="caption" textTransform="capitalize">
                      <Trans values={{ one: 1, two: 3 }}>step-of</Trans>
                    </SuiTypography>
                    <Grid item xs={5}>
                      <SuiButton
                        name="submit"
                        disabled={submit}
                        component={SuiButton}
                        onClick={() => {
                          formik.current.submitForm();
                        }}
                        variant="gradient"
                        color="info"
                        fullWidth
                      >
                        <Trans>next</Trans>
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SuiBox>
          )}
        </Formik>
      </Grid>
      <Grid
        mt={5}
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        md={4}
        lg={6}
        xl={miniSidenav ? 6 : 4}
        sx={{ display: { md: "block", sm: "none" } }}
      >
        <SuiBox
          component="img"
          src={sendMoneyIcon}
          alt="Send Money"
          mb={3}
          width="80%"
          ml="auto"
          mr="auto"
          display="block"
          sx={{ maxWidth: 200 }}
        />
        <SuiTypography component="p" variant="caption" align="center" noWrap width="100%">
          <Trans>crypto:tab-send-subtitle</Trans>
        </SuiTypography>
      </Grid>
    </Grid>
  );
}

Stage1.propTypes = {
  name: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    gasPrice: PropTypes.number,
    decimals: PropTypes.number,
    symbol: PropTypes.string,
    type: PropTypes.string,
    web3: PropTypes.shape({
      utils: PropTypes.shape({ isAddress: PropTypes.func }),
    }),
  }).isRequired,
  currency: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Stage1);
