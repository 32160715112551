/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect, useSelector } from "react-redux";
import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// @mui material components
import MenuList from "@mui/material/MenuList";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import { OutlinedInput, FormControl } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiNavbarMenu from "components/sui/SuiNavbarMenu";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

// @ Mui icons
import SearchIcon from "@mui/icons-material/Search";

import borders from "assets/theme/base/borders";

const { borderColor, borderRadius } = borders;

function AssetMenu({ anchorEl, close, mobileMenu, t }) {
  const assets = useSelector((state) => (state.appStore.assets ? state.appStore.assets : {}));
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState("");
  const renderDocsMenuRoute = () => (
    <>
      <SuiBox sx={{ boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem" }} mb={1}>
        <FormControl sx={{ width: "30ch" }}>
          <OutlinedInput
            onChange={(e) => setSearch(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            flex={1}
          />
        </FormControl>
      </SuiBox>

      <MenuList
        autoFocusItem={anchorEl}
        id="composition-menu"
        aria-labelledby="composition-button"
        style={{ maxHeight: 10 * 55, overflow: "auto" }}
        sx={{
          maxHeight: 20 * 4.5,
          width: "30ch",
        }}
      >
        {Object.keys(assets).map((name) => {
          const asset = assets[name];
          return (
            <>
              {(search.trim().length === 0 ||
                name.toUpperCase().includes(search.toUpperCase()) ||
                t(`currencies:${name}`).toUpperCase().includes(search.toUpperCase())) && (
                <Grid item xs={12}>
                  <SuiBox
                    display="flex"
                    alignItems="left"
                    p={1}
                    onClick={() => {
                      close(name, asset);
                    }}
                    sx={{
                      "&:hover": {
                        background: "#eceeef",
                        borderRadius: borderRadius.lg,
                        borderColor,
                      },
                      cursor: "pointer",
                    }}
                  >
                    <SuiBox
                      sx={{ width: "4ch" }}
                      className="d-inline-block item-icon-wrapper"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: asset.icon }}
                      mx={0}
                    />

                    <SuiBox
                      display="flex"
                      flexDirection="column"
                      lineHeight={1}
                      ml={1}
                      sx={{ width: "11ch" }}
                    >
                      <SuiTypography variant="button" fontWeight="medium">
                        {" "}
                        {asset.symbol}
                      </SuiTypography>
                      <SuiTypography variant="caption" fontWeight="regular">
                        {asset.name}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      display="flex"
                      justifyContent="flex-end"
                      lineHeight={1}
                      mr={1}
                      sx={{ width: "15ch" }}
                    >
                      <SuiTypography variant="button" fontWeight="regular" mt={1}>
                        {" "}
                        {asset.subtitle}
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </Grid>
              )}
            </>
          );
        })}
      </MenuList>
    </>
  );

  return mobileMenu ? (
    renderDocsMenuRoute()
  ) : (
    <SuiNavbarMenu
      anchorEl={anchorEl}
      close={() => {
        close();
      }}
      style={{ maxHeight: "200px" }}
    >
      {renderDocsMenuRoute()}
    </SuiNavbarMenu>
  );
}

// Setting default values for the props of DocsMenu
AssetMenu.defaultProps = {
  mobileMenu: false,
  anchorEl: false,
  close: false,
};

// Typechecking props for the DocsMenu
AssetMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  mobileMenu: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssetMenu));
