/**
=========================================================
* Sui UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/Sui-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Suiware.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Sui UI Dashboard React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiBadge from "components/sui/SuiBadge";
import SuiButton from "components/sui/SuiButton";
import * as CryptoUtil from "utils/CryptoUtil";

function WalletManagementCryptoList({
  listItem,
  order,
  filter,
  hiddenItems,
  handleShow,
  handleHide,
  showZeroBalance,
  showTokens,
}) {
  // const showHiddenWallet = useSelector((state) => state.appStore.showHiddenWallet);
  const filterValue = filter.toUpperCase();
  // eslint-disable-next-line array-callback-return
  // eslint-disable-next-line consistent-return
  const renderlistItem = order.map((key) => {
    const { id, icon, name, symbol, balance, token, decimals } = listItem[key];
    // eslint-disable-next-line no-unused-vars
    const hide = hiddenItems.includes(key);
    const visible =
      (filterValue === "" ||
        name.toUpperCase().includes(filterValue) ||
        symbol.toUpperCase().includes(filterValue)) &&
      (showZeroBalance || balance > 0) &&
      (showTokens || !token);
    if (visible)
      return (
        <SuiBox
          key={id}
          component="li"
          className={`crypto__list-items ${!visible ? "hide-crypto-item" : ""}`}
        >
          <SuiBox className="crypto__list-items_wrap">
            <SuiBox className="crypto__list-items__rightData">
              <SuiBox mr={2} position="relative" className="crypto-avatar-block">
                {icon && (
                  <div
                    className="d-inline-block item-icon-wrapper"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: icon }}
                  />
                )}
              </SuiBox>
              <SuiBox className="crypto__list-items__rightData_top">
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <SuiTypography variant="h6" fontWeight="medium" className="crypto-item-title">
                    {name}
                    <SuiBadge
                      badgeContent={symbol}
                      variant="contained"
                      size="sm"
                      color="light"
                      container
                      className="badge_top"
                      fontWeight="bold"
                    />
                  </SuiTypography>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    className="crypto-price-qty"
                  >
                    <SuiTypography variant="h6" color="dark" class="crypto-qty">
                      {CryptoUtil.toAmount(balance, decimals)}
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </SuiBox>
            <SuiBox className="crypto-btn-block">
              {hide ? (
                <SuiButton
                  color="dark"
                  variant="outlined"
                  size="small"
                  className="crypto-btn"
                  onClick={() => handleShow(key)}
                >
                  Show
                </SuiButton>
              ) : (
                <SuiButton
                  color="dark"
                  variant="outlined"
                  size="small"
                  className="crypto-btn"
                  onClick={() => handleHide(key)}
                >
                  Hide
                </SuiButton>
              )}
            </SuiBox>
          </SuiBox>
        </SuiBox>
      );
    return null;
  });
  return <>{renderlistItem}</>;
}

// Setting default values for the props of Sidenav
WalletManagementCryptoList.defaultProps = {
  filter: "",
};

// Typechecking props for the CryptoList
WalletManagementCryptoList.propTypes = {
  listItem: PropTypes.arrayOf(PropTypes.object).isRequired,
  order: PropTypes.arrayOf(PropTypes.string).isRequired,
  filter: PropTypes.string,
  hiddenItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleShow: PropTypes.func.isRequired,
  handleHide: PropTypes.func.isRequired,
  showZeroBalance: PropTypes.bool.isRequired,
  showTokens: PropTypes.bool.isRequired,
};

export default WalletManagementCryptoList;
