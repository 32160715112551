/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components

import { PropTypes } from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTabPanel from "components/sui/SuiTabPanel";

// @mui material components
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Tabs

import Receive from "layouts/asset/iban/tabs/Receive";
// Actions

function a11yProps(index) {
  return {
    id: `web3-tab-${index}`,
    "aria-controls": `web3-tabpanel-${index}`,
  };
}

function IBAN({ name, asset, t }) {
  const [_name, setName] = useState(undefined);
  const [panel, setPanel] = useState(1);

  useEffect(() => {
    if (name !== _name) {
      setName(name);
    }
  }, []);

  return (
    <Card name="web3" sx={{ width: "100%" }}>
      <SuiBox sx={{ width: "100%" }}>
        <SuiBox sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={panel}
            onChange={(_event, newPanel) => {
              setPanel(newPanel);
            }}
            aria-label="web3 tabs"
          >
            <Tab label={t("crypto:tab-receive")} {...a11yProps(1)} />
          </Tabs>
        </SuiBox>

        <SuiTabPanel value={panel} index={1}>
          <Receive name={name} asset={asset} />
        </SuiTabPanel>
      </SuiBox>
    </Card>
  );
}
IBAN.propTypes = {
  name: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    chain: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    brokers: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(IBAN);
