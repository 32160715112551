/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// react-router-dom components
import { Redirect } from "react-router-dom";

// PropTypes
import PropTypes from "prop-types";

// i18n
import { Trans, withTranslation } from "react-i18next";

import * as Yup from "yup";
import { Formik, Form } from "formik";
// @mui material components
import Card from "@mui/material/Card";
import SuiCheckbox from "components/sui/SuiCheckbox";
import Grid from "@mui/material/Grid";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
import FormHelperText from "@mui/material/FormHelperText";
// Soft UI Dashboard PRO React components

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiCodeInput from "components/sui/SuiCodeInput";
import SuiInput from "components/sui/SuiInput";

// Authentication layout components
import Error from "components/layout/Error";
import Info from "components/layout/Info";
import Separator from "layouts/components/Separator";

import * as authentication from "actions/authentication";
import * as app from "actions/app";

// Authentication layout components
import BasicLayout from "layouts/components/BasicLayout";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";
// Icons
import PhonelinkOffIcon from "@mui/icons-material/PhonelinkOff";
import { isTablet, isDesktop, isMobile, isConsole, browserName, osName } from "react-device-detect";

// eslint-disable-next-line no-unused-vars
function Stage2({ refId, authenticator, oneTime, cancel, t }) {
  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.appStore);
  const [submit, setSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const formik = useRef(null);
  const codeInput = useRef(null);

  const deviceName = () => {
    if (isTablet) {
      return `Tablet ${osName} ${browserName}`;
    }
    if (isDesktop) {
      return `Computer ${osName} ${browserName}`;
    }
    if (isMobile) {
      return `Phone ${osName} ${browserName}`;
    }
    if (isConsole) {
      return `Console ${osName} ${browserName}`;
    }
    return "";
  };

  const initialValues = {
    deviceName: deviceName(),
    code: "",
    confirm: oneTime,
  };

  const handleSetConfirm = () => {
    if (!submit) {
      formik.current.setFieldValue("confirm", !formik.current.values.confirm);
    }
  };
  const handleChangeCode = (e) => {
    formik.current.setFieldValue("code", e);
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      deviceName: Yup.string().min(
        6,
        t("yup:min", { name: t("authentication:deviceName"), char: 6 })
      ),
      confirm: Yup.bool().oneOf([true], t("yup:confirm")),
      code: Yup.string().min(6, t("yup:min", { name: t("code"), char: 6 })),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  const onSubmited = (_error) => {
    if (_error) {
      switch (_error.code) {
        case 500:
          setRedirect("/error/500");
          break;
        case 630:
          formik.current.errors.deviceName = t(`errors:${_error.code}`);
          break;
        case 8200:
          formik.current.setFieldValue("code", initialValues.code, false);
          codeInput.current.reset();
          formik.current.errors.code = t(`errors:${_error.code}`);
          break;
        default:
          dispatch(app.error(_error));
          cancel();
      }
    }
    setSubmit(false);
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(app.errorClose());
    dispatch(app.infoClose());
    dispatch(authentication.signIn3({ ...values, oneTime, refId }, onSubmited));
    return true;
  };

  if (redirect) return <Redirect to={redirect} />;
  if (oneTime) {
    return (
      <BasicLayout
        title={t("authentication:welcome")}
        description={t("authentication:signin.title")}
        image={curved9}
      >
        <Formik
          initialValues={initialValues}
          validate={validate(validationSchema)}
          onSubmit={onSubmit}
          innerRef={formik}
        >
          {({ values, errors, touched, handleSubmit }) => (
            <Card>
              <Form onSubmit={handleSubmit} noValidate name="signInForm">
                <SuiBox p={3} textAlign="center">
                  <SuiTypography variant="h5" fontWeight="medium">
                    <Trans>authentication:signin.one-time-access.subtitle</Trans>!
                  </SuiTypography>
                </SuiBox>
                <SuiBox p={3}>
                  <Info
                    info={appStore.info}
                    color="info"
                    onClick={() => {
                      dispatch(app.infoClose());
                    }}
                  />
                  <Error
                    error={appStore.error}
                    color="primary"
                    onClick={() => {
                      dispatch(app.errorClose());
                    }}
                  />
                  <SuiBox textAlign="center">
                    <PhonelinkOffIcon fontSize="large" />
                  </SuiBox>
                  <SuiBox mb={2}>
                    <SuiTypography
                      component="p"
                      variant={window.innerWidth < values.sm ? "h6" : "h6"}
                      align="center"
                      width="100%"
                      color="info"
                    >
                      <Trans>authentication:signin.one-time-access.text</Trans>
                    </SuiTypography>
                  </SuiBox>

                  <SuiBox>
                    <Grid container spacing={2} wrap="nowrap">
                      <Grid item>
                        <PhonelinkLockIcon fontSize="large" />
                      </Grid>
                      <Grid item>
                        <SuiTypography variant="caption" component="div">
                          <Trans>{`authenticator-code-desc-${authenticator}`}</Trans>
                        </SuiTypography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} wrap="nowrap">
                      <Grid item ml={7} xs={12}>
                        <SuiCodeInput
                          name="code"
                          type="number"
                          ref={codeInput}
                          fields={6}
                          value={values.code}
                          onChange={handleChangeCode}
                          isValid={!(touched.code && errors.code)}
                          filterChars={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
                          filterCharsIsWhitelist
                          pattern="[0-9]"
                          helperText={errors.code}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={6} mt={1} mb={1}>
                        <SuiButton
                          onClick={() => {
                            cancel();
                          }}
                          variant="gradient"
                          color="dark"
                          fullWidth
                          disabled={submit}
                        >
                          <Trans>cancel</Trans>
                        </SuiButton>
                      </Grid>
                      <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={6} mt={1} mb={1}>
                        <SuiButton
                          onClick={() => {
                            formik.current.submitForm();
                          }}
                          variant="gradient"
                          color="info"
                          fullWidth
                          disabled={submit}
                        >
                          <Trans>authentication:access</Trans>
                        </SuiButton>
                      </Grid>
                    </Grid>
                  </SuiBox>
                </SuiBox>
              </Form>
            </Card>
          )}
        </Formik>
      </BasicLayout>
    );
  }
  return (
    <BasicLayout
      title={t("authentication:welcome")}
      description={t("authentication:signin.title")}
      image={curved9}
    >
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
          <Card>
            <Form onSubmit={handleSubmit} noValidate name="signInForm">
              <SuiBox p={3} textAlign="center">
                <SuiTypography variant="h5" fontWeight="medium">
                  <Trans>authentication:signin.add-to-trusted.subtitle</Trans>!
                </SuiTypography>
              </SuiBox>
              <SuiBox p={3}>
                <Info
                  info={appStore.info}
                  color="info"
                  onClick={() => {
                    dispatch(app.infoClose());
                  }}
                />
                <Error
                  error={appStore.error}
                  color="primary"
                  onClick={() => {
                    dispatch(app.errorClose());
                  }}
                />
                <SuiBox textAlign="center">
                  <PhonelinkOffIcon fontSize="large" />
                </SuiBox>
                <SuiBox mb={2}>
                  <SuiInput
                    name="deviceName"
                    placeholder={t("authentication:deviceName")}
                    error={!!touched.deviceName && !!errors.deviceName}
                    helperText={errors.deviceName}
                    onChange={handleChange}
                    value={values.deviceName}
                    disabled={submit}
                    onBlur={handleBlur}
                  />
                </SuiBox>
                <SuiBox display="flex" alignItems="left" p={1}>
                  <SuiCheckbox
                    checked={values.confirm}
                    onChange={handleSetConfirm}
                    error={!!touched.confirm && !!errors.confirm}
                    disabled={submit}
                  />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ cursor: "pointer", userSelect: "none" }}
                    ml={1}
                    color="info"
                  >
                    <Trans>authentication:signin.add-to-trusted.text</Trans>
                  </SuiTypography>
                </SuiBox>
                {!!touched.confirm && errors.confirm && (
                  <SuiBox display="flex" alignItems="center">
                    <FormHelperText error id="accountId-error">
                      {errors.confirm}
                    </FormHelperText>
                  </SuiBox>
                )}
                <Separator text="" />
                <SuiBox>
                  <Grid container spacing={2} wrap="nowrap">
                    <Grid item>
                      <PhonelinkLockIcon fontSize="large" />
                    </Grid>
                    <Grid item>
                      <SuiTypography variant="caption" component="div">
                        <Trans>{`authenticator-code-desc-${authenticator}`}</Trans>
                      </SuiTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} wrap="nowrap">
                    <Grid item ml={7} xs={12}>
                      <SuiCodeInput
                        name="code"
                        type="number"
                        ref={codeInput}
                        fields={6}
                        value={values.code}
                        onChange={handleChangeCode}
                        isValid={!(touched.code && errors.code)}
                        filterChars={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
                        filterCharsIsWhitelist
                        pattern="[0-9]"
                        helperText={errors.code}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={6} mt={1} mb={1}>
                      <SuiButton
                        onClick={() => {
                          cancel();
                        }}
                        variant="gradient"
                        color="dark"
                        fullWidth
                        disabled={submit}
                      >
                        <Trans>cancel</Trans>
                      </SuiButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={6} mt={1} mb={1}>
                      <SuiButton
                        onClick={() => {
                          formik.current.submitForm();
                        }}
                        variant="gradient"
                        color="info"
                        fullWidth
                        disabled={submit}
                      >
                        <Trans>add</Trans>
                      </SuiButton>
                    </Grid>
                  </Grid>
                </SuiBox>
              </SuiBox>
            </Form>
          </Card>
        )}
      </Formik>
    </BasicLayout>
  );
}

Stage2.propTypes = {
  refId: PropTypes.string.isRequired,
  authenticator: PropTypes.bool.isRequired,
  oneTime: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Stage2);
