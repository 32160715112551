/* eslint-disable react/no-unused-state */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiSpinner from "components/sui/SuiSpinner";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

// Soft UI Dashboard PRO React example components
import DefaultLayout from "components/layout/LayoutContainers/DefaultLayout";
// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// i18m
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";
import developersEN from "locales/en/developers.json";

import * as app from "actions/app";

import ContractForm from "layouts/developers/contract/components/ContractForm";
import MethodList from "layouts/developers/contract/components/MethodList";
import Error from "layouts/error";

const { values } = breakpoints;

const Contract = ({ t }) => {
  const dispatch = useDispatch();
  const [names, setNames] = useState([undefined, undefined, "-"]);
  const { uid } = useParams();
  const { address } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contract, setContract] = useState(false);
  useEffect(() => {
    i18n.addResources("en", "developers", developersEN);
    dispatch(
      app.contract(uid, address, (_error, result) => {
        if (_error) {
          setError(_error);
        } else {
          setNames([undefined, undefined, result.appName]);
          setContract(result);
        }
        setLoading(false);
      })
    );
  }, []);

  const handlePaymentChange = (index, payment) => {
    const methods = [...contract.methods];
    methods[index].payment = payment;
    setContract({ ...contract, methods });
  };

  if (loading)
    return (
      <DefaultLayout title={contract ? contract.name : "-"} names={names}>
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <SuiBox mb={3} p={1}>
                <SuiTypography
                  variant={window.innerWidth < values.sm ? "h4" : "h3"}
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  <Trans>developers:app-contract-title</Trans>
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Card sx={{ width: "100%" }}>
              <SuiSpinner />
            </Card>
          </Grid>
        </SuiBox>
      </DefaultLayout>
    );

  if (error) {
    return (
      <Error
        error={error}
        title={t("developers:app-contract-title")}
        titleB={contract ? contract.name : "-"}
        names={names}
      />
    );
  }
  return (
    <DefaultLayout title={contract ? contract.name : "-"} names={names}>
      <SuiBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <SuiBox mb={3} p={1}>
              <SuiTypography
                variant={window.innerWidth < values.sm ? "h4" : "h3"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                <Trans>developers:app-contract-title</Trans>
              </SuiTypography>
            </SuiBox>
            <Grid container>
              <Grid item xs={12}>
                <ContractForm contract={contract} />
              </Grid>
              <SuiBox p={1} mt={3}>
                <SuiTypography variant="h5">
                  <Trans>developers:app-contract-methods-title</Trans>
                </SuiTypography>
              </SuiBox>
              <Grid item xs={12}>
                <MethodList
                  tableData={contract.methods}
                  handlePaymentChange={handlePaymentChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </DefaultLayout>
  );
};

Contract.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Contract);
