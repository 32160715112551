/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
import ActionTypes from "constants/ActionTypes";

export function info(_info) {
  return {
    type: ActionTypes.APP_INFO,
    payload: {
      info: _info,
    },
  };
}

export function infoClose() {
  return {
    type: ActionTypes.APP_INFO_CLOSE,
  };
}

export function changePassword(values, callback) {
  const { mydappwallet } = window;
  mydappwallet.change_password(values, callback);
  return {
    type: ActionTypes.APP_CHANGE_PASSWORD,
  };
}

export function changeEmail(values, callback) {
  const { mydappwallet } = window;
  mydappwallet.change_password(values, callback);
  return {
    type: ActionTypes.APP_CHANGE_PASSWORD,
  };
}

export function authenticatorsSuccess(authenticators) {
  return {
    type: ActionTypes.APP_AUTHENTICATORS_SUCCESS,
    payload: {
      authenticators,
    },
  };
}

export function authenticators(callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.authenticator_list({}, (error, result) => {
      if (result) {
        dispatch(authenticatorsSuccess(result));
      }
      callback(error, result);
    });
  };
}

export function authenticatorEditSuccess(result) {
  return {
    type: ActionTypes.PROFILE_AUTHENTICATOR_EDIT_SUCCESS,
    payload: {
      result,
    },
  };
}

export function authenticatorEdit(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.authenticator_edit(values, (error, result, info) => {
      if (result) {
        dispatch(authenticatorEditSuccess(result));
      }
      callback(error, result, info);
    });
  };
}

export function authenticatorEdit2(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.authenticator_edit2(values, (error, result, info) => {
      if (result) {
        dispatch(authenticatorEditSuccess(result));
      }
      callback(error, result, info);
    });
  };
}

export function authenticatorEnable(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.authenticator_enable(values, (error, result, info) => {
      if (result) {
        dispatch({
          type: ActionTypes.PROFILE_AUTHENTICATOR_ENABLE_SUCCESS,
          payload: {
            result,
          },
        });
      }
      callback(error, result, info);
    });
  };
}

export function authenticatorEnable2(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.authenticator_enable2(values, (error, result, info) => {
      if (result) {
        dispatch({
          type: ActionTypes.PROFILE_AUTHENTICATOR_ENABLE_SUCCESS,
          payload: {
            result,
          },
        });
      }
      callback(error, result, info);
    });
  };
}

export function authenticatorEnable3(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.authenticator_enable3(values, (error, result, info) => {
      if (result) {
        dispatch({
          type: ActionTypes.PROFILE_AUTHENTICATOR_ENABLE_SUCCESS,
          payload: {
            result,
          },
        });
        if (info)
          dispatch({
            type: ActionTypes.APP_INFO,
            payload: {
              info,
            },
          });
      }
      callback(error, result);
    });
  };
}

export function authenticatorDisable(values, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.authenticator_disable(values, (error, result) => {
      if (result) {
        dispatch({
          type: ActionTypes.PROFILE_AUTHENTICATOR_ENABLE_SUCCESS,
          payload: {
            result,
          },
        });
      } else {
        dispatch({
          type: ActionTypes.PROFILE_AUTHENTICATOR_ENABLE_FAILED,
          payload: {
            error,
          },
        });
      }
      callback(error, result);
    });
  };
}

export function userMachines(callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.user_machine_list({}, (error, result) => {
      dispatch({
        type: ActionTypes.PROFILE_MACHINES,
        payload: {
          machines: result,
          error,
        },
      });

      callback(error, result);
    });
  };
}

export function userMachinesDelete(id, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.user_machine_delete({ id }, (error, result) => {
      dispatch({
        type: ActionTypes.PROFILE_MACHINE_DELETE,
        payload: {
          error,
          result,
        },
      });

      callback(error, result);
    });
  };
}
