import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components

import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React example components

import SuiBox from "components/sui/SuiBox";
import SuiFormField from "components/sui/SuiFormField";
import SuiButton from "components/sui/SuiButton";
import SuiAlert from "components/sui/SuiAlert";
// Soft UI Dashboard PRO React base styles

import * as Yup from "yup";
import { Formik } from "formik";

// i18m
import { Trans, withTranslation } from "react-i18next";

// Actions
import * as app from "actions/app";

const initialValues = {
  name: "",
};

const ApplicationAddForm = ({ handleAdd, t }) => {
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const formik = useRef(null);
  const onSubmited = (_error, result) => {
    if (_error) {
      switch (_error.code) {
        case 9100:
          formik.current.errors.name = t("errors:9100");
          break;
        default:
          setError(_error);
      }
    } else {
      formik.current.resetForm();
      handleAdd(result);
    }
    setSubmit(false);
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(app.applicationAdd(values, onSubmited));
    return true;
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, t("yup:min", { name: t("name"), char: 3 }))
        .required(t("yup:required", { name: t("name") })),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  return (
    <Card name="applications" sx={{ width: "100%" }}>
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <SuiBox component="form" onSubmit={handleSubmit} noValidate name="addForm">
            <Grid container spacing={3} ml={1} mt={1} mb={3}>
              <Grid item xs={12} sm={6}>
                <SuiFormField
                  label={t("name")}
                  name="name"
                  placeholder={t("name")}
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                  value={values.name}
                  disabled={submit}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <SuiFormField
                  label="&nbsp;"
                  name="submit"
                  disabled={submit}
                  component={SuiButton}
                  onClick={() => {
                    formik.current.submitForm();
                  }}
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  <Trans>add</Trans>
                </SuiFormField>
              </Grid>
            </Grid>
            {error && (
              <Grid mt={3}>
                <SuiBox>
                  <SuiAlert alert={error} color="primary">
                    <Trans>{`errors:${error.code}`}</Trans>
                  </SuiAlert>
                </SuiBox>
              </Grid>
            )}
          </SuiBox>
        )}
      </Formik>
    </Card>
  );
};

ApplicationAddForm.propTypes = {
  handleAdd: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ApplicationAddForm);
