/* eslint-disable class-methods-use-this */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// i18m
import { Trans, withTranslation } from "react-i18next";
import i18n from "i18next";
import profileEN from "locales/en/profile.json";

// @mui material components
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
// Soft UI Dashboard PRO React components
import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";
import SuiAlert from "components/sui/SuiAlert";
import SuiSpinner from "components/sui/SuiSpinner";
import Card from "@mui/material/Card";

import DefaultLayout from "components/layout/LayoutContainers/DefaultLayout";

// Actions
import * as profile from "actions/profile";

// eslint-disable-next-line import/no-named-as-default
import Row from "layouts/profile/machines/Row";
import DeleteMachineDialog from "layouts/profile/machines/DeleteMachineDialog";

function Machines() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [error, setError] = useState(false);
  const [row, setRow] = useState(false);

  const load = () => {
    setLoading(true);
    dispatch(
      profile.userMachines((_error, result) => {
        if (error) {
          setError(_error);
        } else {
          setData(result);
        }
        setLoading(false);
      })
    );
  };
  useEffect(() => {
    i18n.addResources("en", "profile", profileEN);
    load();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (index, _row) => {
    setRow(_row);
  };

  const handleClose = (del) => {
    if (del) {
      setData(data.filter((item) => item.id !== row.id));
    }
    setRow(false);
  };

  if (loading) return <SuiSpinner />;

  return (
    <DefaultLayout>
      <SuiBox py={1} minHeight="calc(100vh - 114px - 23px)">
        <SuiTypography variant="h3" sx={{ marginBottom: "1rem" }}>
          <Trans>profile:machines-title</Trans>
        </SuiTypography>
        <Card id="authenticators">
          <Grid spacing={2} item xs={12} sm="auto">
            <Grid spacing={2} item xs={12}>
              <TableContainer component={Box}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">#</TableCell>
                      <TableCell align="center">
                        <Trans>profile:machines-col1</Trans>
                      </TableCell>
                      <TableCell>
                        <Trans>profile:machines-col2</Trans>
                      </TableCell>
                      <TableCell align="left">
                        <Trans>profile:machines-col3</Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans>profile:machines-col4</Trans>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((_row, index) => (
                        <Row key={_row.name} row={_row} index={index} handleDelete={handleDelete} />
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  styleOverrides={{
                    select: {
                      width: "50px",
                    },
                  }}
                />
              </TableContainer>
            </Grid>
            <Divider />
            {error && (
              <Grid mt={3}>
                <SuiBox>
                  <SuiAlert alert={error} color="primary">
                    <Trans>{`error-${error.code}`}</Trans>
                  </SuiAlert>
                </SuiBox>
              </Grid>
            )}
          </Grid>
        </Card>
      </SuiBox>
      <DeleteMachineDialog open={row} row={row} type={crypto.type} handleClose={handleClose} />
    </DefaultLayout>
  );
}

export default withTranslation()(Machines);
