/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiSpinner from "components/sui/SuiSpinner";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiAlert from "components/sui/SuiAlert";

// Soft UI Dashboard PRO React example components
import DefaultLayout from "components/layout/LayoutContainers/DefaultLayout";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// i18m
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";
import developersEN from "locales/en/developers.json";

import * as app from "actions/app";

import ApplicationForm from "layouts/developers/application/components/ApplicationForm";
import ContractList from "layouts/developers/application/components/ContractList";
import ContractAddForm from "layouts/developers/application/components/ContractAddForm";
import ContractDeleteDialog from "layouts/developers/application/components/ContractDeleteDialog";
// Images
import errorIcon from "assets/images/icons/error.svg";

const { values } = breakpoints;

const Application = () => {
  const dispatch = useDispatch();
  const { uid } = useParams();
  const [names, setNames] = useState(["AAA", "BBB", "CCC"]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [application, setApp] = useState([]);
  const [contractId, setContractId] = useState(0);
  const [del, setDelete] = useState(false);
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    i18n.addResources("en", "developers", developersEN);
    dispatch(
      app.application(uid, (_error, result) => {
        if (error) {
          setError(_error);
        } else {
          setApp(result);
        }
        setLoading(false);
      })
    );
  }, []);

  const handleDelete = () => {
    setDelete(false);
    application.contracts.splice(contractId, 1);
    setApp({ ...application });
  };

  const handleClose = (index) => {
    setDelete(false);
  };

  const handleAdd = (contract) => {
    setApp({ ...application, contracts: [...application.contracts, contract] });
  };

  if (loading)
    return (
      <DefaultLayout names={names}>
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12}>
              <SuiBox mb={3} p={1}>
                <SuiTypography
                  variant={window.innerWidth < values.sm ? "h4" : "h3"}
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  <Trans>developers:app-title</Trans>
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Card sx={{ width: "100%" }}>
              <SuiSpinner />
            </Card>
          </Grid>
        </SuiBox>
      </DefaultLayout>
    );

  if (error) {
    return (
      <DefaultLayout>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <SuiBox component="img" src={errorIcon} alt="Rocketship" mb={3} width="200px" />
          <SuiBox>
            <SuiAlert alert={error} color="primary">
              <Trans>{`error:${error.code}`}</Trans>
            </SuiAlert>
          </SuiBox>
        </Grid>
      </DefaultLayout>
    );
  }
  return (
    <DefaultLayout title={application.name}>
      {del && (
        <ContractDeleteDialog
          uid={uid}
          contract={application.contracts[contractId]}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      )}
      <SuiBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <SuiBox mb={3} p={1}>
              <SuiTypography
                variant={window.innerWidth < values.sm ? "h4" : "h3"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                <Trans>developers:app-title</Trans>
              </SuiTypography>
            </SuiBox>
            <Grid container>
              <Grid item xs={12}>
                <ApplicationForm application={application} />
              </Grid>
              <SuiBox p={1} mt={3}>
                <SuiTypography variant="h5">
                  <Trans>developers:app-contracts-title</Trans>
                </SuiTypography>
              </SuiBox>
              <Grid item xs={12}>
                <ContractList
                  uid={uid}
                  tableData={application.contracts}
                  handleEditContract={(index) => {
                    setContractId(index);
                    setEdit(true);
                  }}
                  handleDeleteContract={(index) => {
                    setContractId(index);
                    setDelete(true);
                  }}
                />
              </Grid>
              <SuiBox p={1} mt={3}>
                <SuiTypography variant="h5">
                  <Trans>developers:app-contract-add-title</Trans>
                </SuiTypography>
              </SuiBox>
              <Grid item xs={12}>
                <ContractAddForm uid={uid} handleAdd={handleAdd} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </DefaultLayout>
  );
};

Application.propTypes = {};

export default withTranslation()(Application);
