/* eslint-disable no-unused-vars */
import { forwardRef, useState, useRef } from "react";
import { PropTypes } from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiCountryIcon from "components/sui/SuiCountryIcon";
import SuiBox from "components/sui/SuiBox";
import SuiNavbarLink from "components/sui/SuiNavbarLink";
// @mui material components
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Paper from "@mui/material/Paper";
import AssetMenu from "components/layout/AssetMenu";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

import NumberFormat from "react-number-format";

// Utils
import * as CryptoUtil from "utils/CryptoUtil";

// Constants
import Constants from "constants/Constants";

// Soft UI Dashboard PRO React helper functions
const { inputColors } = colors;
const { borderWidth } = borders;
const { inputBoxShadow } = boxShadows;

const countries = Constants.CURRENCIES_COUNTRIES_CODES;

// eslint-disable-next-line no-unused-vars
const AssetAmountField = forwardRef(
  ({ value, error, touched, changeValue, onBlur, asset, setAsset, exchangeRates, t }, ref) => {
    const [highlight, setHighlight] = useState(false);
    const [assetOpen, setAssetOpen] = useState(false);

    const amount = useRef(null);

    const handleOpenAssetMenu = ({ currentTarget }) => {
      setAssetOpen(currentTarget);
    };
    const handleCloseAssetMenu = (_asset) => {
      setAssetOpen(false);
      if (_asset) {
        setAsset(_asset);
      }
      amount.current.focus();
    };

    let elevation;
    if (touched && error) {
      elevation = highlight || assetOpen ? 13 : 12;
    } else {
      elevation = highlight || assetOpen ? 11 : 10;
    }
    return (
      <>
        <Paper
          elevation={elevation}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={1}
          sx={{
            display: "flex",
            borderRadius: borders.borderRadius.lg,
            boxShadow: 0,
            position: "relative",
            "&:hover": {
              background: "#eceeef",
            },
            cursor: "pointer",
          }}
          ref={ref}
        >
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            width="100%"
            re
          >
            <FormControl fullWidth sx={{ padding: 1 }}>
              <NumberFormat
                className="send_amount_1_input"
                name="cryptoAmount"
                id="cryptoAmount"
                getInputRef={amount}
                placeholder={asset.symbol}
                required
                onValueChange={(values, sourceInfo) => {
                  if (sourceInfo.source === "event" && sourceInfo.event.type === "change")
                    changeValue(values.value);
                }}
                value={value}
                suffix={` ${asset.symbol}`}
                onBlur={(e) => {
                  setHighlight(false);
                  onBlur(e);
                }}
                decimalScale={asset.decimals}
                onFocus={() => {
                  setHighlight(true);
                }}
              />
            </FormControl>
            <SuiBox ml="auto" lineHeight={0} onClick={handleOpenAssetMenu}>
              <Tooltip title={t("crypto:from-tooltip")} placement="bottom">
                {asset.icon && (
                  <SuiBox
                    className="d-inline-block item-icon-wrapper"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: asset.icon }}
                    mx={0.5}
                  />
                )}
              </Tooltip>
            </SuiBox>
            <SuiBox ml="auto" lineHeight={0} onClick={handleOpenAssetMenu}>
              {asset.symbol}
            </SuiBox>
            <SuiBox ml="auto" lineHeight={0} onClick={handleOpenAssetMenu}>
              {assetOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </SuiBox>

            <AssetMenu anchorEl={assetOpen} close={handleCloseAssetMenu} />
          </SuiBox>
        </Paper>
        <FormHelperText error id="error">
          {touched ? error : ""}
        </FormHelperText>
      </>
    );
  }
);
AssetAmountField.defaultProps = {
  touched: false,
  error: false,
};

AssetAmountField.propTypes = {
  value: PropTypes.number.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  changeValue: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    brokers: PropTypes.arrayOf(PropTypes.string.isRequired),
    decimals: PropTypes.number.isRequired,
  }).isRequired,
  setAsset: PropTypes.func.isRequired,
  exchangeRates: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AssetAmountField);
