import React from "react";
import { useSoftUIController } from "context";

import PropTypes from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// @mui material components
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Box from "@mui/material/Box";
// Icons
import DeleteIcon from "@mui/icons-material/Delete";

import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";

function Row({ row, index, handleDeleteAsset, t }) {
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>{index + 1}</TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">{row.symbol}</TableCell>
        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
          <Box sx={{ display: { xs: "block", md: !miniSidenav ? "block" : "none" } }}>
            {row.address.slice(0, 10)} ... {row.address.slice(-10)}{" "}
            <SuiIconCopyToClipboard text={row.address} name={t("address")} />
          </Box>
          <Box sx={{ display: { xs: "none", md: miniSidenav ? "block" : "none" } }}>
            {row.address} <SuiIconCopyToClipboard text={row.address} name={t("address")} />
          </Box>
        </TableCell>
        <TableCell align="center">{row.decimals}</TableCell>
        <TableCell align="center">
          <ToggleButtonGroup orientation="horizontal">
            <ToggleButton
              value="delete"
              onClick={() => {
                handleDeleteAsset(index, row);
              }}
            >
              <DeleteIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    decimals: PropTypes.number.isRequired,
  }).isRequired,
  handleDeleteAsset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Row);
