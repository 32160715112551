import React from "react";

import PropTypes from "prop-types";

// i18m
import { withTranslation } from "react-i18next";

// @mui material components
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
// Icons
import DeleteIcon from "@mui/icons-material/Delete";

function Row({ row, index, handleDelete }) {
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>{index + 1}</TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.operationSystem}
        </TableCell>
        <TableCell align="left">
          {row.browser} {row.browserVersion}
        </TableCell>
        <TableCell align="center">
          <ToggleButtonGroup orientation="horizontal">
            <ToggleButton
              value="delete"
              onClick={() => {
                handleDelete(index, row);
              }}
            >
              <DeleteIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    browser: PropTypes.string.isRequired,
    browserVersion: PropTypes.string.isRequired,
    operationSystem: PropTypes.string.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default withTranslation()(Row);
