/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/style-prop-object */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { CircleFlag } from "react-circle-flags";
// Soft UI Dashboard PRO React components
import SuiCountryIconRoot from "components/sui/SuiCountryIcon/SuiCountryIconRoot";
import SuiCountryIconIconRoot from "components/sui/SuiCountryIcon/SuiCountryIconIconRoot";
// Custom styles for the SuiAlert

const SuiCountryIcon = forwardRef(({ code, ...rest }, ref) => (
  <SuiCountryIconRoot {...rest} ref={ref}>
    <SuiCountryIconIconRoot ref={ref}>
      <CircleFlag countryCode={code} height="35" />
    </SuiCountryIconIconRoot>
  </SuiCountryIconRoot>
));

// Typechecking props of the SuiAlert
SuiCountryIcon.propTypes = {
  code: PropTypes.string.isRequired,
};

export default SuiCountryIcon;
