/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// react-router-dom components
import { Redirect } from "react-router-dom";

// PropTypes
import PropTypes from "prop-types";

// import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// i18n
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";
import authenticationEN from "locales/en/authentication.json";

import * as Yup from "yup";
import { Formik, Form } from "formik";
// @mui material components

// Soft UI Dashboard PRO React components

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiInput from "components/sui/SuiInput";
import SuiButton from "components/sui/SuiButton";
import SuiSpinner from "components/sui/SuiSpinner";
// Authentication layout components
import BasicLayout from "layouts/components/BasicLayout";
import Error from "components/layout/Error";

import * as authentication from "actions/authentication";
import * as app from "actions/app";

// Images
import curved11 from "assets/images/curved-images/curved11.jpg";

const initialValues = {
  email: "",
  agreement: false,
};

function Reset({ changeView, t }) {
  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.appStore);
  const [submit, setSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const formik = useRef(null);

  useEffect(() => {
    i18n.addResources("en", "authentication", authenticationEN);
  }, []);

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .required(t("yup:required", { name: t("email") }))
        .email(t("yup:email")),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  const onSubmited = (error) => {
    if (error) {
      setRedirect(`/error/${error.code}`);
    } else {
      changeView("sign-in");
    }
    setSubmit(false);
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(app.errorClose());
    dispatch(app.infoClose());
    dispatch(authentication.resetPassword(values, onSubmited));
    return true;
  };

  if (redirect) return <Redirect to={redirect} />;
  return (
    <BasicLayout
      title={t("authentication:reset.title")}
      description={t("authentication:reset.subtitle")}
      image={curved11}
    >
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
          <Card>
            <Form onSubmit={handleSubmit} noValidate name="resetPassword2Form">
              <SuiBox p={3} mb={1} textAlign="center">
                <SuiTypography variant="h5" fontWeight="medium">
                  <Trans>authentication:reset</Trans>
                </SuiTypography>
              </SuiBox>

              <SuiBox mb={2} textAlign="center">
                <SuiTypography variant="body2" color="text">
                  <Trans>authentication:reset.text</Trans>
                </SuiTypography>
              </SuiBox>
              <SuiBox pt={2} pb={3} px={3}>
                <Error
                  error={appStore.error}
                  color="primary"
                  onClick={() => {
                    dispatch(app.errorClose());
                  }}
                />
                <SuiBox>
                  <SuiBox mb={2}>
                    <SuiInput
                      name="email"
                      type="email"
                      placeholder="Email"
                      error={!!touched.email && !!errors.email}
                      helperText={errors.email}
                      onChange={handleChange}
                      value={values.email}
                      disabled={submit}
                      onBlur={handleBlur}
                    />
                  </SuiBox>

                  {submit && (
                    <SuiBox mt={1} mb={1}>
                      {" "}
                      <SuiSpinner size={50} minHeight={50} />{" "}
                    </SuiBox>
                  )}
                  <SuiBox mt={4} mb={1}>
                    <SuiButton
                      variant="gradient"
                      color="dark"
                      fullWidth
                      disabled={submit}
                      onClick={() => {
                        formik.current.submitForm();
                      }}
                    >
                      <Trans>authentication:reset</Trans>
                    </SuiButton>
                  </SuiBox>
                  <SuiBox mt={3} textAlign="center">
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                      Already have an account?&nbsp;
                      <SuiTypography
                        onClick={() => {
                          changeView("sign-in");
                        }}
                        variant="button"
                        color="dark"
                        fontWeight="bold"
                        textGradient
                        disabled={submit}
                      >
                        <Trans>authentication:signin</Trans>
                      </SuiTypography>
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Form>
          </Card>
        )}
      </Formik>
    </BasicLayout>
  );
}

Reset.propTypes = {
  changeView: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Reset);
