/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiSpinner from "components/sui/SuiSpinner";
import SuiButton from "components/sui/SuiButton";
import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";

import Error from "components/layout/Error";
// Moment
import Moment from "react-moment";

// Constants
import Constants from "constants/Constants";

// Actions
import * as app from "actions/app";

// Utils
import * as CryptoUtil from "utils/CryptoUtil";

const utils = require("web3-utils");

const { TRANSACTION_TYPES } = Constants;
const { TRANSACTION_STATUSES } = Constants;

function DetailsRow({ id, open, asset, transaction, t }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const [more, setMore] = useState(false);
  useEffect(() => {
    if (open && !loading && id !== data.id) {
      setLoading(true);
      setError(false);
    }
  });
  useEffect(() => {
    if (loading && id) {
      transaction(id, (_error, result) => {
        if (error) {
          setError(_error);
          setData({ id });
        } else {
          setData(result);
        }
        setLoading(false);
      });
    }
  }, [loading]);

  const {
    type,
    symbol,
    status,
    value,
    decimals,
    from,
    to,
    gasPrice,
    gasLimit,
    token,
    createDate,
    transactionHash,
  } = data;
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            {loading && <SuiSpinner />}
            {error && (
              <>
                <Typography variant="h6" gutterBottom component="div">
                  Error
                </Typography>
                <Error error={{ code: error.code }} color="primary" />
              </>
            )}

            {!error && data.id && (
              <>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Trans>id</Trans>
                      </TableCell>
                      <TableCell>
                        {id} <SuiIconCopyToClipboard text={id} name={t("id")} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ width: "30%" }}>
                        <Trans>crypto:type</Trans>
                      </TableCell>
                      <TableCell>
                        <Trans values={{ symbol }}>{`crypto:transaction-type-${type}`}</Trans>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Trans>crypto:status</Trans>
                      </TableCell>
                      <TableCell>
                        <>
                          {status === TRANSACTION_STATUSES.NEW && (
                            <SuiButton variant="gradient" color="secondary" disabled>
                              <Trans>crypto:transaction-status-0</Trans>
                            </SuiButton>
                          )}
                          {status === TRANSACTION_STATUSES.REJECTED && (
                            <SuiButton variant="gradient" color="info" disabled>
                              <Trans>crypto:transaction-status-1</Trans>
                            </SuiButton>
                          )}
                          {status === TRANSACTION_STATUSES.PENDING && (
                            <SuiButton variant="gradient" color="warning" disabled>
                              <Trans>crypto:transaction-status-2</Trans>
                            </SuiButton>
                          )}
                          {status === TRANSACTION_STATUSES.FAILED && (
                            <SuiButton variant="gradient" color="error" disabled>
                              <Trans>crypto:transaction-status-3</Trans>
                            </SuiButton>
                          )}
                          {status === TRANSACTION_STATUSES.FINISHED && (
                            <SuiButton variant="gradient" color="success" disabled>
                              <Trans>crypto:transaction-status-4</Trans>
                            </SuiButton>
                          )}
                        </>
                      </TableCell>
                    </TableRow>
                    {data.error && (
                      <TableRow>
                        <TableCell>
                          <Trans>error</Trans>
                        </TableCell>
                        <TableCell>
                          <SuiBox color="error">
                            [<Trans>{`errors:${data.error.code}`}</Trans>]
                          </SuiBox>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        <Trans>crypto:amount</Trans>
                      </TableCell>
                      <TableCell>
                        <h4>
                          {CryptoUtil.toAmountAsString(value, decimals || asset.decimals)} {symbol}
                        </h4>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Trans>crypto:from</Trans>
                      </TableCell>
                      <TableCell>
                        {from} <SuiIconCopyToClipboard text={from} name={t("from")} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Trans>crypto:to</Trans>
                      </TableCell>
                      <TableCell>
                        {to} <SuiIconCopyToClipboard text={to} name={t("to")} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Trans>crypto:gasPrice</Trans>
                      </TableCell>
                      <TableCell>
                        {utils.fromWei(gasPrice.toString())} {asset.symbol} (
                        {utils.fromWei(gasPrice.toString(), "Gwei")} Gwei)
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Collapse in={more} timeout="auto" unmountOnExit>
                  <>
                    {" "}
                    <Table size="small" aria-label="purchases">
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ width: "30%" }}>
                            <Trans>crypto:gasLimit</Trans>
                          </TableCell>
                          <TableCell>{gasLimit}</TableCell>
                        </TableRow>
                        {type === TRANSACTION_TYPES.TRANSFER_TOKEN && (
                          <TableRow>
                            <TableCell>
                              <Trans>crypto:token-address</Trans>
                            </TableCell>
                            <TableCell>
                              {token}{" "}
                              <SuiIconCopyToClipboard
                                text={transaction.token}
                                name={t("token-address")}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>
                            <Trans>crypto:timestamp</Trans>
                          </TableCell>
                          <TableCell>
                            <Moment format={Constants.DATE_FORMAT}>{createDate * 1000}</Moment>{" "}
                            <Moment format={Constants.TIME_FORMAT}>{createDate * 1000}</Moment>
                          </TableCell>
                        </TableRow>
                        {status === TRANSACTION_STATUSES.FINISHED && (
                          <TableRow>
                            <TableCell>
                              <Trans>crypto:hash</Trans>
                            </TableCell>
                            <TableCell>
                              <div>
                                <Link
                                  sx={{ focusVisible: { outline: "none" } }}
                                  href={crypto.scan + transactionHash}
                                  target="_blank"
                                  underline="none"
                                >
                                  {transactionHash}
                                </Link>{" "}
                                <SuiIconCopyToClipboard
                                  text={transactionHash}
                                  name={t("crypto:hash")}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </>
                </Collapse>
                <SuiBox pt="0.75rem" pl="1rem" pb="0.2rem">
                  <Link
                    sx={{ focusVisible: { outline: "none" } }}
                    href="#"
                    onClick={() => {
                      setMore(!more);
                    }}
                  >
                    <Trans>{more ? "see-less" : "see-more"}</Trans>{" "}
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        setMore(!more);
                      }}
                    >
                      {more ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Link>
                </SuiBox>
              </>
            )}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

DetailsRow.propTypes = {
  id: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  transaction: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    decimals: PropTypes.number.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  transaction: (uid, callback) => dispatch(app.transaction(uid, callback)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DetailsRow));
