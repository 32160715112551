import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import * as CryptoUtil from "utils/CryptoUtil";

function SuiPrice({ name, currency }) {
  const asset = useSelector((state) => state.appStore.assets[name]);
  const balance = useSelector((state) => state.appStore.balance[name]);

  if (!asset) return <>Asset not found!</>;
  let price = 0;
  if (currency) {
    const exchangeRates = useSelector(
      (state) => state.appStore.exchangeRates.exchanges[name].price
    );
    price = CryptoUtil.toAmountAsString(balance, asset.decimals, 2, exchangeRates[currency]);
  } else {
    price = CryptoUtil.toAmountAsString(balance, asset.decimals, 10);
  }
  return <>{price}</>;
}

SuiPrice.defaultProps = {
  currency: null,
};

// Typechecking props for the PageLayout
SuiPrice.propTypes = {
  name: PropTypes.string.isRequired,
  currency: PropTypes.string,
};

export default SuiPrice;
