/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useParams } from "react-router-dom";

// Authentication layout components
import Error404 from "layouts/pages/error/404";
import Error500 from "layouts/pages/error/500";
import ErrorOther from "layouts/pages/error/Other";

function Error() {
  // eslint-disable-next-line no-unused-vars
  const { code } = useParams();
  switch (code) {
    case "404":
      return <Error404 />;
    case "500":
      return <Error500 />;
    default:
      return <ErrorOther code={code} />;
  }
}

export default Error;
