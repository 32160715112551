import { forwardRef, useState } from "react";
import { PropTypes } from "prop-types";

// Soft UI Dashboard PRO React components

// @mui material components
import Paper from "@mui/material/Paper";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
// Soft UI Dashboard PRO React base styles

import borders from "assets/theme/base/borders";
// Soft UI Dashboard PRO React helper functions

import NumberFormat from "react-number-format";

// eslint-disable-next-line no-unused-vars
const GasPriceField = forwardRef(({ value, touched, error, onChange, onBlur }, ref) => {
  const [highlight, setHighlight] = useState(false);

  let elevation;
  if (touched && error) {
    elevation = highlight ? 13 : 12;
  } else {
    elevation = highlight ? 11 : 10;
  }
  return (
    <>
      <Paper
        elevation={elevation}
        sx={{
          display: "flex-direction",
          borderRadius: borders.borderRadius.lg,
          boxShadow: 0,
          position: "relative",
          "&:hover": { outline: 0 },
        }}
      >
        <FormControl fullWidth sx={{ padding: 2 }}>
          <NumberFormat
            className="number_input"
            name="gasPrice"
            id="gasPrice"
            placeholder="Gas Price"
            required
            value={value}
            onChange={onChange}
            onBlur={(e) => {
              setHighlight(false);
              onBlur(e);
            }}
            onFocus={() => {
              setHighlight(true);
            }}
            style={{ width: "100%" }}
          />
        </FormControl>
      </Paper>
      <FormHelperText error id="error">
        {touched ? error : ""}
      </FormHelperText>
    </>
  );
});
GasPriceField.defaultProps = {
  error: false,
  touched: false,
};

GasPriceField.propTypes = {
  value: PropTypes.number.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default GasPriceField;
