/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";

import { Redirect } from "react-router-dom";
import Theme from "Theme";
import { useDispatch, useSelector } from "react-redux";

// i18m
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEN from "locales/en/common.json";
import infosEN from "locales/en/infos.json";
import errorsEN from "locales/en/errors.json";
import yupEN from "locales/en/yup.json";
import currenciesEN from "locales/en/currencies.json";
import cryptoEN from "locales/en/crypto.json";
import ibanEN from "locales/en/iban.json";
import Spinner from "components/layout/Spinner";

import * as app from "actions/app";

const MyDappWalletManager = require("mydappwallet");

const resources = {
  en: {
    translation: commonEN,
    alerts: infosEN,
    errors: errorsEN,
  },
};

export default function App() {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);
  const initialization = useSelector((state) => state.appStore.initialization);
  // eslint-disable-next-line no-unused-vars
  useEffect(() => {
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      });
    i18n.addResources("en", "errors", errorsEN);
    i18n.addResources("en", "infos", infosEN);
    i18n.addResources("en", "yup", yupEN);
    i18n.addResources("en", "currencies", currenciesEN);
    i18n.addResources("en", "crypto", cryptoEN);
    i18n.addResources("en", "iban", ibanEN);
    window.app = this;
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

    window.mydappwallet = new MyDappWalletManager(
      "https://api.mydappwallet.com",
      /* "http://localhost/api", */
      "Rczx3k42DgnBcN4A92EA",
      {
        withCredentials: false,
        redirect: (to) => {
          window.open(to);
        },
      },
      (_error, result) => {
        if (result) {
          dispatch(
            app.init((_result, error) => {
              if (error) {
                setRedirect("/error/500");
              }
              setLoading(false);
            })
          );
        } else {
          setLoading(false);
        }
      }
    );
  }, []);

  if (redirect) return <Redirect to={redirect} />;
  if (loading || initialization) return <Spinner />;
  return <Theme />;
}
