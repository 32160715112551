/* eslint-disable class-methods-use-this */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";
import SuiAlert from "components/sui/SuiAlert";

// Actions
import * as profile from "actions/profile";

function DeleteMachineDialog({ row, open, handleClose }) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const onSubmited = (_error) => {
    if (error) {
      setError(_error);
    } else {
      handleClose(true);
    }
  };
  const onSubmit = () => {
    dispatch(profile.userMachinesDelete(row.id, onSubmited));
    return true;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Trans>crypto:books-delete-title</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Trans>profile:machines-delete-subtitle</Trans> <b>{row.name}</b>?
        </DialogContentText>
        {error && (
          <Grid mt={3}>
            <SuiBox>
              <SuiAlert alert={error} color="primary">
                <Trans>{`error-${error.code}`}</Trans>
              </SuiAlert>
            </SuiBox>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <SuiButton onClick={handleClose}>
          <Trans>no</Trans>
        </SuiButton>
        <SuiButton
          onClick={() => {
            onSubmit();
          }}
        >
          <Trans>yes</Trans>
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}

DeleteMachineDialog.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,

  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withTranslation()(DeleteMachineDialog);
