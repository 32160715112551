const BigNumber = require("bignumber.js");

// eslint-disable-next-line import/prefer-default-export
// eslint-disable-next-line no-unused-vars
export function toAmount(value = 0, decimals = 18, digits = decimals, exchangeRate = 1) {
  const v = new BigNumber(value);
  if (v.isZero()) return "0";
  const d = new BigNumber(10 ** decimals);
  const e = new BigNumber(exchangeRate);
  const amount = v.dividedBy(d).multipliedBy(e);
  return amount;
}
export function toAmountAsString(value = 0, decimals = 18, digits = decimals, exchangeRate = 1) {
  const v = new BigNumber(value);
  if (v.isZero()) return "0";
  const d = new BigNumber(10 ** decimals);
  const e = new BigNumber(exchangeRate);
  const amount = v.dividedBy(d).multipliedBy(e);
  return amount
    .toFixed(digits)
    .toString(10)
    .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
}

export function toValue(amount = 0, decimals = 18, exchangeRate = 1) {
  const a = new BigNumber(amount);
  if (a.isZero()) return "0";
  const d = new BigNumber(10 ** decimals);
  const e = new BigNumber(exchangeRate);

  const value = a.multipliedBy(d).multipliedBy(e);
  return value;
}

export function toValueAsString(amount = 0, decimals = 18, digits = decimals, exchangeRate = 1) {
  const a = new BigNumber(amount);
  if (a.isZero()) return "0";
  const d = new BigNumber(10 ** decimals);
  const e = new BigNumber(exchangeRate);

  const value = a.multipliedBy(d).multipliedBy(e);
  return value
    .toFixed(digits)
    .toString(10)
    .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
}
