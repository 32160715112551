/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/sui/SuiTypography";
import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";
import SuiPrice from "components/sui/SuiPrice";
// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import QRCode from "react-qr-code";
import getSymbolFromCurrency from "currency-symbol-map";

function Receive({ name, asset, t }) {
  const currency = useSelector((state) => state.appStore.settings.defaultCurrency);
  const { mydappwallet } = window;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm="auto">
        <Box>
          <QRCode value={mydappwallet.wallet.EVM} size={150} />
        </Box>
      </Grid>
      <Grid spacing={2} item xs={12} sm="auto">
        <Grid spacing={2} item xs={12}>
          <SuiTypography variant="h6" fontWeight="medium" color="info" textTransform="capitalize">
            <Trans>iban:receive-balance</Trans>
          </SuiTypography>
        </Grid>

        <Grid spacing={1} item xs={12}>
          <SuiTypography
            variant="body1"
            fontWeight="medium"
            color="text"
            textTransform="capitalize"
          >
            <SuiPrice name={name} />
          </SuiTypography>
          {!crypto.test && (
            <SuiTypography
              variant="body2"
              fontWeight="light"
              color="text"
              textTransform="capitalize"
              sx={{ lineHeight: 1 }}
            >
              {getSymbolFromCurrency(currency)} <SuiPrice name={name} currency={currency} />
            </SuiTypography>
          )}
        </Grid>
        <Grid spacing={2} item xs={12} mt={2}>
          <SuiTypography variant="h6" fontWeight="medium" color="info" textTransform="capitalize">
            <Trans>iban:receive-account</Trans>
          </SuiTypography>
        </Grid>
        <Grid spacing={2} item xs={12}>
          <SuiTypography
            variant="body2"
            fontWeight="medium"
            color="text"
            textTransform="capitalize"
          >
            {asset.iban}{" "}
            <SuiIconCopyToClipboard text={asset.iban} name={t("iban:receive-account")} />
          </SuiTypography>
        </Grid>
        <Grid spacing={2} item xs={12}>
          <SuiTypography variant="h6" fontWeight="medium" color="info" textTransform="capitalize">
            <Trans>iban:bank</Trans>
          </SuiTypography>
        </Grid>
        <Grid spacing={2} item xs={12}>
          <SuiTypography
            variant="body2"
            fontWeight="medium"
            color="text"
            textTransform="capitalize"
          >
            {asset.bank}
          </SuiTypography>
        </Grid>
      </Grid>
    </Grid>
  );
}
Receive.propTypes = {
  name: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    iban: PropTypes.string.isRequired,
    bank: PropTypes.string.isRequired,
    brokers: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = () => ({});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Receive));
