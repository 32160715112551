/* eslint-disable class-methods-use-this */
import { useState } from "react";

import { connect } from "react-redux";
import { PropTypes } from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiButton from "components/sui/SuiButton";
import SuiAlert from "components/sui/SuiAlert";

// Actions
import * as app from "actions/app";

function DeleteBookDialog({ row, type, open, handleClose, addressBookDelete }) {
  const [error, setError] = useState(false);
  const onSubmited = (_error) => {
    if (error) {
      setError(_error);
    } else {
      handleClose();
    }
  };
  const onSubmit = () => {
    addressBookDelete({ address: row.address, type }, onSubmited);
    return true;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Trans>crypto:books-delete-title</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Trans>crypto:books-delete-subtitle</Trans> <b>{row.name}</b>?
        </DialogContentText>
        {error && (
          <Grid mt={3}>
            <SuiBox>
              <SuiAlert alert={error} color="primary">
                <Trans>{`error-${error.code}`}</Trans>
              </SuiAlert>
            </SuiBox>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <SuiButton onClick={handleClose}>
          <Trans>no</Trans>
        </SuiButton>
        <SuiButton
          onClick={() => {
            onSubmit();
          }}
        >
          <Trans>yes</Trans>
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}

DeleteBookDialog.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addressBookDelete: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  addressBookDelete: (values, callback) => dispatch(app.addressBookDelete(values, callback)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DeleteBookDialog));
