// Custom styles for DashboardNavbar
import typography from "assets/theme/base/typography";

// @mui material components
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const { size, fontWeightMedium } = typography;

const ColorButton = styled(Button)({
  color: "#fff",
  background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
  boxShadow: "none",
  borderRadius: "1.5rem",
  padding: "0.2rem 0.5rem",
  fontSize: size.md,
  fontWeight: fontWeightMedium,
  transition: "all 0.5s ease",
  minHeight: "unset",
  "&:hover": {
    background: "linear-gradient(250deg, rgb(33, 82, 255), rgb(33, 212, 253))",
    boxShadow: "none",
    transform: "unset",
    color: "#fff",
  },
  "&:focus:not(:hover)": {
    background: "linear-gradient(250deg, rgb(33, 82, 255), rgb(33, 212, 253))",
    boxShadow: "none",
    transform: "unset",
    color: "#fff",
  },
  "& span": {
    marginLeft: 0,
    color: "#fff",
  },
});
export default ColorButton;
