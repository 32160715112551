/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// i18n
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";

import profileEN from "locales/en/profile.json";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiSpinner from "components/sui/SuiSpinner";
import SuiAlert from "components/sui/SuiAlert";
import SuiTypography from "components/sui/SuiTypography";

// Actions
import * as profile from "actions/profile";
import DefaultLayout from "components/layout/LayoutContainers/DefaultLayout";

import SMSAuthenticator from "layouts/profile/authenticators/components/SMSAuthenticator";
import GoogleAuthenticator from "layouts/profile/authenticators/components/GoogleAuthenticator";

// Images
import listEmptyIcon from "assets/images/icons/list-empty.png";

import Constants from "constants/Constants";

const { AUTHENTICATORS } = Constants;

const Authenticators = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const load = () => {
    setLoading(true);
    dispatch(
      profile.authenticators((_error, result) => {
        if (error) {
          setError(_error);
        } else {
          setData(result);
        }
        setLoading(false);
      })
    );
  };
  useEffect(() => {
    i18n.addResources("en", "profile", profileEN);
    load();
  }, []);

  return (
    <DefaultLayout>
      <SuiBox py={1} minHeight="calc(100vh - 114px - 23px)">
        <SuiTypography variant="h3" sx={{ marginBottom: "1rem" }}>
          <Trans>profile:authenticators-title</Trans>
        </SuiTypography>
        <Card id="authenticators">
          <SuiBox p={2}>
            <Grid container spacing={3}>
              {loading && <SuiSpinner />}
              {error && (
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SuiBox
                    component="img"
                    src={listEmptyIcon}
                    alt="Rocketship"
                    mb={3}
                    width="200px"
                  />
                  <SuiBox>
                    <SuiAlert alert={error} color="primary">
                      <Trans>{`error-${error.code}`}</Trans>
                    </SuiAlert>
                  </SuiBox>
                </Grid>
              )}
              {data && (
                <>
                  {data.map((auth) => (
                    <>
                      {auth.type === AUTHENTICATORS.GOOGLE && (
                        <GoogleAuthenticator auth={auth} load={load} />
                      )}
                      {auth.type === AUTHENTICATORS.SMS && (
                        <SMSAuthenticator auth={auth} load={load} />
                      )}
                    </>
                  ))}
                </>
              )}
            </Grid>
          </SuiBox>
        </Card>
      </SuiBox>
    </DefaultLayout>
  );
};

export default withTranslation()(Authenticators);
