import * as React from "react";

import styled from "styled-components";
// @mui material components
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";

// TODO: set proper in future due to urgent deploye it is pending

const BasicDropDownMenuCmp = styled(Menu)`
  .MuiPaper-root {
    width: 202px;
    padding: 0;
    border-radius: 5px;
    border: 1px solid #e4e4e4;
    background: red;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: #000;
    padding: 16px;
    height: 50px;
    border-bottom: 1px solid #e4e4e4;
    border-radius: 0;
    &:hover {
      background-color: unset;
    }
  }
  .menu-item {
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 16px 16px 0;
    white-space: nowrap;
    height: 50px;
    font-weight: normal;
    border-radius: 0;
    .icon {
      padding: 10px 16px;
      opacity: 0;
      svg {
        width: 26px;
        height: 28px;
        fill: #439d46;
      }
    }
    &.active {
      .icon {
        opacity: 1;
      }
    }
  }
`;

// eslint-disable-next-line react/prop-types
export default function BasicMenu({ anchorEl, setAnchorEl, setSortingType, sortingType }) {
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <BasicDropDownMenuCmp
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem className="title" onClick={handleClose} sx={{ fontWeight: "bold" }}>
          Sort By
        </MenuItem>

        <MenuItem
          key={1}
          onClick={() => {
            setSortingType("CURRENCY");
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              width: 50,
            }}
          >
            {sortingType === "CURRENCY" && (
              <IconButton>
                <CheckIcon />
              </IconButton>
            )}
          </Box>
          Currency
        </MenuItem>
        <MenuItem
          key={2}
          onClick={() => {
            setSortingType("ESTIMATES");
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              width: 50,
            }}
          >
            {sortingType === "ESTIMATES" && (
              <IconButton>
                <CheckIcon />
              </IconButton>
            )}
          </Box>
          Estimates
        </MenuItem>
        <MenuItem
          key={3}
          onClick={() => {
            setSortingType("RATING");
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              width: 50,
            }}
          >
            {sortingType === "RATING" && (
              <IconButton>
                <CheckIcon />
              </IconButton>
            )}
          </Box>
          Rating
        </MenuItem>
      </BasicDropDownMenuCmp>
    </div>
  );
}
