/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import PageLayout from "components/layout/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/components/Footer";

// Soft UI Dashboard PRO React page layout routes

function CoverLayout({ color, title, description, header, top, children }) {
  return (
    <PageLayout background="white">
      <SuiBox
        width="calc(100% - 2rem)"
        minHeight="50vh"
        borderRadius="lg"
        mx={2}
        my={2}
        pt={6}
        pb={28}
      >
        <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item mt={top} xs={12} sm={12} md={12} lg={10} xl={7} xxl={5}>
            <SuiBox xs={12} px={3} justifyContent="left" sx={{ textAlign: "left" }}>
              {header}
            </SuiBox>
            <SuiBox pt={3} px={3}>
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold" color={color}>
                  {title}
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="body2" fontWeight="regular" color="text">
                {description}
              </SuiTypography>
            </SuiBox>
            <SuiBox p={3}>{children}</SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </PageLayout>
  );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  title: "",
  description: "",
  color: "info",
  top: 2,
  header: null,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
